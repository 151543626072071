import { makeVar } from '@apollo/client'
import client from '../apolloClient'
import { CACHE_QUERY } from '../queries/cacheQueries'
import { QUERY_PROJECTS_API } from '../queries/projectQueries'
import { CurrentWorker, IProject } from '../types'
import { getListFromRelayList } from '../utils/apiHelpers'

export const projectsVar = makeVar<IProject[]>([])

export const currentWorkerVar = makeVar<CurrentWorker | null>(null)

export const loadCacheData = async () => {
  const response = await client.query({
    query: CACHE_QUERY,
    fetchPolicy: 'network-only'
  })

  projectsVar(getListFromRelayList<IProject[]>(response.data, 'myProjects'))
  currentWorkerVar(response.data.myWorker)
}

export const loadProjects = async () => {
  const response = await client.query({
    query: QUERY_PROJECTS_API,
    fetchPolicy: 'network-only'
  })

  projectsVar(getListFromRelayList<IProject[]>(response.data, 'myProjects'))
}
