import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

const userManager = new UserManager({
  userStore: new WebStorageStateStore(),
  authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT}/oauth2/v2.0`,
  metadataUrl: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT}/v2.0/.well-known/openid-configuration`,
  client_id: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
  redirect_uri: window.location.origin + '/login',
  response_type: 'code',
  scope: 'openid profile email',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true
})

export default userManager
