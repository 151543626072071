import React from 'react'

type Props = {
  solid?: boolean
  regular?: boolean
  color?: string
  size?: string
  icon: string
  className?: string
}

const Icon = ({ icon, color, solid, regular, size, className }: Props) => {
  const getStyle = () => {
    if (solid) {
      return 'fas'
    } else if (regular) {
      return 'far'
    } else {
      return 'fal'
    }
  }
  return (
    <i
      className={`${getStyle()} ${icon} ${className}`}
      style={{
        color: color,
        fontSize: size || 'inherit',
        verticalAlign: 'baseline'
      }}
    />
  )
}

export default Icon
