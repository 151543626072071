import { Typography } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'
import PageContainer from '../../components/common/PageContainer'
import { APPS } from '../../components/navigation/NavbarApps'
import NavbarApp from '../../components/navigation/NavbarApp'

const ToolsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--spacing-base-loose);
  margin-top: var(--spacing-base-loose);
`

const GuestIndex = () => {
  return (
    <PageContainer>
      <Typography variant='h2'>Motius Tools</Typography>
      <ToolsGrid>
        {APPS.filter(app => app.guest).map(app => {
          return (
            <NavbarApp
              key={app.id}
              name={app.name}
              image={app.image}
              url={app.url}
            />
          )
        })}
      </ToolsGrid>
    </PageContainer>
  )
}

export default GuestIndex
