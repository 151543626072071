import { gql } from '@apollo/client'

export const QUERY_PROJECTS_API = gql`
  query GetProjectsFromApi {
    myProjects {
      edges {
        node {
          id
          name
          color
          isActive
          costCenterInternal
        }
      }
    }
  }
`

export const QUERY_PROJECTS = gql`
  {
    projects @client {
      id
      name
      color
      isActive
      costCenterInternal
      __typename
    }
  }
`
