import * as Sentry from '@sentry/browser'
import userManager from '../userManager'

class ApiError extends Error {}

const httpClient = async (url: string, options?: any, pure?: boolean) => {
  const headers = new Headers({
    'Platform-Origin': 'Worker',
    'Content-Type': 'application/json',
    'Accept-Language': 'en-US',
    ...options.headers
  })

  if (!process.env.REACT_APP_TEST_TOKEN) {
    const user = await userManager.getUser()
    headers.append('Authorization', `Bearer ${user?.id_token}`)
  } else {
    headers.append('Authorization', `JWT ${process.env.REACT_APP_TEST_TOKEN}`)
  }

  if (options && options.body) {
    options.body = JSON.stringify(options.body)
  }

  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    ...options,
    headers
  }).then(async res => {
    if (res.ok) {
      return pure
        ? res
        : res
            .json()
            .then(data => data)
            .catch(e => true)
    } else {
      if (res.status !== 400 && process.env.REACT_APP_SENTRY_DSN) {
        const user = await userManager.getUser()
        Sentry.setUser(user)
        res
          .json()
          .then(json => {
            Sentry.captureException(new ApiError(json))
            throw json
          })
          .catch(() => {
            Sentry.captureException(new ApiError('Unknown error'), {
              extra: {
                request: {
                  url,
                  options
                }
              }
            })
          })
      } else {
        throw await res.json()
      }
    }
  })
}

const timelogs = {
  export: async (ids: number[] | string[]) => {
    const response = await httpClient(
      `/timelogs/export/csv/?id__in=${ids.join(',')}`,
      null,
      true
    )
    return response
  }
}

const serviceVersion = {
  version: async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/service-versions`
    );
    return response;
  }
};

const shortener = {
  shorten: async (url: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL!.replace('/api', '')}/s/shorten`,
      {
        body: JSON.stringify({ url }),
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'en-US'
        }
      }
    )
    return response
  }
}

const worker = {
  uploadFile: async (worker: string, data: FormData) => {
    const user = await userManager.getUser()
    const token = `Bearer ${user?.id_token}`
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/worker/${worker}/`,
      {
        method: 'PATCH',
        body: data,
        headers: {
          Authorization: token
        }
      }
    )
    return response
  },
  removeCV: async (worker: string) => {
    const user = await userManager.getUser()
    const token = `Bearer ${user?.id_token}`

    const response = await httpClient(`/users/worker/${worker}/`, {
      method: 'PATCH',
      headers: {
        Authorization: token
      },
      body: { cv: null }
    })
    return response
  }
}

const apiService = {
  timelogs,
  shortener,
  worker,
  serviceVersion
}

export default apiService
