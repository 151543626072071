import { gql } from '@apollo/client'

export const CACHE_QUERY = gql`
  query GetCacheValues {
    myProjects {
      edges {
        node {
          id
          name
          color
          isActive
          costCenterInternal
        }
      }
    }
    myWorker {
      id
      department {
        id
        name
      }
    }
  }
`
