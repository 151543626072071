import React from 'react'
import styled from '@emotion/styled'
import LoadingIcon from '../common/LoadingIcon'

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loading = () => {
  return (
    <LoadingContainer>
      <LoadingIcon color='var(--color-primary)' />
    </LoadingContainer>
  )
}

export default Loading
