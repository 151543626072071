import { ApolloProvider } from '@apollo/client'
import 'normalize.css'
import React from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import ReactDOM from 'react-dom'
import { ToastContainer, ToastContentProps, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import client from './apolloClient'
import App from './App'
import Icon from './components/common/Icon'
import './index.css'
import { CombinedThemeProvider } from './theme'
import IconButton from './components/common/IconButton'
import { startSentryLogging } from './sentry'

startSentryLogging()

const ToastCloseButton = ({ closeToast }: ToastContentProps) => (
  <IconButton onClick={closeToast}>
    <Icon color='var(--color-primary)' icon='fa-times' />
  </IconButton>
)

const AppContainer = (
  <CombinedThemeProvider>
    <ToastContainer
      transition={Slide}
      position='bottom-center'
      closeOnClick={false}
      hideProgressBar
      closeButton={ToastCloseButton}
    />
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </CombinedThemeProvider>
)

ReactDOM.render(AppContainer, document.getElementById('root'))
