import { gql, useQuery } from '@apollo/client'
import { CurrentWorker } from '../types'

export const QUERY_CURRENT_WORKER_API = gql`
  query GetCurrentWorker {
    myWorker {
      id
      department {
        id
        name
      }
    }
  }
`

export const QUERY_CURRENT_WORKER = gql`
  {
    currentWorker @client {
      id
      department {
        id
        name
      }
    }
  }
`

export const useGetCurrentWorker = () => {
  return useQuery<{ currentWorker: CurrentWorker }>(QUERY_CURRENT_WORKER)
}
