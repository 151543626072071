import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Container,
  Typography
} from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'
import { Link, NavLink, NavLinkProps, useHistory } from 'react-router-dom'
import IconButton from '../common/IconButton'
import Icon from '../common/Icon'
import userManager from '../../userManager'
import NavbarApplications from './NavbarApps'
import MotiusLogo from '../../assets/images/logo-nav.png'
import { useAuth, useMobileQuery } from '../../utils/customHooks'
import { themeVariables } from '../../theme'
import { useAppContext } from '../../store/appContext'

const StyledAppBar = styled(AppBar)`
  && {
    background: ${props => props.theme.palette.background.paper};

    .MuiToolbar-regular {
      align-items: stretch;
    }
  }
`

const StyledContainer = styled(Container)`
  && {
    position: relative;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.palette.text.primary};

    ${themeVariables.mobileQuery} {
      padding: 0;
    }
  }
`

const NavbarLinkContainer = styled.div`
  display: flex;
  align-items: end;
  margin-right: var(--spacing-base-loose);
  height: 100%;

  & > * {
    height: 100%;
    margin-right: var(--spacing-extra-loose);
  }
`

const NavbarLink = styled((props: NavLinkProps) => (
  <NavLink activeClassName='active' {...props} />
))`
  display: flex !important;
  align-items: center;
  text-decoration: none;
  color: var(--color-text-primary);

  & > * {
    padding: var(--spacing-base-loose) 0;
  }

  &.active {
    color: var(--color-primary);
    box-shadow: inset 0 -2px 0 0 var(--color-primary);
  }
`

const NavbarLogo = styled.figure`
  margin: 0;
  padding: var(--spacing-base-tight) 0;
  height: 50px;

  img {
    height: 100%;
  }
`

const MobileNavigation = styled(Typography)`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  button {
    margin-right: var(--spacing-base-loose);
    padding: 0;
  }
`

const Navbar = () => {
  const { navbar } = useAppContext()
  const isMobile = useMobileQuery()
  const history = useHistory()
  const { isGuest } = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    userManager.signoutRedirect()
  }

  return (
    <StyledAppBar position='sticky' elevation={0}>
      <Toolbar role='toolbar'>
        <StyledContainer maxWidth='lg' style={{ display: 'flex' }}>
          {isMobile ? (
            <MobileNavigation variant='h3'>
              {navbar.hasParent && (
                <IconButton onClick={() => history.goBack()}>
                  <Icon
                    regular
                    icon='fa-chevron-left'
                    color='var(--color-text-primary)'
                  />
                </IconButton>
              )}
              <span>{navbar.title}</span>
            </MobileNavigation>
          ) : (
            <>
              <NavbarLink to='/' activeClassName=''>
                <NavbarLogo>
                  <img src={MotiusLogo} alt='Motius GmbH' />
                </NavbarLogo>
              </NavbarLink>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                {!isGuest && (
                  <NavbarLinkContainer>
                    <NavbarLink to='/timelogs'>
                      <Typography variant='body1'>Time</Typography>
                    </NavbarLink>
                    <NavbarLink to='/projects'>
                      <Typography variant='body1'>Projects</Typography>
                    </NavbarLink>
                    <NavbarLink to='/profile'>
                      <Typography variant='body1'>People</Typography>
                    </NavbarLink>
                  </NavbarLinkContainer>
                )}
                <NavbarApplications />
                <div>
                  <IconButton onClick={handleMenuClick}>
                    <Icon
                      icon='fa-user-circle'
                      color='var(--color-secondary)'
                    />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {!isGuest && (
                    <>
                      <MenuItem
                        component={Link}
                        to='/profile/me'
                        onClick={handleMenuClose}
                      >
                        My Profile
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        to='/profile/referral'
                        onClick={handleMenuClose}
                      >
                        Refer a friend!
                      </MenuItem>
                      <MenuItem
                        component={'a'}
                        href='https://motius.atlassian.net/wiki/spaces/TEAM/pages/7746683448/Managing+your+Time'
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={handleMenuClose}
                      >
                        How to track time
                      </MenuItem>
                      <MenuItem
                        component={'a'}
                        href='https://motius.atlassian.net/servicedesk/customer/portals'
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={handleMenuClose}
                      >
                        Get help
                      </MenuItem>
                    </>
                  )}
                  <MenuItem
                    style={{ color: '#ee0000', opacity: 0.86 }}
                    onClick={handleLogout}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </>
          )}
        </StyledContainer>
      </Toolbar>
    </StyledAppBar>
  )
}

export default Navbar
