import create from 'zustand'

export type State = {
  navbar: {
    hasParent: boolean
    title: string
  },
  setNavigation: (navbar?: {
    hasParent: boolean
    title: string
  }) => void
}

const initialNavbar: State['navbar'] = {
    title: 'Motius R&D',
    hasParent: false
}

export const useAppContext = create<State>((set) => ({
  navbar: initialNavbar,
  setNavigation: (navbar) => set({ navbar: navbar || initialNavbar })
}))
