import styled from '@emotion/styled'

const IconButton = styled.button<{ rounded?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${props => (props.rounded ? '0.5rem' : '0.25rem')};
  border: none;
  border-radius: 50%;
  background: ${props =>
    props.rounded ? 'var(--color-primary)' : 'transparent'};
  color: ${props => (props.rounded ? 'white' : 'var(--color-text-primary)')};
  cursor: pointer;
  z-index: 100;
  outline: none;

  span {
    margin-left: var(--spacing-tight);
  }
`

export default IconButton
