/**
 * Gives the data as a normal array from a relay list. So instead of edges, then nodes you get the array directly
 * This function supports type casting as well use `getListFromRelayList<T>()`.
 *
 * @param  {any} data Whole response from the server
 * @param  {string} key key of the array. eg. allTimelogTags
 */
export const getListFromRelayList = <T extends unknown>(
  data: any,
  key: string
): T => {
  if (!data || !data[key]) {
    return [] as T
  } else {
    return (
      (data[key].edges?.map(({ node }: any) => ({ ...node })) as T) || ([] as T)
    )
  }
}

export const createTalentReferralLink = (referralCode: string) =>
  `https://motius.breezy.hr/p/7d9c01a631d601-talent-pool-application-join-as-working-student-graduate-freelancer-m-f-d?utm_source=${referralCode}&utm_medium=ref&utm_campaign=ref`
