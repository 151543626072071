import React from 'react'
import styled from '@emotion/styled'
import Icon from './Icon'

const LoadingIcon = styled((props: any) => <Icon {...props} icon='fa-sync' />)`
  @keyframes loadTurn {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: loadTurn 1s infinite linear;
`

export default LoadingIcon
