import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: any;
  GenericScalar: any;
  JSONString: any;
};

export type AppendTimelog = {
  __typename?: 'AppendTimelog';
  timelog?: Maybe<TimelogNode>;
  error?: Maybe<Scalars['JSONString']>;
};

export type ApproveEmailChange = {
  __typename?: 'ApproveEmailChange';
  detail?: Maybe<Scalars['String']>;
};

export type CrmOpportunityNode = CustomNode & {
  __typename?: 'CRMOpportunityNode';
  id: Scalars['ID'];
  accountName?: Maybe<Scalars['String']>;
  dealName: Scalars['String'];
  stage: ProjectCrmOpportunityStageChoices;
};

export type CrmOpportunityNodeConnection = {
  __typename?: 'CRMOpportunityNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CrmOpportunityNodeEdge>>;
};

export type CrmOpportunityNodeEdge = {
  __typename?: 'CRMOpportunityNodeEdge';
  node?: Maybe<CrmOpportunityNode>;
  cursor: Scalars['String'];
};

export enum ClientClientProfileClientTypeChoices {
  A_1 = 'A_1',
  A_0 = 'A_0'
}

export enum ClientClientProfileVisibilityChoices {
  A_1 = 'A_1',
  A_0 = 'A_0'
}

export type ClientProfileNode = CustomNode & {
  __typename?: 'ClientProfileNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  nameShort?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  clientType: ClientClientProfileClientTypeChoices;
  user?: Maybe<UserNode>;
  supplierId?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  visibility: ClientClientProfileVisibilityChoices;
  accountManager?: Maybe<UserNode>;
  order?: Maybe<Scalars['Int']>;
  isInternal: Scalars['Boolean'];
  projects: ProjectNodeConnection;
};


export type ClientProfileNodeProjectsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  predecessor?: Maybe<Scalars['ID']>;
  predecessor_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  predecessor_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<Scalars['ID']>;
  parent_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  nameInternal?: Maybe<Scalars['String']>;
  nameInternal_Iexact?: Maybe<Scalars['String']>;
  nameInternal_Icontains?: Maybe<Scalars['String']>;
  nameInternal_Istartswith?: Maybe<Scalars['String']>;
  nameInternal_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameInternal_Isnull?: Maybe<Scalars['Boolean']>;
  nameShort?: Maybe<Scalars['String']>;
  nameShort_Iexact?: Maybe<Scalars['String']>;
  nameShort_Icontains?: Maybe<Scalars['String']>;
  nameShort_Istartswith?: Maybe<Scalars['String']>;
  nameShort_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameShort_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_Id?: Maybe<Scalars['Float']>;
  costCenter_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_Id_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_PublicId?: Maybe<Scalars['String']>;
  costCenter_PublicId_Iexact?: Maybe<Scalars['String']>;
  costCenter_PublicId_Icontains?: Maybe<Scalars['String']>;
  costCenter_PublicId_Istartswith?: Maybe<Scalars['String']>;
  costCenter_PublicId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_PublicId_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<Scalars['String']>;
  projectType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  clientProfile_Id?: Maybe<Scalars['Float']>;
  clientProfile_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientProfile_Id_Isnull?: Maybe<Scalars['Boolean']>;
  clientContract_Id?: Maybe<Scalars['Float']>;
  clientContract_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientContract_Id_Isnull?: Maybe<Scalars['Boolean']>;
  documentation_Id?: Maybe<Scalars['Float']>;
  documentation_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentation_Id_Isnull?: Maybe<Scalars['Boolean']>;
  responsible?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  mine?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  trackable?: Maybe<Scalars['Boolean']>;
  me?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Scalars['ID']>;
  invitations?: Maybe<Scalars['ID']>;
};

export type ClientProfileNodeConnection = {
  __typename?: 'ClientProfileNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ClientProfileNodeEdge>>;
};

export type ClientProfileNodeEdge = {
  __typename?: 'ClientProfileNodeEdge';
  node?: Maybe<ClientProfileNode>;
  cursor: Scalars['String'];
};

export type ClusterNode = CustomNode & {
  __typename?: 'ClusterNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  parent?: Maybe<ClusterNode>;
  name: Scalars['String'];
  roleName: Scalars['String'];
  slug: Scalars['String'];
  code: Scalars['String'];
  tools: ToolNodeConnection;
  owner?: Maybe<UserNode>;
  color: Scalars['String'];
  children: ClusterNodeConnection;
  workers: MyWorkerNodeConnection;
  projectRoles: ProjectRoleNodeConnection;
};


export type ClusterNodeToolsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type ClusterNodeChildrenArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};


export type ClusterNodeWorkersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  user_DateJoined_Gt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Gte?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lte?: Maybe<Scalars['DateTime']>;
  user_Position_Id?: Maybe<Scalars['Float']>;
  user_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  lastStateChange_Gt?: Maybe<Scalars['Date']>;
  lastStateChange_Gte?: Maybe<Scalars['Date']>;
  lastStateChange_Lt?: Maybe<Scalars['Date']>;
  lastStateChange_Lte?: Maybe<Scalars['Date']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  country_Iexact?: Maybe<Scalars['String']>;
  country_Icontains?: Maybe<Scalars['String']>;
  country_Istartswith?: Maybe<Scalars['String']>;
  country_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<Scalars['String']>;
  nationality_Iexact?: Maybe<Scalars['String']>;
  nationality_Icontains?: Maybe<Scalars['String']>;
  nationality_Istartswith?: Maybe<Scalars['String']>;
  nationality_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nationality_Isnull?: Maybe<Scalars['Boolean']>;
  workLocation?: Maybe<Scalars['String']>;
  workLocation_Iexact?: Maybe<Scalars['String']>;
  workLocation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  workLocation_Isnull?: Maybe<Scalars['Boolean']>;
  invitations_Project_Id?: Maybe<Scalars['Float']>;
  invitations_Project_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  invitations_Project_Id_Isnull?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_Iexact?: Maybe<Scalars['String']>;
  fullName_Icontains?: Maybe<Scalars['String']>;
  fullName_Istartswith?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  userType_Iexact?: Maybe<Scalars['String']>;
  userType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  userType_Isnull?: Maybe<Scalars['Boolean']>;
  tools_Tool_Id?: Maybe<Scalars['Float']>;
  tools_Tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  showArchived?: Maybe<Scalars['Boolean']>;
  appliedForSpecific?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  clusters?: Maybe<Scalars['ID']>;
  tags?: Maybe<Scalars['ID']>;
  department?: Maybe<Scalars['ID']>;
  skillEstimation?: Maybe<Scalars['ID']>;
  activeContract?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isCore?: Maybe<Scalars['Boolean']>;
  dashSearch?: Maybe<Scalars['String']>;
};


export type ClusterNodeProjectRolesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_Iexact?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  adminComment?: Maybe<Scalars['String']>;
  adminComment_Iexact?: Maybe<Scalars['String']>;
  adminComment_Icontains?: Maybe<Scalars['String']>;
  adminComment_Istartswith?: Maybe<Scalars['String']>;
  adminComment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminComment_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Iexact?: Maybe<Scalars['Date']>;
  startDate_Icontains?: Maybe<Scalars['Date']>;
  startDate_Istartswith?: Maybe<Scalars['Date']>;
  startDate_In?: Maybe<Array<Maybe<Scalars['Date']>>>;
  startDate_Isnull?: Maybe<Scalars['Boolean']>;
  budget?: Maybe<Scalars['Float']>;
  budget_Iexact?: Maybe<Scalars['Float']>;
  budget_Icontains?: Maybe<Scalars['Float']>;
  budget_Istartswith?: Maybe<Scalars['Float']>;
  budget_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  budget_Isnull?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_Icontains?: Maybe<Scalars['Boolean']>;
  isStaffed?: Maybe<Scalars['Boolean']>;
  isStaffed_Icontains?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  cluster?: Maybe<Scalars['ID']>;
  tools?: Maybe<Scalars['ID']>;
  onlyPublic?: Maybe<Scalars['Boolean']>;
};

export type ClusterNodeConnection = {
  __typename?: 'ClusterNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ClusterNodeEdge>>;
};

export type ClusterNodeEdge = {
  __typename?: 'ClusterNodeEdge';
  node?: Maybe<ClusterNode>;
  cursor: Scalars['String'];
};

export type ConfirmEmailChange = {
  __typename?: 'ConfirmEmailChange';
  detail?: Maybe<Scalars['String']>;
};

export enum ContractProjectClientContractBillingChoices {
  Deposit = 'DEPOSIT',
  Milestone = 'MILESTONE',
  DepositAndMilestone = 'DEPOSIT_AND_MILESTONE'
}

export enum ContractProjectClientContractLanguageChoices {
  De = 'DE',
  En = 'EN'
}

export enum ContractProjectClientContractPricingTypeChoices {
  Sprint = 'SPRINT',
  Task = 'TASK',
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Sp = 'SP'
}

export enum ContractProjectClientContractStateChoices {
  Draft = 'DRAFT',
  Reviewed = 'REVIEWED',
  Approved = 'APPROVED',
  Billed = 'BILLED',
  Paid = 'PAID',
  Rejected = 'REJECTED'
}

export enum ContractProjectClientContractTypeChoices {
  Consultancy = 'CONSULTANCY',
  Services = 'SERVICES',
  Extension = 'EXTENSION',
  Agile = 'AGILE',
  Sp = 'SP'
}

export enum ContractProjectWorkerContractTypeChoices {
  WorkingStudent = 'WORKING_STUDENT',
  Freelancer = 'FREELANCER',
  Minijob = 'MINIJOB',
  Internship = 'INTERNSHIP',
  Temporary = 'TEMPORARY',
  Permanent = 'PERMANENT',
  PartnerEmployment = 'PARTNER_EMPLOYMENT'
}

export enum ContractProjectWorkerContractTypeOfSalaryChoices {
  FixSalary = 'FIX_SALARY',
  FixSalaryAllIn = 'FIX_SALARY_ALL_IN',
  HourlyWage = 'HOURLY_WAGE',
  Freelancer = 'FREELANCER'
}

export enum ContractProjectWorkerContractWorkLocationChoices {
  Munich = 'MUNICH',
  Stuttgart = 'STUTTGART',
  Berlin = 'BERLIN',
  Dubai = 'DUBAI',
  Serbia = 'SERBIA',
  India = 'INDIA',
  Remote = 'REMOTE'
}

export type CostCenterNode = CustomNode & {
  __typename?: 'CostCenterNode';
  publicId: Scalars['String'];
  name: Scalars['String'];
  internal: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type CreateTimelog = {
  __typename?: 'CreateTimelog';
  timelog?: Maybe<TimelogNode>;
};

export type CreateToolAndAddWorker = {
  __typename?: 'CreateToolAndAddWorker';
  workerTool?: Maybe<WorkerToolNode>;
};

export type CreateWorkerLanguage = {
  __typename?: 'CreateWorkerLanguage';
  workerLanguage?: Maybe<WorkerLanguageNode>;
};

export type CreateWorkerTool = {
  __typename?: 'CreateWorkerTool';
  workerTool?: Maybe<WorkerToolNode>;
};

export type CustomNode = {
  id: Scalars['ID'];
};




export type DeleteTimelog = {
  __typename?: 'DeleteTimelog';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type DeleteWorkerLanguage = {
  __typename?: 'DeleteWorkerLanguage';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type DeleteWorkerTool = {
  __typename?: 'DeleteWorkerTool';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type DepartmentNode = CustomNode & {
  __typename?: 'DepartmentNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  name: Scalars['String'];
  responsible?: Maybe<MyWorkerNode>;
  workerprofileSet: MyWorkerNodeConnection;
  projectworkercontractSet: ProjectWorkerContractNodeConnection;
};


export type DepartmentNodeWorkerprofileSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  user_DateJoined_Gt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Gte?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lte?: Maybe<Scalars['DateTime']>;
  user_Position_Id?: Maybe<Scalars['Float']>;
  user_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  lastStateChange_Gt?: Maybe<Scalars['Date']>;
  lastStateChange_Gte?: Maybe<Scalars['Date']>;
  lastStateChange_Lt?: Maybe<Scalars['Date']>;
  lastStateChange_Lte?: Maybe<Scalars['Date']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  country_Iexact?: Maybe<Scalars['String']>;
  country_Icontains?: Maybe<Scalars['String']>;
  country_Istartswith?: Maybe<Scalars['String']>;
  country_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<Scalars['String']>;
  nationality_Iexact?: Maybe<Scalars['String']>;
  nationality_Icontains?: Maybe<Scalars['String']>;
  nationality_Istartswith?: Maybe<Scalars['String']>;
  nationality_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nationality_Isnull?: Maybe<Scalars['Boolean']>;
  workLocation?: Maybe<Scalars['String']>;
  workLocation_Iexact?: Maybe<Scalars['String']>;
  workLocation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  workLocation_Isnull?: Maybe<Scalars['Boolean']>;
  invitations_Project_Id?: Maybe<Scalars['Float']>;
  invitations_Project_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  invitations_Project_Id_Isnull?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_Iexact?: Maybe<Scalars['String']>;
  fullName_Icontains?: Maybe<Scalars['String']>;
  fullName_Istartswith?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  userType_Iexact?: Maybe<Scalars['String']>;
  userType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  userType_Isnull?: Maybe<Scalars['Boolean']>;
  tools_Tool_Id?: Maybe<Scalars['Float']>;
  tools_Tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  showArchived?: Maybe<Scalars['Boolean']>;
  appliedForSpecific?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  clusters?: Maybe<Scalars['ID']>;
  tags?: Maybe<Scalars['ID']>;
  department?: Maybe<Scalars['ID']>;
  skillEstimation?: Maybe<Scalars['ID']>;
  activeContract?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isCore?: Maybe<Scalars['Boolean']>;
  dashSearch?: Maybe<Scalars['String']>;
};


export type DepartmentNodeProjectworkercontractSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_Iexact?: Maybe<Scalars['String']>;
  type_Icontains?: Maybe<Scalars['String']>;
  type_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Scalars['Float']>;
  payment_Iexact?: Maybe<Scalars['Float']>;
  payment_Icontains?: Maybe<Scalars['Float']>;
  payment_Istartswith?: Maybe<Scalars['Float']>;
  payment_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  payment_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  worker?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['ID']>;
  isCore?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type DepartmentNodeConnection = {
  __typename?: 'DepartmentNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DepartmentNodeEdge>>;
};

export type DepartmentNodeEdge = {
  __typename?: 'DepartmentNodeEdge';
  node?: Maybe<DepartmentNode>;
  cursor: Scalars['String'];
};

export type EnumNode = {
  __typename?: 'EnumNode';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type FileNode = {
  __typename?: 'FileNode';
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type GroupNode = CustomNode & {
  __typename?: 'GroupNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  positionSet: PositionNodeConnection;
};


export type GroupNodePositionSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};

export type GroupNodeConnection = {
  __typename?: 'GroupNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GroupNodeEdge>>;
};

export type GroupNodeEdge = {
  __typename?: 'GroupNodeEdge';
  node?: Maybe<GroupNode>;
  cursor: Scalars['String'];
};

export type InitateEmailChange = {
  __typename?: 'InitateEmailChange';
  detail?: Maybe<Scalars['String']>;
};

export type InvoiceNode = CustomNode & {
  __typename?: 'InvoiceNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  state: PaymentInvoiceStateChoices;
  type: PaymentInvoiceTypeChoices;
  author?: Maybe<UserNode>;
  language: PaymentInvoiceLanguageChoices;
  pretaxDeduction: Scalars['Boolean'];
  reverseCharge: Scalars['Boolean'];
  dueDate?: Maybe<Scalars['Date']>;
  legacyId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  customPublicId?: Maybe<Scalars['String']>;
  recipientSel: PaymentInvoiceRecipientSelChoices;
  recipientId?: Maybe<Scalars['Int']>;
  costCenter: CostCenterNode;
  clientContract?: Maybe<ProjectClientContractNode>;
  invitation?: Maybe<ProjectInvitationNode>;
  sentDate?: Maybe<Scalars['Date']>;
  billingDate?: Maybe<Scalars['Date']>;
  billingEmail?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  additionalAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<PaymentInvoiceCountryChoices>;
  accountHolder?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  pdf?: Maybe<FileNode>;
  appendix?: Maybe<Scalars['String']>;
  appendixPdf?: Maybe<FileNode>;
  additionalParams?: Maybe<Scalars['GenericScalar']>;
  pdfPublicId?: Maybe<Scalars['String']>;
  receiptMessageId?: Maybe<Scalars['String']>;
  publicId: Scalars['String'];
  discount: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  category?: Maybe<PaymentInvoiceCategoryChoices>;
  lineItems: LineItemNodeConnection;
};


export type InvoiceNodeLineItemsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  message_Icontains?: Maybe<Scalars['String']>;
  amountNet?: Maybe<Scalars['Float']>;
  amountNet_Gt?: Maybe<Scalars['Float']>;
  amountNet_Gte?: Maybe<Scalars['Float']>;
  amountNet_Lt?: Maybe<Scalars['Float']>;
  amountNet_Lte?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  invoice?: Maybe<Scalars['ID']>;
};

export type InvoiceNodeConnection = {
  __typename?: 'InvoiceNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<InvoiceNodeEdge>>;
};

export type InvoiceNodeEdge = {
  __typename?: 'InvoiceNodeEdge';
  node?: Maybe<InvoiceNode>;
  cursor: Scalars['String'];
};


export type JiraIssueNode = CustomNode & {
  __typename?: 'JiraIssueNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  summary: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  jiraId: Scalars['Int'];
  jiraKey: Scalars['String'];
  type: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  storyPoints?: Maybe<Scalars['Float']>;
  timelogs: TimelogNodeConnection;
};


export type JiraIssueNodeTimelogsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  comment_Iexact?: Maybe<Scalars['String']>;
  comment_Icontains?: Maybe<Scalars['String']>;
  comment_Istartswith?: Maybe<Scalars['String']>;
  comment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  isPayrollRelevant?: Maybe<Scalars['Boolean']>;
  date_Gt?: Maybe<Scalars['Date']>;
  date_Gte?: Maybe<Scalars['Date']>;
  date_Lt?: Maybe<Scalars['Date']>;
  date_Lte?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['Date']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  paidDate_Gt?: Maybe<Scalars['DateTime']>;
  paidDate_Gte?: Maybe<Scalars['DateTime']>;
  paidDate_Lt?: Maybe<Scalars['DateTime']>;
  paidDate_Lte?: Maybe<Scalars['DateTime']>;
  timelogType?: Maybe<Scalars['String']>;
  timelogType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  project?: Maybe<Scalars['ID']>;
  worker?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};

export type JiraIssueNodeConnection = {
  __typename?: 'JiraIssueNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<JiraIssueNodeEdge>>;
};

export type JiraIssueNodeEdge = {
  __typename?: 'JiraIssueNodeEdge';
  node?: Maybe<JiraIssueNode>;
  cursor: Scalars['String'];
};

export type LineItemNode = CustomNode & {
  __typename?: 'LineItemNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  invoice?: Maybe<InvoiceNode>;
  message: Scalars['String'];
  amountNet: Scalars['Decimal'];
  publicId?: Maybe<Scalars['String']>;
  category?: Maybe<PaymentLineItemCategoryChoices>;
};

export type LineItemNodeConnection = {
  __typename?: 'LineItemNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<LineItemNodeEdge>>;
};

export type LineItemNodeEdge = {
  __typename?: 'LineItemNodeEdge';
  node?: Maybe<LineItemNode>;
  cursor: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateWorkerTool?: Maybe<UpdateWorkerTool>;
  deleteWorkerTool?: Maybe<DeleteWorkerTool>;
  createWorkerTool?: Maybe<CreateWorkerTool>;
  createToolAndWorkerTool?: Maybe<CreateToolAndAddWorker>;
  updateProjectColor?: Maybe<UpdateProjectColor>;
  createTimelog?: Maybe<CreateTimelog>;
  updateTimelog?: Maybe<UpdateTimelog>;
  deleteTimelog?: Maybe<DeleteTimelog>;
  appendTimelog?: Maybe<AppendTimelog>;
  updateUser?: Maybe<UpdateUser>;
  initiateEmailChange?: Maybe<InitateEmailChange>;
  approveEmailChange?: Maybe<ApproveEmailChange>;
  confirmEmailChange?: Maybe<ConfirmEmailChange>;
  updateWorker?: Maybe<UpdateWorker>;
  setWorkerClusters?: Maybe<SetWorkerClusters>;
  createWorkerLanguage?: Maybe<CreateWorkerLanguage>;
  updateWorkerLanguage?: Maybe<UpdateWorkerLanguage>;
  deleteWorkerLanguage?: Maybe<DeleteWorkerLanguage>;
};


export type MutationUpdateWorkerToolArgs = {
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};


export type MutationDeleteWorkerToolArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationCreateWorkerToolArgs = {
  rating?: Maybe<Scalars['Int']>;
  tool?: Maybe<Scalars['String']>;
};


export type MutationCreateToolAndWorkerToolArgs = {
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};


export type MutationUpdateProjectColorArgs = {
  color: Scalars['String'];
  project: Scalars['ID'];
};


export type MutationCreateTimelogArgs = {
  comment?: Maybe<Scalars['String']>;
  crmOpportunityId?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  durationSeconds: Scalars['Int'];
  jiraIssues?: Maybe<Array<Scalars['ID']>>;
  project?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSONString']>;
  tags?: Maybe<Array<Scalars['ID']>>;
  timelogType?: Maybe<Scalars['String']>;
};


export type MutationUpdateTimelogArgs = {
  comment?: Maybe<Scalars['String']>;
  crmOpportunityId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  durationSeconds?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  jiraIssues?: Maybe<Array<Scalars['ID']>>;
  project?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['JSONString']>;
  tags?: Maybe<Array<Scalars['ID']>>;
};


export type MutationDeleteTimelogArgs = {
  id: Scalars['ID'];
};


export type MutationAppendTimelogArgs = {
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  durationSeconds: Scalars['Int'];
  jiraIssues?: Maybe<Array<Scalars['ID']>>;
  project: Scalars['ID'];
  properties?: Maybe<Scalars['JSONString']>;
  tags?: Maybe<Array<Scalars['ID']>>;
};


export type MutationUpdateUserArgs = {
  displayName?: Maybe<Scalars['String']>;
};


export type MutationInitiateEmailChangeArgs = {
  newEmail: Scalars['String'];
};


export type MutationApproveEmailChangeArgs = {
  raw: Scalars['String'];
  sign: Scalars['String'];
};


export type MutationConfirmEmailChangeArgs = {
  raw: Scalars['String'];
  sign: Scalars['String'];
};


export type MutationUpdateWorkerArgs = {
  phone?: Maybe<Scalars['String']>;
};


export type MutationSetWorkerClustersArgs = {
  clusters?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateWorkerLanguageArgs = {
  language?: Maybe<Scalars['String']>;
  proficiency?: Maybe<Scalars['Int']>;
};


export type MutationUpdateWorkerLanguageArgs = {
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  proficiency?: Maybe<Scalars['Int']>;
};


export type MutationDeleteWorkerLanguageArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MyWorkerNode = CustomNode & {
  __typename?: 'MyWorkerNode';
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
  fullName: Scalars['String'];
  state: WorkerWorkerProfileStateChoices;
  picture?: Maybe<FileNode>;
  department?: Maybe<DepartmentNode>;
  clusters: ClusterNodeConnection;
  workLocation: WorkerWorkerProfileWorkLocationChoices;
  tools: WorkerToolNodeConnection;
  cv?: Maybe<FileNode>;
  cvText?: Maybe<Scalars['String']>;
  isPermanent?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  weeklyHours?: Maybe<Scalars['Int']>;
  latestRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  referralCode?: Maybe<Scalars['String']>;
};


export type MyWorkerNodeClustersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};


export type MyWorkerNodeToolsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Name?: Maybe<Scalars['String']>;
  tool_Name_Iexact?: Maybe<Scalars['String']>;
  tool_Name_Icontains?: Maybe<Scalars['String']>;
  tool_Name_Istartswith?: Maybe<Scalars['String']>;
  tool_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Id?: Maybe<Scalars['Float']>;
  tool_Id_Iexact?: Maybe<Scalars['Float']>;
  tool_Id_Icontains?: Maybe<Scalars['Float']>;
  tool_Id_Istartswith?: Maybe<Scalars['Float']>;
  tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type MyWorkerNodeConnection = {
  __typename?: 'MyWorkerNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MyWorkerNodeEdge>>;
};

export type MyWorkerNodeEdge = {
  __typename?: 'MyWorkerNodeEdge';
  node?: Maybe<MyWorkerNode>;
  cursor: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export enum PaymentInvoiceCategoryChoices {
  Teambuilding = 'TEAMBUILDING',
  Transportation = 'TRANSPORTATION',
  Software = 'SOFTWARE',
  Hardware = 'HARDWARE',
  Education = 'EDUCATION',
  Hospitality = 'HOSPITALITY',
  Events = 'EVENTS',
  Accomodation = 'ACCOMODATION'
}

export enum PaymentInvoiceCountryChoices {
  Af = 'AF',
  Ax = 'AX',
  Al = 'AL',
  Dz = 'DZ',
  As = 'AS',
  Ad = 'AD',
  Ao = 'AO',
  Ai = 'AI',
  Aq = 'AQ',
  Ag = 'AG',
  Ar = 'AR',
  Am = 'AM',
  Aw = 'AW',
  Au = 'AU',
  At = 'AT',
  Az = 'AZ',
  Bs = 'BS',
  Bh = 'BH',
  Bd = 'BD',
  Bb = 'BB',
  By = 'BY',
  Be = 'BE',
  Bz = 'BZ',
  Bj = 'BJ',
  Bm = 'BM',
  Bt = 'BT',
  Bo = 'BO',
  Bq = 'BQ',
  Ba = 'BA',
  Bw = 'BW',
  Bv = 'BV',
  Br = 'BR',
  Io = 'IO',
  Bn = 'BN',
  Bg = 'BG',
  Bf = 'BF',
  Bi = 'BI',
  Cv = 'CV',
  Kh = 'KH',
  Cm = 'CM',
  Ca = 'CA',
  Ky = 'KY',
  Cf = 'CF',
  Td = 'TD',
  Cl = 'CL',
  Cn = 'CN',
  Cx = 'CX',
  Cc = 'CC',
  Co = 'CO',
  Km = 'KM',
  Cg = 'CG',
  Cd = 'CD',
  Ck = 'CK',
  Cr = 'CR',
  Ci = 'CI',
  Hr = 'HR',
  Cu = 'CU',
  Cw = 'CW',
  Cy = 'CY',
  Cz = 'CZ',
  Dk = 'DK',
  Dj = 'DJ',
  Dm = 'DM',
  Do = 'DO',
  Ec = 'EC',
  Eg = 'EG',
  Sv = 'SV',
  Gq = 'GQ',
  Er = 'ER',
  Ee = 'EE',
  Sz = 'SZ',
  Et = 'ET',
  Fk = 'FK',
  Fo = 'FO',
  Fj = 'FJ',
  Fi = 'FI',
  Fr = 'FR',
  Gf = 'GF',
  Pf = 'PF',
  Tf = 'TF',
  Ga = 'GA',
  Gm = 'GM',
  Ge = 'GE',
  De = 'DE',
  Gh = 'GH',
  Gi = 'GI',
  Gr = 'GR',
  Gl = 'GL',
  Gd = 'GD',
  Gp = 'GP',
  Gu = 'GU',
  Gt = 'GT',
  Gg = 'GG',
  Gn = 'GN',
  Gw = 'GW',
  Gy = 'GY',
  Ht = 'HT',
  Hm = 'HM',
  Va = 'VA',
  Hn = 'HN',
  Hk = 'HK',
  Hu = 'HU',
  Is = 'IS',
  In = 'IN',
  Id = 'ID',
  Ir = 'IR',
  Iq = 'IQ',
  Ie = 'IE',
  Im = 'IM',
  Il = 'IL',
  It = 'IT',
  Jm = 'JM',
  Jp = 'JP',
  Je = 'JE',
  Jo = 'JO',
  Kz = 'KZ',
  Ke = 'KE',
  Ki = 'KI',
  Kw = 'KW',
  Kg = 'KG',
  La = 'LA',
  Lv = 'LV',
  Lb = 'LB',
  Ls = 'LS',
  Lr = 'LR',
  Ly = 'LY',
  Li = 'LI',
  Lt = 'LT',
  Lu = 'LU',
  Mo = 'MO',
  Mg = 'MG',
  Mw = 'MW',
  My = 'MY',
  Mv = 'MV',
  Ml = 'ML',
  Mt = 'MT',
  Mh = 'MH',
  Mq = 'MQ',
  Mr = 'MR',
  Mu = 'MU',
  Yt = 'YT',
  Mx = 'MX',
  Fm = 'FM',
  Md = 'MD',
  Mc = 'MC',
  Mn = 'MN',
  Me = 'ME',
  Ms = 'MS',
  Ma = 'MA',
  Mz = 'MZ',
  Mm = 'MM',
  Na = 'NA',
  Nr = 'NR',
  Np = 'NP',
  Nl = 'NL',
  Nc = 'NC',
  Nz = 'NZ',
  Ni = 'NI',
  Ne = 'NE',
  Ng = 'NG',
  Nu = 'NU',
  Nf = 'NF',
  Kp = 'KP',
  Mk = 'MK',
  Mp = 'MP',
  No = 'NO',
  Om = 'OM',
  Pk = 'PK',
  Pw = 'PW',
  Ps = 'PS',
  Pa = 'PA',
  Pg = 'PG',
  Py = 'PY',
  Pe = 'PE',
  Ph = 'PH',
  Pn = 'PN',
  Pl = 'PL',
  Pt = 'PT',
  Pr = 'PR',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Ru = 'RU',
  Rw = 'RW',
  Bl = 'BL',
  Sh = 'SH',
  Kn = 'KN',
  Lc = 'LC',
  Mf = 'MF',
  Pm = 'PM',
  Vc = 'VC',
  Ws = 'WS',
  Sm = 'SM',
  St = 'ST',
  Sa = 'SA',
  Sn = 'SN',
  Rs = 'RS',
  Sc = 'SC',
  Sl = 'SL',
  Sg = 'SG',
  Sx = 'SX',
  Sk = 'SK',
  Si = 'SI',
  Sb = 'SB',
  So = 'SO',
  Za = 'ZA',
  Gs = 'GS',
  Kr = 'KR',
  Ss = 'SS',
  Es = 'ES',
  Lk = 'LK',
  Sd = 'SD',
  Sr = 'SR',
  Sj = 'SJ',
  Se = 'SE',
  Ch = 'CH',
  Sy = 'SY',
  Tw = 'TW',
  Tj = 'TJ',
  Tz = 'TZ',
  Th = 'TH',
  Tl = 'TL',
  Tg = 'TG',
  Tk = 'TK',
  To = 'TO',
  Tt = 'TT',
  Tn = 'TN',
  Tr = 'TR',
  Tm = 'TM',
  Tc = 'TC',
  Tv = 'TV',
  Ug = 'UG',
  Ua = 'UA',
  Ae = 'AE',
  Gb = 'GB',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Vu = 'VU',
  Ve = 'VE',
  Vn = 'VN',
  Vg = 'VG',
  Vi = 'VI',
  Wf = 'WF',
  Eh = 'EH',
  Ye = 'YE',
  Zm = 'ZM',
  Zw = 'ZW'
}

export enum PaymentInvoiceLanguageChoices {
  De = 'DE',
  En = 'EN'
}

export enum PaymentInvoiceRecipientSelChoices {
  A_0 = 'A_0',
  A_1 = 'A_1'
}

export enum PaymentInvoiceStateChoices {
  New = 'NEW',
  Open = 'OPEN',
  Approved = 'APPROVED',
  Sent = 'SENT',
  Paid = 'PAID',
  Rejected = 'REJECTED',
  Void = 'VOID',
  Converted = 'CONVERTED',
  NewVersion = 'NEW_VERSION'
}

export enum PaymentInvoiceTypeChoices {
  R = 'R',
  P = 'P',
  G = 'G',
  S = 'S',
  L = 'L',
  E = 'E',
  C = 'C',
  D = 'D'
}

export enum PaymentLineItemCategoryChoices {
  Development = 'DEVELOPMENT',
  Travel = 'TRAVEL',
  AdditionalExpenses = 'ADDITIONAL_EXPENSES'
}

export type PositionNode = CustomNode & {
  __typename?: 'PositionNode';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PositionNodeConnection = {
  __typename?: 'PositionNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PositionNodeEdge>>;
};

export type PositionNodeEdge = {
  __typename?: 'PositionNodeEdge';
  node?: Maybe<PositionNode>;
  cursor: Scalars['String'];
};

export enum ProjectCrmOpportunityStageChoices {
  MundlicheZusage = 'MUNDLICHE_ZUSAGE',
  Prospecting = 'PROSPECTING',
  ProposalPriceQuote = 'PROPOSAL_PRICE_QUOTE',
  NeedsAnalysis = 'NEEDS_ANALYSIS',
  ClosedWon = 'CLOSED_WON',
  ClosedWonFrameworkContract = 'CLOSED_WON_FRAMEWORK_CONTRACT',
  ClosedLost = 'CLOSED_LOST',
  NegotiationReview = 'NEGOTIATION_REVIEW'
}

export type ProjectClientContractNode = CustomNode & {
  __typename?: 'ProjectClientContractNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  state: ContractProjectClientContractStateChoices;
  author?: Maybe<UserNode>;
  coverText?: Maybe<Scalars['String']>;
  pdfPublicId?: Maybe<Scalars['String']>;
  legalOverwrite: Scalars['Boolean'];
  legalText: Scalars['String'];
  language: ContractProjectClientContractLanguageChoices;
  body: Scalars['String'];
  pricing: Scalars['String'];
  contract?: Maybe<FileNode>;
  contractSigned?: Maybe<FileNode>;
  calculationFile?: Maybe<FileNode>;
  calculationParams?: Maybe<Scalars['GenericScalar']>;
  orderConfirmation?: Maybe<FileNode>;
  project: ProjectNode;
  safety?: Maybe<Scalars['Decimal']>;
  additionalExpenses: Scalars['Decimal'];
  travelExpenses: Scalars['Decimal'];
  hourlyRate: Scalars['Decimal'];
  storyPointRate?: Maybe<Scalars['Decimal']>;
  deadline: Scalars['Date'];
  deductionValue: Scalars['Decimal'];
  keyAccountDiscount: Scalars['Decimal'];
  type: ContractProjectClientContractTypeChoices;
  paymentPeriod: Scalars['Int'];
  hidePricing: Scalars['Boolean'];
  twoClientSignatures: Scalars['Boolean'];
  hideRdTaxText: Scalars['Boolean'];
  hideProjectStagesText: Scalars['Boolean'];
  publicId: Scalars['String'];
  tax?: Maybe<Scalars['Decimal']>;
  netValueOverride?: Maybe<Scalars['Decimal']>;
  initialNetValue?: Maybe<Scalars['Decimal']>;
  unusedValue: Scalars['Decimal'];
  billing: ContractProjectClientContractBillingChoices;
  pricingType?: Maybe<ContractProjectClientContractPricingTypeChoices>;
  contractSignedOn?: Maybe<Scalars['Date']>;
  technicalReviewer?: Maybe<MyWorkerNode>;
  shareEnabled: Scalars['Boolean'];
  shareHash?: Maybe<Scalars['String']>;
  includeRateCard: Scalars['Boolean'];
  invoices: InvoiceNodeConnection;
};


export type ProjectClientContractNodeInvoicesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter?: Maybe<Scalars['ID']>;
  costCenter_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  costCenter_Isnull?: Maybe<Scalars['Boolean']>;
  clientContract?: Maybe<Scalars['ID']>;
  clientContract_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientContract_Isnull?: Maybe<Scalars['Boolean']>;
  recipientCt?: Maybe<Scalars['ID']>;
  recipientCt_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  recipientCt_Isnull?: Maybe<Scalars['Boolean']>;
  recipientId?: Maybe<Scalars['Int']>;
  recipientId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  recipientId_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  paidDate?: Maybe<Scalars['Date']>;
  paidDate_Gt?: Maybe<Scalars['Date']>;
  paidDate_Gte?: Maybe<Scalars['Date']>;
  paidDate_Lt?: Maybe<Scalars['Date']>;
  paidDate_Lte?: Maybe<Scalars['Date']>;
  billingDate?: Maybe<Scalars['Date']>;
  billingDate_Gt?: Maybe<Scalars['Date']>;
  billingDate_Gte?: Maybe<Scalars['Date']>;
  billingDate_Lt?: Maybe<Scalars['Date']>;
  billingDate_Lte?: Maybe<Scalars['Date']>;
  dueDate?: Maybe<Scalars['Date']>;
  dueDate_Gt?: Maybe<Scalars['Date']>;
  dueDate_Gte?: Maybe<Scalars['Date']>;
  dueDate_Lt?: Maybe<Scalars['Date']>;
  dueDate_Lte?: Maybe<Scalars['Date']>;
  category?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Float']>;
  tax_Gt?: Maybe<Scalars['Float']>;
  tax_Gte?: Maybe<Scalars['Float']>;
  tax_Lt?: Maybe<Scalars['Float']>;
  tax_Lte?: Maybe<Scalars['Float']>;
  includePlanned?: Maybe<Scalars['Boolean']>;
  includeRejected?: Maybe<Scalars['Boolean']>;
};

export type ProjectClientContractNodeConnection = {
  __typename?: 'ProjectClientContractNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProjectClientContractNodeEdge>>;
};

export type ProjectClientContractNodeEdge = {
  __typename?: 'ProjectClientContractNodeEdge';
  node?: Maybe<ProjectClientContractNode>;
  cursor: Scalars['String'];
};

export type ProjectInvitationNode = CustomNode & {
  __typename?: 'ProjectInvitationNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  state: ProjectProjectInvitationStateChoices;
  worker: MyWorkerNode;
  project: ProjectNode;
  role?: Maybe<ProjectRoleNode>;
  hourlyRate?: Maybe<Scalars['Decimal']>;
  color: Scalars['String'];
  responsibilities: Array<Scalars['String']>;
  plannedHours: ProjectInvitationPlannedHoursNodeConnection;
  invoices: InvoiceNodeConnection;
  stateLabel?: Maybe<Scalars['String']>;
  feedbackLink: Scalars['String'];
};


export type ProjectInvitationNodePlannedHoursArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  invitation_Worker?: Maybe<Scalars['ID']>;
  invitation_Worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  invitation_Worker_Isnull?: Maybe<Scalars['Boolean']>;
  weeklyHours?: Maybe<Scalars['Int']>;
  weeklyHours_Iexact?: Maybe<Scalars['Int']>;
  weeklyHours_Lte?: Maybe<Scalars['Int']>;
  weeklyHours_Gte?: Maybe<Scalars['Int']>;
  weeklyHours_Gt?: Maybe<Scalars['Int']>;
  weeklyHours_Lt?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Iexact?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
};


export type ProjectInvitationNodeInvoicesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter?: Maybe<Scalars['ID']>;
  costCenter_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  costCenter_Isnull?: Maybe<Scalars['Boolean']>;
  clientContract?: Maybe<Scalars['ID']>;
  clientContract_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientContract_Isnull?: Maybe<Scalars['Boolean']>;
  recipientCt?: Maybe<Scalars['ID']>;
  recipientCt_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  recipientCt_Isnull?: Maybe<Scalars['Boolean']>;
  recipientId?: Maybe<Scalars['Int']>;
  recipientId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  recipientId_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  paidDate?: Maybe<Scalars['Date']>;
  paidDate_Gt?: Maybe<Scalars['Date']>;
  paidDate_Gte?: Maybe<Scalars['Date']>;
  paidDate_Lt?: Maybe<Scalars['Date']>;
  paidDate_Lte?: Maybe<Scalars['Date']>;
  billingDate?: Maybe<Scalars['Date']>;
  billingDate_Gt?: Maybe<Scalars['Date']>;
  billingDate_Gte?: Maybe<Scalars['Date']>;
  billingDate_Lt?: Maybe<Scalars['Date']>;
  billingDate_Lte?: Maybe<Scalars['Date']>;
  dueDate?: Maybe<Scalars['Date']>;
  dueDate_Gt?: Maybe<Scalars['Date']>;
  dueDate_Gte?: Maybe<Scalars['Date']>;
  dueDate_Lt?: Maybe<Scalars['Date']>;
  dueDate_Lte?: Maybe<Scalars['Date']>;
  category?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Float']>;
  tax_Gt?: Maybe<Scalars['Float']>;
  tax_Gte?: Maybe<Scalars['Float']>;
  tax_Lt?: Maybe<Scalars['Float']>;
  tax_Lte?: Maybe<Scalars['Float']>;
  includePlanned?: Maybe<Scalars['Boolean']>;
  includeRejected?: Maybe<Scalars['Boolean']>;
};

export type ProjectInvitationNodeConnection = {
  __typename?: 'ProjectInvitationNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProjectInvitationNodeEdge>>;
};

export type ProjectInvitationNodeEdge = {
  __typename?: 'ProjectInvitationNodeEdge';
  node?: Maybe<ProjectInvitationNode>;
  cursor: Scalars['String'];
};

export type ProjectInvitationPlannedHoursNode = CustomNode & {
  __typename?: 'ProjectInvitationPlannedHoursNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  invitation: ProjectInvitationNode;
  weeklyHours: Scalars['Int'];
  startDate: Scalars['Date'];
};

export type ProjectInvitationPlannedHoursNodeConnection = {
  __typename?: 'ProjectInvitationPlannedHoursNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProjectInvitationPlannedHoursNodeEdge>>;
};

export type ProjectInvitationPlannedHoursNodeEdge = {
  __typename?: 'ProjectInvitationPlannedHoursNodeEdge';
  node?: Maybe<ProjectInvitationPlannedHoursNode>;
  cursor: Scalars['String'];
};

export type ProjectNode = CustomNode & {
  __typename?: 'ProjectNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameInternal: Scalars['String'];
  nameShort?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  workers: MyWorkerNodeConnection;
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  timelog: Scalars['Boolean'];
  image?: Maybe<FileNode>;
  costCenter?: Maybe<CostCenterNode>;
  roles: ProjectRoleNodeConnection;
  invitations: ProjectInvitationNodeConnection;
  costCenterInternal?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  clusters?: Maybe<ClusterNodeConnection>;
};


export type ProjectNodeWorkersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  user_DateJoined_Gt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Gte?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lte?: Maybe<Scalars['DateTime']>;
  user_Position_Id?: Maybe<Scalars['Float']>;
  user_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  lastStateChange_Gt?: Maybe<Scalars['Date']>;
  lastStateChange_Gte?: Maybe<Scalars['Date']>;
  lastStateChange_Lt?: Maybe<Scalars['Date']>;
  lastStateChange_Lte?: Maybe<Scalars['Date']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  country_Iexact?: Maybe<Scalars['String']>;
  country_Icontains?: Maybe<Scalars['String']>;
  country_Istartswith?: Maybe<Scalars['String']>;
  country_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<Scalars['String']>;
  nationality_Iexact?: Maybe<Scalars['String']>;
  nationality_Icontains?: Maybe<Scalars['String']>;
  nationality_Istartswith?: Maybe<Scalars['String']>;
  nationality_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nationality_Isnull?: Maybe<Scalars['Boolean']>;
  workLocation?: Maybe<Scalars['String']>;
  workLocation_Iexact?: Maybe<Scalars['String']>;
  workLocation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  workLocation_Isnull?: Maybe<Scalars['Boolean']>;
  invitations_Project_Id?: Maybe<Scalars['Float']>;
  invitations_Project_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  invitations_Project_Id_Isnull?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_Iexact?: Maybe<Scalars['String']>;
  fullName_Icontains?: Maybe<Scalars['String']>;
  fullName_Istartswith?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  userType_Iexact?: Maybe<Scalars['String']>;
  userType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  userType_Isnull?: Maybe<Scalars['Boolean']>;
  tools_Tool_Id?: Maybe<Scalars['Float']>;
  tools_Tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  showArchived?: Maybe<Scalars['Boolean']>;
  appliedForSpecific?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  clusters?: Maybe<Scalars['ID']>;
  tags?: Maybe<Scalars['ID']>;
  department?: Maybe<Scalars['ID']>;
  skillEstimation?: Maybe<Scalars['ID']>;
  activeContract?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isCore?: Maybe<Scalars['Boolean']>;
  dashSearch?: Maybe<Scalars['String']>;
};


export type ProjectNodeRolesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_Iexact?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  adminComment?: Maybe<Scalars['String']>;
  adminComment_Iexact?: Maybe<Scalars['String']>;
  adminComment_Icontains?: Maybe<Scalars['String']>;
  adminComment_Istartswith?: Maybe<Scalars['String']>;
  adminComment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminComment_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Iexact?: Maybe<Scalars['Date']>;
  startDate_Icontains?: Maybe<Scalars['Date']>;
  startDate_Istartswith?: Maybe<Scalars['Date']>;
  startDate_In?: Maybe<Array<Maybe<Scalars['Date']>>>;
  startDate_Isnull?: Maybe<Scalars['Boolean']>;
  budget?: Maybe<Scalars['Float']>;
  budget_Iexact?: Maybe<Scalars['Float']>;
  budget_Icontains?: Maybe<Scalars['Float']>;
  budget_Istartswith?: Maybe<Scalars['Float']>;
  budget_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  budget_Isnull?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_Icontains?: Maybe<Scalars['Boolean']>;
  isStaffed?: Maybe<Scalars['Boolean']>;
  isStaffed_Icontains?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  cluster?: Maybe<Scalars['ID']>;
  tools?: Maybe<Scalars['ID']>;
  onlyPublic?: Maybe<Scalars['Boolean']>;
};


export type ProjectNodeInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  worker_Isnull?: Maybe<Scalars['Boolean']>;
  worker_Department_Id?: Maybe<Scalars['Float']>;
  worker_Department_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_Department_Id_Isnull?: Maybe<Scalars['Boolean']>;
  worker_User_Position_Id?: Maybe<Scalars['Float']>;
  worker_User_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_User_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  project_CostCenter_Internal?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};


export type ProjectNodeClustersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};

export type ProjectNodeConnection = {
  __typename?: 'ProjectNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProjectNodeEdge>>;
};

export type ProjectNodeEdge = {
  __typename?: 'ProjectNodeEdge';
  node?: Maybe<ProjectNode>;
  cursor: Scalars['String'];
};

export type ProjectPlanNode = {
  __typename?: 'ProjectPlanNode';
  projectId?: Maybe<Scalars['String']>;
  plannedHours?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export enum ProjectProjectInvitationStateChoices {
  A_1 = 'A_1',
  A_2 = 'A_2',
  A_3 = 'A_3',
  A_7 = 'A_7',
  A_6 = 'A_6',
  A_8 = 'A_8'
}

export type ProjectRoleNode = CustomNode & {
  __typename?: 'ProjectRoleNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  isPublic: Scalars['Boolean'];
  isStaffed: Scalars['Boolean'];
  project: ProjectNode;
  cluster?: Maybe<ClusterNode>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  adminComment?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  hours?: Maybe<Scalars['Int']>;
  budget?: Maybe<Scalars['Decimal']>;
  hourlyRate?: Maybe<Scalars['Decimal']>;
  totalHours?: Maybe<Scalars['Decimal']>;
  workers: MyWorkerNodeConnection;
  tools: ToolNodeConnection;
  importedRow?: Maybe<Scalars['GenericScalar']>;
  invitations: ProjectInvitationNodeConnection;
};


export type ProjectRoleNodeWorkersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  user_DateJoined_Gt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Gte?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lte?: Maybe<Scalars['DateTime']>;
  user_Position_Id?: Maybe<Scalars['Float']>;
  user_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  lastStateChange_Gt?: Maybe<Scalars['Date']>;
  lastStateChange_Gte?: Maybe<Scalars['Date']>;
  lastStateChange_Lt?: Maybe<Scalars['Date']>;
  lastStateChange_Lte?: Maybe<Scalars['Date']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  country_Iexact?: Maybe<Scalars['String']>;
  country_Icontains?: Maybe<Scalars['String']>;
  country_Istartswith?: Maybe<Scalars['String']>;
  country_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<Scalars['String']>;
  nationality_Iexact?: Maybe<Scalars['String']>;
  nationality_Icontains?: Maybe<Scalars['String']>;
  nationality_Istartswith?: Maybe<Scalars['String']>;
  nationality_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nationality_Isnull?: Maybe<Scalars['Boolean']>;
  workLocation?: Maybe<Scalars['String']>;
  workLocation_Iexact?: Maybe<Scalars['String']>;
  workLocation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  workLocation_Isnull?: Maybe<Scalars['Boolean']>;
  invitations_Project_Id?: Maybe<Scalars['Float']>;
  invitations_Project_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  invitations_Project_Id_Isnull?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_Iexact?: Maybe<Scalars['String']>;
  fullName_Icontains?: Maybe<Scalars['String']>;
  fullName_Istartswith?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  userType_Iexact?: Maybe<Scalars['String']>;
  userType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  userType_Isnull?: Maybe<Scalars['Boolean']>;
  tools_Tool_Id?: Maybe<Scalars['Float']>;
  tools_Tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  showArchived?: Maybe<Scalars['Boolean']>;
  appliedForSpecific?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  clusters?: Maybe<Scalars['ID']>;
  tags?: Maybe<Scalars['ID']>;
  department?: Maybe<Scalars['ID']>;
  skillEstimation?: Maybe<Scalars['ID']>;
  activeContract?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isCore?: Maybe<Scalars['Boolean']>;
  dashSearch?: Maybe<Scalars['String']>;
};


export type ProjectRoleNodeToolsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type ProjectRoleNodeInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  worker_Isnull?: Maybe<Scalars['Boolean']>;
  worker_Department_Id?: Maybe<Scalars['Float']>;
  worker_Department_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_Department_Id_Isnull?: Maybe<Scalars['Boolean']>;
  worker_User_Position_Id?: Maybe<Scalars['Float']>;
  worker_User_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_User_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  project_CostCenter_Internal?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};

export type ProjectRoleNodeConnection = {
  __typename?: 'ProjectRoleNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProjectRoleNodeEdge>>;
};

export type ProjectRoleNodeEdge = {
  __typename?: 'ProjectRoleNodeEdge';
  node?: Maybe<ProjectRoleNode>;
  cursor: Scalars['String'];
};

export type ProjectStageNode = CustomNode & {
  __typename?: 'ProjectStageNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  projects: ProjectNodeConnection;
};


export type ProjectStageNodeProjectsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  predecessor?: Maybe<Scalars['ID']>;
  predecessor_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  predecessor_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<Scalars['ID']>;
  parent_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  nameInternal?: Maybe<Scalars['String']>;
  nameInternal_Iexact?: Maybe<Scalars['String']>;
  nameInternal_Icontains?: Maybe<Scalars['String']>;
  nameInternal_Istartswith?: Maybe<Scalars['String']>;
  nameInternal_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameInternal_Isnull?: Maybe<Scalars['Boolean']>;
  nameShort?: Maybe<Scalars['String']>;
  nameShort_Iexact?: Maybe<Scalars['String']>;
  nameShort_Icontains?: Maybe<Scalars['String']>;
  nameShort_Istartswith?: Maybe<Scalars['String']>;
  nameShort_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameShort_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_Id?: Maybe<Scalars['Float']>;
  costCenter_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_Id_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_PublicId?: Maybe<Scalars['String']>;
  costCenter_PublicId_Iexact?: Maybe<Scalars['String']>;
  costCenter_PublicId_Icontains?: Maybe<Scalars['String']>;
  costCenter_PublicId_Istartswith?: Maybe<Scalars['String']>;
  costCenter_PublicId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_PublicId_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<Scalars['String']>;
  projectType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  clientProfile_Id?: Maybe<Scalars['Float']>;
  clientProfile_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientProfile_Id_Isnull?: Maybe<Scalars['Boolean']>;
  clientContract_Id?: Maybe<Scalars['Float']>;
  clientContract_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientContract_Id_Isnull?: Maybe<Scalars['Boolean']>;
  documentation_Id?: Maybe<Scalars['Float']>;
  documentation_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentation_Id_Isnull?: Maybe<Scalars['Boolean']>;
  responsible?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  mine?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  trackable?: Maybe<Scalars['Boolean']>;
  me?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Scalars['ID']>;
  invitations?: Maybe<Scalars['ID']>;
};

export type ProjectStageNodeConnection = {
  __typename?: 'ProjectStageNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProjectStageNodeEdge>>;
};

export type ProjectStageNodeEdge = {
  __typename?: 'ProjectStageNodeEdge';
  node?: Maybe<ProjectStageNode>;
  cursor: Scalars['String'];
};

export type ProjectStats = {
  __typename?: 'ProjectStats';
  loggedSeconds?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectNode>;
};

export type ProjectWorkerContractNode = CustomNode & {
  __typename?: 'ProjectWorkerContractNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  type: ContractProjectWorkerContractTypeChoices;
  typeOfSalary: ContractProjectWorkerContractTypeOfSalaryChoices;
  worker: MyWorkerNode;
  workLocation: ContractProjectWorkerContractWorkLocationChoices;
  position?: Maybe<PositionNode>;
  department?: Maybe<DepartmentNode>;
  weeklyHours?: Maybe<Scalars['Int']>;
  maxTrackableWeeklyHours: Scalars['Int'];
  payment?: Maybe<Scalars['Decimal']>;
  yearlyGrossSalary?: Maybe<Scalars['Decimal']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  isCore: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  updated: Scalars['DateTime'];
};

export type ProjectWorkerContractNodeConnection = {
  __typename?: 'ProjectWorkerContractNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProjectWorkerContractNodeEdge>>;
};

export type ProjectWorkerContractNodeEdge = {
  __typename?: 'ProjectWorkerContractNodeEdge';
  node?: Maybe<ProjectWorkerContractNode>;
  cursor: Scalars['String'];
};

export type ProjectsCache = {
  __typename?: 'ProjectsCache';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Scalars['String'];
  isActive: Scalars['Boolean'];
  costCenterInternal: Scalars['Boolean'];
  weeklyHours?: Maybe<Scalars['Int']>;
};

export type PublicHolidayNode = CustomNode & {
  __typename?: 'PublicHolidayNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  name: Scalars['String'];
  location: VacationPublicHolidayLocationChoices;
};

export type PublicHolidayNodeConnection = {
  __typename?: 'PublicHolidayNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PublicHolidayNodeEdge>>;
};

export type PublicHolidayNodeEdge = {
  __typename?: 'PublicHolidayNodeEdge';
  node?: Maybe<PublicHolidayNode>;
  cursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allClients?: Maybe<ClientProfileNodeConnection>;
  allClusters?: Maybe<ClusterNodeConnection>;
  allDepartments?: Maybe<DepartmentNodeConnection>;
  allGroups?: Maybe<GroupNodeConnection>;
  allInvitations?: Maybe<ProjectInvitationNodeConnection>;
  allInvoices?: Maybe<InvoiceNodeConnection>;
  allJiraIssues?: Maybe<JiraIssueNodeConnection>;
  allPlannedHours?: Maybe<ProjectInvitationPlannedHoursNodeConnection>;
  allPositions?: Maybe<PositionNodeConnection>;
  allProjectContract?: Maybe<ProjectClientContractNodeConnection>;
  allProjectRoles?: Maybe<ProjectRoleNodeConnection>;
  allProjectStages?: Maybe<ProjectStageNodeConnection>;
  allProjects?: Maybe<ProjectNodeConnection>;
  allPublicHolidays?: Maybe<PublicHolidayNodeConnection>;
  allReferrals?: Maybe<WorkerReferralNodeConnection>;
  allTemplates?: Maybe<TemplateNodeConnection>;
  allTimelogTags?: Maybe<TimelogTagNodeConnection>;
  allTimelogs?: Maybe<TimelogNodeConnection>;
  allTools?: Maybe<ToolNodeConnection>;
  allUsers?: Maybe<UserNodeConnection>;
  allWorkerContracts?: Maybe<ProjectWorkerContractNodeConnection>;
  allWorkerLanguages?: Maybe<WorkerLanguageNodeConnection>;
  allWorkerNotes?: Maybe<WorkerNoteNodeConnection>;
  allWorkerSkillEstimationEntries?: Maybe<WorkerSkillEstimationEntryNodeConnection>;
  allWorkerSkillEstimations?: Maybe<WorkerSkillEstimationNodeConnection>;
  allWorkerTools?: Maybe<WorkerToolNodeConnection>;
  allWorkers?: Maybe<WorkerProfileNodeConnection>;
  client?: Maybe<ClientProfileNode>;
  cluster?: Maybe<ClusterNode>;
  group?: Maybe<GroupNode>;
  invitation?: Maybe<ProjectInvitationNode>;
  invoice?: Maybe<InvoiceNode>;
  jiraIssue?: Maybe<JiraIssueNode>;
  languageProficiencies?: Maybe<Array<Maybe<EnumNode>>>;
  languages?: Maybe<Array<Maybe<EnumNode>>>;
  myContracts?: Maybe<ProjectWorkerContractNodeConnection>;
  myInvitations?: Maybe<ProjectInvitationNodeConnection>;
  myPlannedHours?: Maybe<ProjectInvitationPlannedHoursNodeConnection>;
  myProjectPlan?: Maybe<Array<Maybe<ProjectPlanNode>>>;
  myProjects?: Maybe<ProjectNodeConnection>;
  myTimelogStats?: Maybe<TimelogStatsNode>;
  myTimelogs?: Maybe<TimelogNodeConnection>;
  myUser?: Maybe<UserNode>;
  myWorker?: Maybe<MyWorkerNode>;
  myWorkerLanguages?: Maybe<WorkerLanguageNodeConnection>;
  opportunities?: Maybe<CrmOpportunityNodeConnection>;
  position?: Maybe<PositionNode>;
  project?: Maybe<ProjectNode>;
  projectContract?: Maybe<ProjectClientContractNode>;
  projectRole?: Maybe<ProjectRoleNode>;
  projectStage?: Maybe<ProjectStageNode>;
  projects?: Maybe<Array<ProjectsCache>>;
  publicHoliday?: Maybe<PublicHolidayNode>;
  template?: Maybe<TemplateNode>;
  timelog?: Maybe<TimelogNode>;
  tool?: Maybe<ToolNode>;
  user?: Maybe<UserNode>;
  worker?: Maybe<WorkerProfileNode>;
  workerContract?: Maybe<ProjectWorkerContractNode>;
  workerLanguage?: Maybe<WorkerLanguageNode>;
  workerNote?: Maybe<WorkerNoteNode>;
  workerSkillEstimation?: Maybe<WorkerSkillEstimationNode>;
  workerSkillEstimationEntry?: Maybe<WorkerSkillEstimationEntryNode>;
  workerTool?: Maybe<WorkerToolNode>;
};


export type QueryAllClientsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  nameShort?: Maybe<Scalars['String']>;
  nameShort_Iexact?: Maybe<Scalars['String']>;
  nameShort_Icontains?: Maybe<Scalars['String']>;
  nameShort_Istartswith?: Maybe<Scalars['String']>;
  nameShort_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameShort_Isnull?: Maybe<Scalars['Boolean']>;
  projects_Id?: Maybe<Scalars['Float']>;
  projects_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAllClustersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};


export type QueryAllDepartmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};


export type QueryAllGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  user_Id?: Maybe<Scalars['Float']>;
  user_Id_Iexact?: Maybe<Scalars['Float']>;
  user_Id_Icontains?: Maybe<Scalars['Float']>;
  user_Id_Istartswith?: Maybe<Scalars['Float']>;
  user_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
};


export type QueryAllInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  worker_Isnull?: Maybe<Scalars['Boolean']>;
  worker_Department_Id?: Maybe<Scalars['Float']>;
  worker_Department_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_Department_Id_Isnull?: Maybe<Scalars['Boolean']>;
  worker_User_Position_Id?: Maybe<Scalars['Float']>;
  worker_User_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_User_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  project_CostCenter_Internal?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};


export type QueryAllInvoicesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter?: Maybe<Scalars['ID']>;
  costCenter_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  costCenter_Isnull?: Maybe<Scalars['Boolean']>;
  clientContract?: Maybe<Scalars['ID']>;
  clientContract_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientContract_Isnull?: Maybe<Scalars['Boolean']>;
  recipientCt?: Maybe<Scalars['ID']>;
  recipientCt_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  recipientCt_Isnull?: Maybe<Scalars['Boolean']>;
  recipientId?: Maybe<Scalars['Int']>;
  recipientId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  recipientId_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  paidDate?: Maybe<Scalars['Date']>;
  paidDate_Gt?: Maybe<Scalars['Date']>;
  paidDate_Gte?: Maybe<Scalars['Date']>;
  paidDate_Lt?: Maybe<Scalars['Date']>;
  paidDate_Lte?: Maybe<Scalars['Date']>;
  billingDate?: Maybe<Scalars['Date']>;
  billingDate_Gt?: Maybe<Scalars['Date']>;
  billingDate_Gte?: Maybe<Scalars['Date']>;
  billingDate_Lt?: Maybe<Scalars['Date']>;
  billingDate_Lte?: Maybe<Scalars['Date']>;
  dueDate?: Maybe<Scalars['Date']>;
  dueDate_Gt?: Maybe<Scalars['Date']>;
  dueDate_Gte?: Maybe<Scalars['Date']>;
  dueDate_Lt?: Maybe<Scalars['Date']>;
  dueDate_Lte?: Maybe<Scalars['Date']>;
  category?: Maybe<Scalars['String']>;
  category_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['Float']>;
  tax_Gt?: Maybe<Scalars['Float']>;
  tax_Gte?: Maybe<Scalars['Float']>;
  tax_Lt?: Maybe<Scalars['Float']>;
  tax_Lte?: Maybe<Scalars['Float']>;
  includePlanned?: Maybe<Scalars['Boolean']>;
  includeRejected?: Maybe<Scalars['Boolean']>;
};


export type QueryAllJiraIssuesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  summary?: Maybe<Scalars['String']>;
  summary_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  summary_Isnull?: Maybe<Scalars['Boolean']>;
  summary_Icontains?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  type_Icontains?: Maybe<Scalars['String']>;
  jiraKey?: Maybe<Scalars['String']>;
  jiraKey_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  jiraKey_Isnull?: Maybe<Scalars['Boolean']>;
  jiraKey_Icontains?: Maybe<Scalars['String']>;
  jiraProject_Projects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  jiraProject_Projects_In?: Maybe<Array<Maybe<Array<Maybe<Scalars['ID']>>>>>;
  jiraProject_Projects_Isnull?: Maybe<Scalars['Boolean']>;
  jiraProject_Projects_Id?: Maybe<Scalars['Float']>;
  jiraProject_Projects_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  jiraProject_Projects_Id_Isnull?: Maybe<Scalars['Boolean']>;
  jiraProject_JiraKey?: Maybe<Scalars['String']>;
  jiraProject_JiraKey_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  jiraProject_JiraKey_Icontains?: Maybe<Scalars['String']>;
  myProjects?: Maybe<Scalars['Boolean']>;
  issueSearch?: Maybe<Scalars['String']>;
};


export type QueryAllPlannedHoursArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  invitation_Worker?: Maybe<Scalars['ID']>;
  invitation_Worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  invitation_Worker_Isnull?: Maybe<Scalars['Boolean']>;
  weeklyHours?: Maybe<Scalars['Int']>;
  weeklyHours_Iexact?: Maybe<Scalars['Int']>;
  weeklyHours_Lte?: Maybe<Scalars['Int']>;
  weeklyHours_Gte?: Maybe<Scalars['Int']>;
  weeklyHours_Gt?: Maybe<Scalars['Int']>;
  weeklyHours_Lt?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Iexact?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
};


export type QueryAllPositionsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};


export type QueryAllProjectContractArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_Iexact?: Maybe<Scalars['String']>;
  type_Icontains?: Maybe<Scalars['String']>;
  type_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  billing?: Maybe<Scalars['String']>;
  billing_Iexact?: Maybe<Scalars['String']>;
  billing_Icontains?: Maybe<Scalars['String']>;
  billing_Istartswith?: Maybe<Scalars['String']>;
  billing_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  billing_Isnull?: Maybe<Scalars['Boolean']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  hourlyRate_Iexact?: Maybe<Scalars['Float']>;
  hourlyRate_Icontains?: Maybe<Scalars['Float']>;
  hourlyRate_Istartswith?: Maybe<Scalars['Float']>;
  hourlyRate_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  hourlyRate_Isnull?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
};


export type QueryAllProjectRolesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_Iexact?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  adminComment?: Maybe<Scalars['String']>;
  adminComment_Iexact?: Maybe<Scalars['String']>;
  adminComment_Icontains?: Maybe<Scalars['String']>;
  adminComment_Istartswith?: Maybe<Scalars['String']>;
  adminComment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminComment_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Iexact?: Maybe<Scalars['Date']>;
  startDate_Icontains?: Maybe<Scalars['Date']>;
  startDate_Istartswith?: Maybe<Scalars['Date']>;
  startDate_In?: Maybe<Array<Maybe<Scalars['Date']>>>;
  startDate_Isnull?: Maybe<Scalars['Boolean']>;
  budget?: Maybe<Scalars['Float']>;
  budget_Iexact?: Maybe<Scalars['Float']>;
  budget_Icontains?: Maybe<Scalars['Float']>;
  budget_Istartswith?: Maybe<Scalars['Float']>;
  budget_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  budget_Isnull?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_Icontains?: Maybe<Scalars['Boolean']>;
  isStaffed?: Maybe<Scalars['Boolean']>;
  isStaffed_Icontains?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  cluster?: Maybe<Scalars['ID']>;
  tools?: Maybe<Scalars['ID']>;
  onlyPublic?: Maybe<Scalars['Boolean']>;
};


export type QueryAllProjectStagesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type QueryAllProjectsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  predecessor?: Maybe<Scalars['ID']>;
  predecessor_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  predecessor_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<Scalars['ID']>;
  parent_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  nameInternal?: Maybe<Scalars['String']>;
  nameInternal_Iexact?: Maybe<Scalars['String']>;
  nameInternal_Icontains?: Maybe<Scalars['String']>;
  nameInternal_Istartswith?: Maybe<Scalars['String']>;
  nameInternal_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameInternal_Isnull?: Maybe<Scalars['Boolean']>;
  nameShort?: Maybe<Scalars['String']>;
  nameShort_Iexact?: Maybe<Scalars['String']>;
  nameShort_Icontains?: Maybe<Scalars['String']>;
  nameShort_Istartswith?: Maybe<Scalars['String']>;
  nameShort_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameShort_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_Id?: Maybe<Scalars['Float']>;
  costCenter_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_Id_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_PublicId?: Maybe<Scalars['String']>;
  costCenter_PublicId_Iexact?: Maybe<Scalars['String']>;
  costCenter_PublicId_Icontains?: Maybe<Scalars['String']>;
  costCenter_PublicId_Istartswith?: Maybe<Scalars['String']>;
  costCenter_PublicId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_PublicId_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<Scalars['String']>;
  projectType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  clientProfile_Id?: Maybe<Scalars['Float']>;
  clientProfile_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientProfile_Id_Isnull?: Maybe<Scalars['Boolean']>;
  clientContract_Id?: Maybe<Scalars['Float']>;
  clientContract_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientContract_Id_Isnull?: Maybe<Scalars['Boolean']>;
  documentation_Id?: Maybe<Scalars['Float']>;
  documentation_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentation_Id_Isnull?: Maybe<Scalars['Boolean']>;
  responsible?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  mine?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  trackable?: Maybe<Scalars['Boolean']>;
  me?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Scalars['ID']>;
  invitations?: Maybe<Scalars['ID']>;
};


export type QueryAllPublicHolidaysArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  location_Iexact?: Maybe<Scalars['String']>;
  location_Icontains?: Maybe<Scalars['String']>;
  location_Istartswith?: Maybe<Scalars['String']>;
  location_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  location_Isnull?: Maybe<Scalars['Boolean']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
};


export type QueryAllReferralsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  me?: Maybe<Scalars['Boolean']>;
};


export type QueryAllTemplatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_Iexact?: Maybe<Scalars['String']>;
  slug_Icontains?: Maybe<Scalars['String']>;
  slug_Istartswith?: Maybe<Scalars['String']>;
  slug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_Iexact?: Maybe<Scalars['String']>;
  type_Icontains?: Maybe<Scalars['String']>;
  type_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  content_Iexact?: Maybe<Scalars['String']>;
  content_Icontains?: Maybe<Scalars['String']>;
  content_Istartswith?: Maybe<Scalars['String']>;
  content_Isnull?: Maybe<Scalars['Boolean']>;
};


export type QueryAllTimelogTagsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Icontains?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_Iexact?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryAllTimelogsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  comment_Iexact?: Maybe<Scalars['String']>;
  comment_Icontains?: Maybe<Scalars['String']>;
  comment_Istartswith?: Maybe<Scalars['String']>;
  comment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  isPayrollRelevant?: Maybe<Scalars['Boolean']>;
  date_Gt?: Maybe<Scalars['Date']>;
  date_Gte?: Maybe<Scalars['Date']>;
  date_Lt?: Maybe<Scalars['Date']>;
  date_Lte?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['Date']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  paidDate_Gt?: Maybe<Scalars['DateTime']>;
  paidDate_Gte?: Maybe<Scalars['DateTime']>;
  paidDate_Lt?: Maybe<Scalars['DateTime']>;
  paidDate_Lte?: Maybe<Scalars['DateTime']>;
  timelogType?: Maybe<Scalars['String']>;
  timelogType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  project?: Maybe<Scalars['ID']>;
  worker?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};


export type QueryAllToolsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type QueryAllUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_Iexact?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  email_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_Isnull?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Iexact?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  firstName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName_Isnull?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Iexact?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  lastName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  workerProfile?: Maybe<Scalars['ID']>;
  workerProfile_Isnull?: Maybe<Scalars['Boolean']>;
  groups_Permissions_Codename?: Maybe<Scalars['String']>;
  position_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['ID']>;
  groups?: Maybe<Scalars['ID']>;
};


export type QueryAllWorkerContractsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_Iexact?: Maybe<Scalars['String']>;
  type_Icontains?: Maybe<Scalars['String']>;
  type_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Scalars['Float']>;
  payment_Iexact?: Maybe<Scalars['Float']>;
  payment_Icontains?: Maybe<Scalars['Float']>;
  payment_Istartswith?: Maybe<Scalars['Float']>;
  payment_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  payment_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  worker?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['ID']>;
  isCore?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryAllWorkerLanguagesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAllWorkerNotesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker_Id?: Maybe<Scalars['Float']>;
  worker_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_Id_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_Iexact?: Maybe<Scalars['String']>;
  type_Icontains?: Maybe<Scalars['String']>;
  type_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  author?: Maybe<Scalars['ID']>;
  author_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  author_Isnull?: Maybe<Scalars['Boolean']>;
};


export type QueryAllWorkerSkillEstimationEntriesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['ID']>;
  source_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  tool?: Maybe<Scalars['ID']>;
  tool_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tool_Isnull?: Maybe<Scalars['Boolean']>;
  estimation?: Maybe<Scalars['String']>;
  estimation_Iexact?: Maybe<Scalars['String']>;
  estimation_Icontains?: Maybe<Scalars['String']>;
  estimation_Istartswith?: Maybe<Scalars['String']>;
  estimation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  estimation_Isnull?: Maybe<Scalars['Boolean']>;
  evaluation?: Maybe<Scalars['String']>;
};


export type QueryAllWorkerSkillEstimationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  worker_Isnull?: Maybe<Scalars['Boolean']>;
  worker_Invitations_Project?: Maybe<Scalars['ID']>;
  worker_Invitations_Project_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  worker_Invitations_Project_Isnull?: Maybe<Scalars['Boolean']>;
  reporter?: Maybe<Scalars['ID']>;
  reporter_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  reporter_Isnull?: Maybe<Scalars['Boolean']>;
  isSelf?: Maybe<Scalars['Boolean']>;
  isSelf_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isSelf_Isnull?: Maybe<Scalars['Boolean']>;
  entries?: Maybe<Scalars['ID']>;
};


export type QueryAllWorkerToolsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Name?: Maybe<Scalars['String']>;
  tool_Name_Iexact?: Maybe<Scalars['String']>;
  tool_Name_Icontains?: Maybe<Scalars['String']>;
  tool_Name_Istartswith?: Maybe<Scalars['String']>;
  tool_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Id?: Maybe<Scalars['Float']>;
  tool_Id_Iexact?: Maybe<Scalars['Float']>;
  tool_Id_Icontains?: Maybe<Scalars['Float']>;
  tool_Id_Istartswith?: Maybe<Scalars['Float']>;
  tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryAllWorkersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  user_DateJoined_Gt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Gte?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lt?: Maybe<Scalars['DateTime']>;
  user_DateJoined_Lte?: Maybe<Scalars['DateTime']>;
  user_Position_Id?: Maybe<Scalars['Float']>;
  user_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  lastStateChange_Gt?: Maybe<Scalars['Date']>;
  lastStateChange_Gte?: Maybe<Scalars['Date']>;
  lastStateChange_Lt?: Maybe<Scalars['Date']>;
  lastStateChange_Lte?: Maybe<Scalars['Date']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  country_Iexact?: Maybe<Scalars['String']>;
  country_Icontains?: Maybe<Scalars['String']>;
  country_Istartswith?: Maybe<Scalars['String']>;
  country_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<Scalars['String']>;
  nationality_Iexact?: Maybe<Scalars['String']>;
  nationality_Icontains?: Maybe<Scalars['String']>;
  nationality_Istartswith?: Maybe<Scalars['String']>;
  nationality_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nationality_Isnull?: Maybe<Scalars['Boolean']>;
  workLocation?: Maybe<Scalars['String']>;
  workLocation_Iexact?: Maybe<Scalars['String']>;
  workLocation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  workLocation_Isnull?: Maybe<Scalars['Boolean']>;
  invitations_Project_Id?: Maybe<Scalars['Float']>;
  invitations_Project_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  invitations_Project_Id_Isnull?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  fullName_Iexact?: Maybe<Scalars['String']>;
  fullName_Icontains?: Maybe<Scalars['String']>;
  fullName_Istartswith?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  userType_Iexact?: Maybe<Scalars['String']>;
  userType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  userType_Isnull?: Maybe<Scalars['Boolean']>;
  tools_Tool_Id?: Maybe<Scalars['Float']>;
  tools_Tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  showArchived?: Maybe<Scalars['Boolean']>;
  appliedForSpecific?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  clusters?: Maybe<Scalars['ID']>;
  tags?: Maybe<Scalars['ID']>;
  department?: Maybe<Scalars['ID']>;
  skillEstimation?: Maybe<Scalars['ID']>;
  activeContract?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isCore?: Maybe<Scalars['Boolean']>;
  dashSearch?: Maybe<Scalars['String']>;
};


export type QueryClientArgs = {
  id: Scalars['ID'];
};


export type QueryClusterArgs = {
  id: Scalars['ID'];
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryInvitationArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryJiraIssueArgs = {
  id: Scalars['ID'];
};


export type QueryMyContractsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_Iexact?: Maybe<Scalars['String']>;
  type_Icontains?: Maybe<Scalars['String']>;
  type_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<Scalars['Float']>;
  payment_Iexact?: Maybe<Scalars['Float']>;
  payment_Icontains?: Maybe<Scalars['Float']>;
  payment_Istartswith?: Maybe<Scalars['Float']>;
  payment_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  payment_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  worker?: Maybe<Scalars['ID']>;
  position?: Maybe<Scalars['ID']>;
  isCore?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryMyInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  worker_Isnull?: Maybe<Scalars['Boolean']>;
  worker_Department_Id?: Maybe<Scalars['Float']>;
  worker_Department_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_Department_Id_Isnull?: Maybe<Scalars['Boolean']>;
  worker_User_Position_Id?: Maybe<Scalars['Float']>;
  worker_User_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_User_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  project_CostCenter_Internal?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};


export type QueryMyPlannedHoursArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  invitation_Worker?: Maybe<Scalars['ID']>;
  invitation_Worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  invitation_Worker_Isnull?: Maybe<Scalars['Boolean']>;
  weeklyHours?: Maybe<Scalars['Int']>;
  weeklyHours_Iexact?: Maybe<Scalars['Int']>;
  weeklyHours_Lte?: Maybe<Scalars['Int']>;
  weeklyHours_Gte?: Maybe<Scalars['Int']>;
  weeklyHours_Gt?: Maybe<Scalars['Int']>;
  weeklyHours_Lt?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Iexact?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
};


export type QueryMyProjectPlanArgs = {
  startDate: Scalars['Date'];
};


export type QueryMyProjectsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  predecessor?: Maybe<Scalars['ID']>;
  predecessor_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  predecessor_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<Scalars['ID']>;
  parent_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  nameInternal?: Maybe<Scalars['String']>;
  nameInternal_Iexact?: Maybe<Scalars['String']>;
  nameInternal_Icontains?: Maybe<Scalars['String']>;
  nameInternal_Istartswith?: Maybe<Scalars['String']>;
  nameInternal_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameInternal_Isnull?: Maybe<Scalars['Boolean']>;
  nameShort?: Maybe<Scalars['String']>;
  nameShort_Iexact?: Maybe<Scalars['String']>;
  nameShort_Icontains?: Maybe<Scalars['String']>;
  nameShort_Istartswith?: Maybe<Scalars['String']>;
  nameShort_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameShort_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_Id?: Maybe<Scalars['Float']>;
  costCenter_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_Id_Isnull?: Maybe<Scalars['Boolean']>;
  costCenter_PublicId?: Maybe<Scalars['String']>;
  costCenter_PublicId_Iexact?: Maybe<Scalars['String']>;
  costCenter_PublicId_Icontains?: Maybe<Scalars['String']>;
  costCenter_PublicId_Istartswith?: Maybe<Scalars['String']>;
  costCenter_PublicId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  costCenter_PublicId_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  projectType?: Maybe<Scalars['String']>;
  projectType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Gt?: Maybe<Scalars['Date']>;
  startDate_Gte?: Maybe<Scalars['Date']>;
  startDate_Lt?: Maybe<Scalars['Date']>;
  startDate_Lte?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  endDate_Gt?: Maybe<Scalars['Date']>;
  endDate_Gte?: Maybe<Scalars['Date']>;
  endDate_Lt?: Maybe<Scalars['Date']>;
  endDate_Lte?: Maybe<Scalars['Date']>;
  clientProfile_Id?: Maybe<Scalars['Float']>;
  clientProfile_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientProfile_Id_Isnull?: Maybe<Scalars['Boolean']>;
  clientContract_Id?: Maybe<Scalars['Float']>;
  clientContract_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientContract_Id_Isnull?: Maybe<Scalars['Boolean']>;
  documentation_Id?: Maybe<Scalars['Float']>;
  documentation_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentation_Id_Isnull?: Maybe<Scalars['Boolean']>;
  responsible?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  mine?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  trackable?: Maybe<Scalars['Boolean']>;
  me?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Scalars['ID']>;
  invitations?: Maybe<Scalars['ID']>;
};


export type QueryMyTimelogStatsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryMyTimelogsArgs = {
  project_Id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tags_Id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  comment_Iexact?: Maybe<Scalars['String']>;
  comment_Icontains?: Maybe<Scalars['String']>;
  comment_Istartswith?: Maybe<Scalars['String']>;
  comment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  isPayrollRelevant?: Maybe<Scalars['Boolean']>;
  date_Gt?: Maybe<Scalars['Date']>;
  date_Gte?: Maybe<Scalars['Date']>;
  date_Lt?: Maybe<Scalars['Date']>;
  date_Lte?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['Date']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  paidDate_Gt?: Maybe<Scalars['DateTime']>;
  paidDate_Gte?: Maybe<Scalars['DateTime']>;
  paidDate_Lt?: Maybe<Scalars['DateTime']>;
  paidDate_Lte?: Maybe<Scalars['DateTime']>;
  timelogType?: Maybe<Scalars['String']>;
  timelogType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  project?: Maybe<Scalars['ID']>;
  worker?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};


export type QueryMyWorkerLanguagesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryOpportunitiesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  accountName?: Maybe<Scalars['String']>;
  accountName_Icontains?: Maybe<Scalars['String']>;
  dealName?: Maybe<Scalars['String']>;
  dealName_Icontains?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  stage_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  stage_Isnull?: Maybe<Scalars['Boolean']>;
  stage_Icontains?: Maybe<Scalars['String']>;
};


export type QueryPositionArgs = {
  id: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectContractArgs = {
  id: Scalars['ID'];
};


export type QueryProjectRoleArgs = {
  id: Scalars['ID'];
};


export type QueryProjectStageArgs = {
  id: Scalars['ID'];
};


export type QueryPublicHolidayArgs = {
  id: Scalars['ID'];
};


export type QueryTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryTimelogArgs = {
  id: Scalars['ID'];
};


export type QueryToolArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryWorkerArgs = {
  id: Scalars['ID'];
};


export type QueryWorkerContractArgs = {
  id: Scalars['ID'];
};


export type QueryWorkerLanguageArgs = {
  id: Scalars['ID'];
};


export type QueryWorkerNoteArgs = {
  id: Scalars['ID'];
};


export type QueryWorkerSkillEstimationArgs = {
  id: Scalars['ID'];
};


export type QueryWorkerSkillEstimationEntryArgs = {
  id: Scalars['ID'];
};


export type QueryWorkerToolArgs = {
  id: Scalars['ID'];
};

export type SetWorkerClusters = {
  __typename?: 'SetWorkerClusters';
  clusters?: Maybe<Array<Maybe<ClusterNode>>>;
};

export enum TemplateBaseTemplateTypeChoices {
  TemplateBasetemplate = 'TEMPLATE_BASETEMPLATE',
  TemplateInvitationdetailtemplate = 'TEMPLATE_INVITATIONDETAILTEMPLATE',
  TemplateWorkerdetailtemplate = 'TEMPLATE_WORKERDETAILTEMPLATE',
  TemplateWorkermailtotemplate = 'TEMPLATE_WORKERMAILTOTEMPLATE',
  TemplateTechnicalinterviewtemplate = 'TEMPLATE_TECHNICALINTERVIEWTEMPLATE',
  TemplateExportworkertemplate = 'TEMPLATE_EXPORTWORKERTEMPLATE',
  TemplateContracttemplate = 'TEMPLATE_CONTRACTTEMPLATE',
  TemplateContractchecklisttemplate = 'TEMPLATE_CONTRACTCHECKLISTTEMPLATE',
  TemplateWorkernotetemplate = 'TEMPLATE_WORKERNOTETEMPLATE',
  TemplateProjectdescriptiontemplate = 'TEMPLATE_PROJECTDESCRIPTIONTEMPLATE',
  TemplateLegaltemplate = 'TEMPLATE_LEGALTEMPLATE'
}

export type TemplateNode = Node & {
  __typename?: 'TemplateNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  type?: Maybe<TemplateBaseTemplateTypeChoices>;
  data?: Maybe<Scalars['GenericScalar']>;
};

export type TemplateNodeConnection = {
  __typename?: 'TemplateNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TemplateNodeEdge>>;
};

export type TemplateNodeEdge = {
  __typename?: 'TemplateNodeEdge';
  node?: Maybe<TemplateNode>;
  cursor: Scalars['String'];
};

export type TimelogNode = CustomNode & {
  __typename?: 'TimelogNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  date: Scalars['Date'];
  comment?: Maybe<Scalars['String']>;
  formattedComment?: Maybe<Scalars['String']>;
  durationSeconds: Scalars['Int'];
  state: TimelogTimelogStateChoices;
  worker: MyWorkerNode;
  project?: Maybe<ProjectNode>;
  crmOpportunityId?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  properties?: Maybe<Scalars['GenericScalar']>;
  tags: TimelogTagNodeConnection;
  jiraIssues: JiraIssueNodeConnection;
  timelogType: TimelogTimelogTimelogTypeChoices;
  personioId?: Maybe<Scalars['Int']>;
  isPayrollRelevant: Scalars['Boolean'];
  appliedOn?: Maybe<Scalars['DateTime']>;
  stateLabel?: Maybe<Scalars['String']>;
  timelogTypeLabel?: Maybe<Scalars['String']>;
};


export type TimelogNodeTagsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Icontains?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_Iexact?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TimelogNodeJiraIssuesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  summary?: Maybe<Scalars['String']>;
  summary_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  summary_Isnull?: Maybe<Scalars['Boolean']>;
  summary_Icontains?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_Isnull?: Maybe<Scalars['Boolean']>;
  type_Icontains?: Maybe<Scalars['String']>;
  jiraKey?: Maybe<Scalars['String']>;
  jiraKey_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  jiraKey_Isnull?: Maybe<Scalars['Boolean']>;
  jiraKey_Icontains?: Maybe<Scalars['String']>;
  jiraProject_Projects?: Maybe<Array<Maybe<Scalars['ID']>>>;
  jiraProject_Projects_In?: Maybe<Array<Maybe<Array<Maybe<Scalars['ID']>>>>>;
  jiraProject_Projects_Isnull?: Maybe<Scalars['Boolean']>;
  jiraProject_Projects_Id?: Maybe<Scalars['Float']>;
  jiraProject_Projects_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  jiraProject_Projects_Id_Isnull?: Maybe<Scalars['Boolean']>;
  jiraProject_JiraKey?: Maybe<Scalars['String']>;
  jiraProject_JiraKey_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  jiraProject_JiraKey_Icontains?: Maybe<Scalars['String']>;
  myProjects?: Maybe<Scalars['Boolean']>;
  issueSearch?: Maybe<Scalars['String']>;
};

export type TimelogNodeConnection = {
  __typename?: 'TimelogNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TimelogNodeEdge>>;
};

export type TimelogNodeEdge = {
  __typename?: 'TimelogNodeEdge';
  node?: Maybe<TimelogNode>;
  cursor: Scalars['String'];
};

export type TimelogStatsNode = {
  __typename?: 'TimelogStatsNode';
  targetUtilization?: Maybe<Scalars['Float']>;
  weeklyBillableHoursTarget?: Maybe<Scalars['Float']>;
  currentWeeklyBillableHours?: Maybe<Scalars['Float']>;
  capaRatio?: Maybe<Scalars['Float']>;
  loggedSeconds?: Maybe<Scalars['Int']>;
  averageWeeklySeconds?: Maybe<Scalars['Float']>;
  trackedAgainstPlanned?: Maybe<Scalars['Float']>;
  projects?: Maybe<Array<Maybe<ProjectStats>>>;
};

export type TimelogTagNode = CustomNode & {
  __typename?: 'TimelogTagNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  timelogs: TimelogNodeConnection;
};


export type TimelogTagNodeTimelogsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  comment_Iexact?: Maybe<Scalars['String']>;
  comment_Icontains?: Maybe<Scalars['String']>;
  comment_Istartswith?: Maybe<Scalars['String']>;
  comment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  isPayrollRelevant?: Maybe<Scalars['Boolean']>;
  date_Gt?: Maybe<Scalars['Date']>;
  date_Gte?: Maybe<Scalars['Date']>;
  date_Lt?: Maybe<Scalars['Date']>;
  date_Lte?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['Date']>;
  paidDate?: Maybe<Scalars['DateTime']>;
  paidDate_Gt?: Maybe<Scalars['DateTime']>;
  paidDate_Gte?: Maybe<Scalars['DateTime']>;
  paidDate_Lt?: Maybe<Scalars['DateTime']>;
  paidDate_Lte?: Maybe<Scalars['DateTime']>;
  timelogType?: Maybe<Scalars['String']>;
  timelogType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  project?: Maybe<Scalars['ID']>;
  worker?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};

export type TimelogTagNodeConnection = {
  __typename?: 'TimelogTagNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TimelogTagNodeEdge>>;
};

export type TimelogTagNodeEdge = {
  __typename?: 'TimelogTagNodeEdge';
  node?: Maybe<TimelogTagNode>;
  cursor: Scalars['String'];
};

export enum TimelogTimelogStateChoices {
  A_3 = 'A_3',
  A_5 = 'A_5',
  A_9 = 'A_9'
}

export enum TimelogTimelogTimelogTypeChoices {
  Timelog = 'TIMELOG',
  Vacation = 'VACATION',
  Holiday = 'HOLIDAY',
  Sickness = 'SICKNESS',
  Leave = 'LEAVE'
}

export type ToolNode = CustomNode & {
  __typename?: 'ToolNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  keywordRegex: Scalars['String'];
  source: ToolToolSourceChoices;
  defaultEvaluated: Scalars['Boolean'];
  clusters: ClusterNodeConnection;
  workerTools: WorkerToolNodeConnection;
  workerEstimation: WorkerSkillEstimationEntryNodeConnection;
  roles: ProjectRoleNodeConnection;
};


export type ToolNodeClustersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};


export type ToolNodeWorkerToolsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Name?: Maybe<Scalars['String']>;
  tool_Name_Iexact?: Maybe<Scalars['String']>;
  tool_Name_Icontains?: Maybe<Scalars['String']>;
  tool_Name_Istartswith?: Maybe<Scalars['String']>;
  tool_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Id?: Maybe<Scalars['Float']>;
  tool_Id_Iexact?: Maybe<Scalars['Float']>;
  tool_Id_Icontains?: Maybe<Scalars['Float']>;
  tool_Id_Istartswith?: Maybe<Scalars['Float']>;
  tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type ToolNodeWorkerEstimationArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['ID']>;
  source_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  tool?: Maybe<Scalars['ID']>;
  tool_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tool_Isnull?: Maybe<Scalars['Boolean']>;
  estimation?: Maybe<Scalars['String']>;
  estimation_Iexact?: Maybe<Scalars['String']>;
  estimation_Icontains?: Maybe<Scalars['String']>;
  estimation_Istartswith?: Maybe<Scalars['String']>;
  estimation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  estimation_Isnull?: Maybe<Scalars['Boolean']>;
  evaluation?: Maybe<Scalars['String']>;
};


export type ToolNodeRolesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_Iexact?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  adminComment?: Maybe<Scalars['String']>;
  adminComment_Iexact?: Maybe<Scalars['String']>;
  adminComment_Icontains?: Maybe<Scalars['String']>;
  adminComment_Istartswith?: Maybe<Scalars['String']>;
  adminComment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  adminComment_Isnull?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
  startDate_Iexact?: Maybe<Scalars['Date']>;
  startDate_Icontains?: Maybe<Scalars['Date']>;
  startDate_Istartswith?: Maybe<Scalars['Date']>;
  startDate_In?: Maybe<Array<Maybe<Scalars['Date']>>>;
  startDate_Isnull?: Maybe<Scalars['Boolean']>;
  budget?: Maybe<Scalars['Float']>;
  budget_Iexact?: Maybe<Scalars['Float']>;
  budget_Icontains?: Maybe<Scalars['Float']>;
  budget_Istartswith?: Maybe<Scalars['Float']>;
  budget_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  budget_Isnull?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_Icontains?: Maybe<Scalars['Boolean']>;
  isStaffed?: Maybe<Scalars['Boolean']>;
  isStaffed_Icontains?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  cluster?: Maybe<Scalars['ID']>;
  tools?: Maybe<Scalars['ID']>;
  onlyPublic?: Maybe<Scalars['Boolean']>;
};

export type ToolNodeConnection = {
  __typename?: 'ToolNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ToolNodeEdge>>;
};

export type ToolNodeEdge = {
  __typename?: 'ToolNodeEdge';
  node?: Maybe<ToolNode>;
  cursor: Scalars['String'];
};

export enum ToolToolSourceChoices {
  A_1 = 'A_1',
  A_2 = 'A_2',
  A_3 = 'A_3',
  A_4 = 'A_4'
}

export enum ToolWorkerToolStatusChoices {
  New = 'NEW',
  ConfirmedInterview = 'CONFIRMED_INTERVIEW',
  ConfirmedProject = 'CONFIRMED_PROJECT'
}

export type UpdateProjectColor = {
  __typename?: 'UpdateProjectColor';
  invitation?: Maybe<ProjectInvitationNode>;
};

export type UpdateTimelog = {
  __typename?: 'UpdateTimelog';
  timelog?: Maybe<TimelogNode>;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  user?: Maybe<UserNode>;
};

export type UpdateWorker = {
  __typename?: 'UpdateWorker';
  worker?: Maybe<WorkerProfileNode>;
};

export type UpdateWorkerLanguage = {
  __typename?: 'UpdateWorkerLanguage';
  workerLanguage?: Maybe<WorkerLanguageNode>;
};

export type UpdateWorkerTool = {
  __typename?: 'UpdateWorkerTool';
  workerTool?: Maybe<WorkerToolNode>;
};

export type UserNode = CustomNode & {
  __typename?: 'UserNode';
  id: Scalars['ID'];
  isSuperuser: Scalars['Boolean'];
  username: Scalars['String'];
  email: Scalars['String'];
  motiusEmail?: Maybe<Scalars['String']>;
  position?: Maybe<PositionNode>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  displayName: Scalars['String'];
  dateJoined: Scalars['DateTime'];
  isStaff: Scalars['Boolean'];
  workerProfile?: Maybe<MyWorkerNode>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<UserNodeEdge>>;
};

export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  node?: Maybe<UserNode>;
  cursor: Scalars['String'];
};

export enum VacationPublicHolidayLocationChoices {
  Munich = 'MUNICH',
  Stuttgart = 'STUTTGART',
  Berlin = 'BERLIN',
  Dubai = 'DUBAI',
  Serbia = 'SERBIA',
  India = 'INDIA',
  Remote = 'REMOTE'
}

export type WorkerLanguageNode = CustomNode & {
  __typename?: 'WorkerLanguageNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  worker: MyWorkerNode;
  language: WorkerWorkerLanguageLanguageChoices;
  proficiency?: Maybe<Scalars['String']>;
};

export type WorkerLanguageNodeConnection = {
  __typename?: 'WorkerLanguageNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WorkerLanguageNodeEdge>>;
};

export type WorkerLanguageNodeEdge = {
  __typename?: 'WorkerLanguageNodeEdge';
  node?: Maybe<WorkerLanguageNode>;
  cursor: Scalars['String'];
};

export type WorkerNoteNode = CustomNode & {
  __typename?: 'WorkerNoteNode';
  id: Scalars['ID'];
  worker: MyWorkerNode;
  project?: Maybe<ProjectNode>;
  type: WorkerWorkerNoteTypeChoices;
  created: Scalars['Date'];
  author?: Maybe<UserNode>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
};

export type WorkerNoteNodeConnection = {
  __typename?: 'WorkerNoteNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WorkerNoteNodeEdge>>;
};

export type WorkerNoteNodeEdge = {
  __typename?: 'WorkerNoteNodeEdge';
  node?: Maybe<WorkerNoteNode>;
  cursor: Scalars['String'];
};

export type WorkerProfileNode = CustomNode & {
  __typename?: 'WorkerProfileNode';
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
  fullName: Scalars['String'];
  state: WorkerWorkerProfileStateChoices;
  picture?: Maybe<FileNode>;
  department?: Maybe<DepartmentNode>;
  clusters: ClusterNodeConnection;
  workLocation: WorkerWorkerProfileWorkLocationChoices;
  tools: WorkerToolNodeConnection;
  languages: WorkerLanguageNodeConnection;
  invitations: ProjectInvitationNodeConnection;
  isPermanent?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  weeklyHours?: Maybe<Scalars['Int']>;
  latestRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type WorkerProfileNodeClustersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
};


export type WorkerProfileNodeToolsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Name?: Maybe<Scalars['String']>;
  tool_Name_Iexact?: Maybe<Scalars['String']>;
  tool_Name_Icontains?: Maybe<Scalars['String']>;
  tool_Name_Istartswith?: Maybe<Scalars['String']>;
  tool_Name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  tool_Id?: Maybe<Scalars['Float']>;
  tool_Id_Iexact?: Maybe<Scalars['Float']>;
  tool_Id_Icontains?: Maybe<Scalars['Float']>;
  tool_Id_Istartswith?: Maybe<Scalars['Float']>;
  tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type WorkerProfileNodeLanguagesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type WorkerProfileNodeInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  worker?: Maybe<Scalars['ID']>;
  worker_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  worker_Isnull?: Maybe<Scalars['Boolean']>;
  worker_Department_Id?: Maybe<Scalars['Float']>;
  worker_Department_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_Department_Id_Isnull?: Maybe<Scalars['Boolean']>;
  worker_User_Position_Id?: Maybe<Scalars['Float']>;
  worker_User_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  worker_User_Position_Id_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  project_CostCenter_Internal?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Scalars['ID']>;
  me?: Maybe<Scalars['Boolean']>;
};

export type WorkerProfileNodeConnection = {
  __typename?: 'WorkerProfileNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WorkerProfileNodeEdge>>;
};

export type WorkerProfileNodeEdge = {
  __typename?: 'WorkerProfileNodeEdge';
  node?: Maybe<WorkerProfileNode>;
  cursor: Scalars['String'];
};

export type WorkerReferralNode = CustomNode & {
  __typename?: 'WorkerReferralNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  referred: UserNode;
  referredBy?: Maybe<UserNode>;
  reward: Scalars['Decimal'];
  state: WorkerWorkerReferralStateChoices;
};

export type WorkerReferralNodeConnection = {
  __typename?: 'WorkerReferralNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WorkerReferralNodeEdge>>;
};

export type WorkerReferralNodeEdge = {
  __typename?: 'WorkerReferralNodeEdge';
  node?: Maybe<WorkerReferralNode>;
  cursor: Scalars['String'];
};

export type WorkerSkillEstimationEntryNode = CustomNode & {
  __typename?: 'WorkerSkillEstimationEntryNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  estimation: WorkerWorkerSkillEstimationEntryEstimationChoices;
  source: WorkerSkillEstimationNode;
  tool: ToolNode;
};

export type WorkerSkillEstimationEntryNodeConnection = {
  __typename?: 'WorkerSkillEstimationEntryNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WorkerSkillEstimationEntryNodeEdge>>;
};

export type WorkerSkillEstimationEntryNodeEdge = {
  __typename?: 'WorkerSkillEstimationEntryNodeEdge';
  node?: Maybe<WorkerSkillEstimationEntryNode>;
  cursor: Scalars['String'];
};

export type WorkerSkillEstimationNode = CustomNode & {
  __typename?: 'WorkerSkillEstimationNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  worker: MyWorkerNode;
  project?: Maybe<ProjectNode>;
  reporter: UserNode;
  isSelf: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  entries: WorkerSkillEstimationEntryNodeConnection;
};


export type WorkerSkillEstimationNodeEntriesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
  id_Iexact?: Maybe<Scalars['Float']>;
  id_Icontains?: Maybe<Scalars['Float']>;
  id_Istartswith?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['ID']>;
  source_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  tool?: Maybe<Scalars['ID']>;
  tool_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tool_Isnull?: Maybe<Scalars['Boolean']>;
  estimation?: Maybe<Scalars['String']>;
  estimation_Iexact?: Maybe<Scalars['String']>;
  estimation_Icontains?: Maybe<Scalars['String']>;
  estimation_Istartswith?: Maybe<Scalars['String']>;
  estimation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  estimation_Isnull?: Maybe<Scalars['Boolean']>;
  evaluation?: Maybe<Scalars['String']>;
};

export type WorkerSkillEstimationNodeConnection = {
  __typename?: 'WorkerSkillEstimationNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WorkerSkillEstimationNodeEdge>>;
};

export type WorkerSkillEstimationNodeEdge = {
  __typename?: 'WorkerSkillEstimationNodeEdge';
  node?: Maybe<WorkerSkillEstimationNode>;
  cursor: Scalars['String'];
};

export type WorkerToolNode = CustomNode & {
  __typename?: 'WorkerToolNode';
  id: Scalars['ID'];
  created?: Maybe<Scalars['DateTime']>;
  worker: MyWorkerNode;
  tool: ToolNode;
  rating?: Maybe<Scalars['Int']>;
  status: ToolWorkerToolStatusChoices;
  approved: Scalars['Boolean'];
};

export type WorkerToolNodeConnection = {
  __typename?: 'WorkerToolNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WorkerToolNodeEdge>>;
};

export type WorkerToolNodeEdge = {
  __typename?: 'WorkerToolNodeEdge';
  node?: Maybe<WorkerToolNode>;
  cursor: Scalars['String'];
};

export enum WorkerWorkerLanguageLanguageChoices {
  Af = 'AF',
  Ar = 'AR',
  Az = 'AZ',
  Bg = 'BG',
  Be = 'BE',
  Bn = 'BN',
  Br = 'BR',
  Bs = 'BS',
  Ca = 'CA',
  Cs = 'CS',
  Cy = 'CY',
  Da = 'DA',
  De = 'DE',
  El = 'EL',
  En = 'EN',
  EnAu = 'EN_AU',
  EnGb = 'EN_GB',
  Eo = 'EO',
  Es = 'ES',
  EsAr = 'ES_AR',
  EsMx = 'ES_MX',
  EsNi = 'ES_NI',
  EsVe = 'ES_VE',
  Et = 'ET',
  Eu = 'EU',
  Fa = 'FA',
  Fi = 'FI',
  Fr = 'FR',
  Fy = 'FY',
  Ga = 'GA',
  Gl = 'GL',
  He = 'HE',
  Hi = 'HI',
  Hr = 'HR',
  Hu = 'HU',
  Ia = 'IA',
  Id = 'ID',
  Is = 'IS',
  It = 'IT',
  Ja = 'JA',
  Ka = 'KA',
  Kk = 'KK',
  Km = 'KM',
  Kn = 'KN',
  Ko = 'KO',
  Lb = 'LB',
  Lt = 'LT',
  Lv = 'LV',
  Mk = 'MK',
  Ml = 'ML',
  Mn = 'MN',
  My = 'MY',
  Nb = 'NB',
  Ne = 'NE',
  Nl = 'NL',
  Nn = 'NN',
  Os = 'OS',
  Pa = 'PA',
  Pl = 'PL',
  Pt = 'PT',
  PtBr = 'PT_BR',
  Ro = 'RO',
  Ru = 'RU',
  Sk = 'SK',
  Sl = 'SL',
  Sq = 'SQ',
  Sr = 'SR',
  SrLatn = 'SR_LATN',
  Sv = 'SV',
  Sw = 'SW',
  Ta = 'TA',
  Te = 'TE',
  Th = 'TH',
  Tr = 'TR',
  Tt = 'TT',
  Udm = 'UDM',
  Uk = 'UK',
  Ur = 'UR',
  Vi = 'VI',
  ZhCn = 'ZH_CN',
  ZhHans = 'ZH_HANS',
  ZhHant = 'ZH_HANT',
  ZhTw = 'ZH_TW'
}

export enum WorkerWorkerNoteTypeChoices {
  Interview = 'INTERVIEW',
  TechnicalInterview = 'TECHNICAL_INTERVIEW',
  TeamEvaluation = 'TEAM_EVALUATION',
  Evaluation = 'EVALUATION',
  QualityReview = 'QUALITY_REVIEW',
  Email = 'EMAIL',
  ProjectNote = 'PROJECT_NOTE',
  Other = 'OTHER'
}

export enum WorkerWorkerProfileStateChoices {
  Registering = 'REGISTERING',
  New = 'NEW',
  Alumni = 'ALUMNI',
  MissingInformation = 'MISSING_INFORMATION',
  Invited = 'INVITED',
  Interview = 'INTERVIEW',
  Rejected = 'REJECTED',
  Accepted = 'ACCEPTED',
  Outboarded = 'OUTBOARDED',
  Pending = 'PENDING',
  Deleted = 'DELETED'
}

export enum WorkerWorkerProfileWorkLocationChoices {
  Munich = 'MUNICH',
  Stuttgart = 'STUTTGART',
  Berlin = 'BERLIN',
  Dubai = 'DUBAI',
  Serbia = 'SERBIA',
  India = 'INDIA',
  Remote = 'REMOTE'
}

export enum WorkerWorkerReferralStateChoices {
  Created = 'CREATED',
  Unpaid = 'UNPAID',
  Paid = 'PAID'
}

export enum WorkerWorkerSkillEstimationEntryEstimationChoices {
  A_0 = 'A_0',
  A_1 = 'A_1',
  A_2 = 'A_2',
  A_3 = 'A_3',
  A_4 = 'A_4',
  A_5 = 'A_5'
}

export type CreateToolAndWorkerToolMutationVariables = Exact<{
  name: Scalars['String'];
  rating: Scalars['Int'];
}>;


export type CreateToolAndWorkerToolMutation = (
  { __typename?: 'Mutation' }
  & {
    createToolAndWorkerTool?: Maybe<(
      { __typename?: 'CreateToolAndAddWorker' }
      & {
        workerTool?: Maybe<(
          { __typename?: 'WorkerToolNode' }
          & Pick<WorkerToolNode, 'id' | 'rating'>
          & {
            tool: (
              { __typename?: 'ToolNode' }
              & Pick<ToolNode, 'name'>
            )
          }
        )>
      }
    )>
  }
);

export type CreateWorkerToolMutationVariables = Exact<{
  tool: Scalars['String'];
  rating: Scalars['Int'];
}>;


export type CreateWorkerToolMutation = (
  { __typename?: 'Mutation' }
  & {
    createWorkerTool?: Maybe<(
      { __typename?: 'CreateWorkerTool' }
      & {
        workerTool?: Maybe<(
          { __typename?: 'WorkerToolNode' }
          & Pick<WorkerToolNode, 'id' | 'rating'>
          & {
            tool: (
              { __typename?: 'ToolNode' }
              & Pick<ToolNode, 'id' | 'name'>
            )
          }
        )>
      }
    )>
  }
);

export type DeleteWorkerToolMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteWorkerToolMutation = (
  { __typename?: 'Mutation' }
  & {
    deleteWorkerTool?: Maybe<(
      { __typename?: 'DeleteWorkerTool' }
      & Pick<DeleteWorkerTool, 'deleted'>
    )>
  }
);

export type GetToolsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type GetToolsQuery = (
  { __typename?: 'Query' }
  & {
    allTools?: Maybe<(
      { __typename?: 'ToolNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'ToolNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'ToolNode' }
              & Pick<ToolNode, 'id' | 'name'>
            )>
          }
        )>>
      }
    )>
  }
);

export type GetToolsByNameQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type GetToolsByNameQuery = (
  { __typename?: 'Query' }
  & {
    allTools?: Maybe<(
      { __typename?: 'ToolNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'ToolNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'ToolNode' }
              & Pick<ToolNode, 'id' | 'name'>
            )>
          }
        )>>
      }
    )>
  }
);

export type MyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProfileQuery = (
  { __typename?: 'Query' }
  & {
    myWorker?: Maybe<(
      { __typename?: 'MyWorkerNode' }
      & Pick<MyWorkerNode, 'id' | 'phone' | 'workLocation'>
      & {
        picture?: Maybe<(
          { __typename?: 'FileNode' }
          & Pick<FileNode, 'name' | 'url'>
        )>, user?: Maybe<(
          { __typename?: 'UserNode' }
          & Pick<UserNode, 'email' | 'displayName' | 'motiusEmail'>
          & {
            position?: Maybe<(
              { __typename?: 'PositionNode' }
              & Pick<PositionNode, 'name'>
            )>
          }
        )>
      }
    )>
  }
);

export type MySkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type MySkillsQuery = (
  { __typename?: 'Query' }
  & {
    myWorker?: Maybe<(
      { __typename?: 'MyWorkerNode' }
      & Pick<MyWorkerNode, 'id'>
      & {
        clusters: (
          { __typename?: 'ClusterNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'ClusterNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'ClusterNode' }
                  & Pick<ClusterNode, 'id' | 'name'>
                )>
              }
            )>>
          }
        ), tools: (
          { __typename?: 'WorkerToolNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'WorkerToolNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'WorkerToolNode' }
                  & Pick<WorkerToolNode, 'id' | 'rating'>
                  & {
                    tool: (
                      { __typename?: 'ToolNode' }
                      & Pick<ToolNode, 'name'>
                    )
                  }
                )>
              }
            )>>
          }
        ), cv?: Maybe<(
          { __typename?: 'FileNode' }
          & Pick<FileNode, 'name' | 'url'>
        )>
      }
    )>, allClusters?: Maybe<(
      { __typename?: 'ClusterNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'ClusterNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'ClusterNode' }
              & Pick<ClusterNode, 'id' | 'name'>
            )>
          }
        )>>
      }
    )>
  }
);

export type GetPeopleQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  user_Position_Id_In?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  department?: Maybe<Scalars['ID']>;
  workLocation_In?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  tools_Tool_Id_In?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  fullName_Icontains?: Maybe<Scalars['String']>;
  dashSearch?: Maybe<Scalars['String']>;
}>;


export type GetPeopleQuery = (
  { __typename?: 'Query' }
  & {
    allDepartments?: Maybe<(
      { __typename?: 'DepartmentNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'DepartmentNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'DepartmentNode' }
              & Pick<DepartmentNode, 'id' | 'name'>
            )>
          }
        )>>
      }
    )>, allPositions?: Maybe<(
      { __typename?: 'PositionNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'PositionNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'PositionNode' }
              & Pick<PositionNode, 'id' | 'name'>
            )>
          }
        )>>
      }
    )>, allWorkers?: Maybe<(
      { __typename?: 'WorkerProfileNodeConnection' }
      & {
        pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
        ), edges: Array<Maybe<(
          { __typename?: 'WorkerProfileNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'WorkerProfileNode' }
              & Pick<WorkerProfileNode, 'id' | 'latestRoles'>
              & {
                picture?: Maybe<(
                  { __typename?: 'FileNode' }
                  & Pick<FileNode, 'url' | 'name'>
                )>, invitations: (
                  { __typename?: 'ProjectInvitationNodeConnection' }
                  & {
                    edges: Array<Maybe<(
                      { __typename?: 'ProjectInvitationNodeEdge' }
                      & {
                        node?: Maybe<(
                          { __typename?: 'ProjectInvitationNode' }
                          & Pick<ProjectInvitationNode, 'id'>
                          & {
                            project: (
                              { __typename?: 'ProjectNode' }
                              & Pick<ProjectNode, 'name'>
                            )
                          }
                        )>
                      }
                    )>>
                  }
                ), clusters: (
                  { __typename?: 'ClusterNodeConnection' }
                  & {
                    edges: Array<Maybe<(
                      { __typename?: 'ClusterNodeEdge' }
                      & {
                        node?: Maybe<(
                          { __typename?: 'ClusterNode' }
                          & Pick<ClusterNode, 'id' | 'name'>
                        )>
                      }
                    )>>
                  }
                ), department?: Maybe<(
                  { __typename?: 'DepartmentNode' }
                  & Pick<DepartmentNode, 'id' | 'name'>
                )>, user?: Maybe<(
                  { __typename?: 'UserNode' }
                  & Pick<UserNode, 'displayName'>
                  & {
                    position?: Maybe<(
                      { __typename?: 'PositionNode' }
                      & Pick<PositionNode, 'name'>
                    )>
                  }
                )>
              }
            )>
          }
        )>>
      }
    )>
  }
);

export type PersonDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PersonDetailQuery = (
  { __typename?: 'Query' }
  & {
    worker?: Maybe<(
      { __typename?: 'WorkerProfileNode' }
      & Pick<WorkerProfileNode, 'id' | 'latestRoles' | 'workLocation'>
      & {
        picture?: Maybe<(
          { __typename?: 'FileNode' }
          & Pick<FileNode, 'url' | 'name'>
        )>, department?: Maybe<(
          { __typename?: 'DepartmentNode' }
          & Pick<DepartmentNode, 'name'>
        )>, clusters: (
          { __typename?: 'ClusterNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'ClusterNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'ClusterNode' }
                  & Pick<ClusterNode, 'id' | 'name'>
                )>
              }
            )>>
          }
        ), user?: Maybe<(
          { __typename?: 'UserNode' }
          & Pick<UserNode, 'motiusEmail' | 'displayName'>
          & {
            position?: Maybe<(
              { __typename?: 'PositionNode' }
              & Pick<PositionNode, 'name'>
            )>
          }
        )>, tools: (
          { __typename?: 'WorkerToolNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'WorkerToolNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'WorkerToolNode' }
                  & Pick<WorkerToolNode, 'id'>
                  & {
                    tool: (
                      { __typename?: 'ToolNode' }
                      & Pick<ToolNode, 'name'>
                    )
                  }
                )>
              }
            )>>
          }
        ), invitations: (
          { __typename?: 'ProjectInvitationNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'ProjectInvitationNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'ProjectInvitationNode' }
                  & Pick<ProjectInvitationNode, 'id' | 'stateLabel'>
                  & {
                    project: (
                      { __typename?: 'ProjectNode' }
                      & Pick<ProjectNode, 'id' | 'name' | 'startDate' | 'endDate' | 'isActive'>
                      & {
                        image?: Maybe<(
                          { __typename?: 'FileNode' }
                          & Pick<FileNode, 'url' | 'name'>
                        )>
                      }
                    ), role?: Maybe<(
                      { __typename?: 'ProjectRoleNode' }
                      & Pick<ProjectRoleNode, 'name'>
                    )>
                  }
                )>
              }
            )>>
          }
        )
      }
    )>
  }
);

export type PersonalInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonalInformationQuery = (
  { __typename?: 'Query' }
  & {
    myWorker?: Maybe<(
      { __typename?: 'MyWorkerNode' }
      & Pick<MyWorkerNode, 'id' | 'phone'>
      & {
        user?: Maybe<(
          { __typename?: 'UserNode' }
          & Pick<UserNode, 'id' | 'motiusEmail' | 'displayName' | 'email'>
        )>
      }
    )>
  }
);

export type SetClustersMutationVariables = Exact<{
  clusterIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type SetClustersMutation = (
  { __typename?: 'Mutation' }
  & {
    setWorkerClusters?: Maybe<(
      { __typename?: 'SetWorkerClusters' }
      & {
        clusters?: Maybe<Array<Maybe<(
          { __typename?: 'ClusterNode' }
          & Pick<ClusterNode, 'id'>
        )>>>
      }
    )>
  }
);

export type UpdatePersonalInformationMutationVariables = Exact<{
  phone: Scalars['String'];
  displayName: Scalars['String'];
}>;


export type UpdatePersonalInformationMutation = (
  { __typename?: 'Mutation' }
  & {
    updateWorker?: Maybe<(
      { __typename?: 'UpdateWorker' }
      & {
        worker?: Maybe<(
          { __typename?: 'WorkerProfileNode' }
          & Pick<WorkerProfileNode, 'id'>
        )>
      }
    )>, updateUser?: Maybe<(
      { __typename?: 'UpdateUser' }
      & {
        user?: Maybe<(
          { __typename?: 'UserNode' }
          & Pick<UserNode, 'id'>
        )>
      }
    )>
  }
);

export type UpdateWorkerToolMutationVariables = Exact<{
  id: Scalars['String'];
  rating: Scalars['Int'];
}>;


export type UpdateWorkerToolMutation = (
  { __typename?: 'Mutation' }
  & {
    updateWorkerTool?: Maybe<(
      { __typename?: 'UpdateWorkerTool' }
      & {
        workerTool?: Maybe<(
          { __typename?: 'WorkerToolNode' }
          & Pick<WorkerToolNode, 'id'>
        )>
      }
    )>
  }
);

export type GetMyInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyInvitationsQuery = (
  { __typename?: 'Query' }
  & {
    myInvitations?: Maybe<(
      { __typename?: 'ProjectInvitationNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'ProjectInvitationNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'ProjectInvitationNode' }
              & Pick<ProjectInvitationNode, 'id' | 'stateLabel'>
              & {
                role?: Maybe<(
                  { __typename?: 'ProjectRoleNode' }
                  & Pick<ProjectRoleNode, 'name'>
                )>, project: (
                  { __typename?: 'ProjectNode' }
                  & Pick<ProjectNode, 'id' | 'name' | 'color' | 'startDate' | 'endDate' | 'isActive' | 'description'>
                  & {
                    image?: Maybe<(
                      { __typename?: 'FileNode' }
                      & Pick<FileNode, 'url' | 'name'>
                    )>, roles: (
                      { __typename?: 'ProjectRoleNodeConnection' }
                      & {
                        edges: Array<Maybe<(
                          { __typename?: 'ProjectRoleNodeEdge' }
                          & {
                            node?: Maybe<(
                              { __typename?: 'ProjectRoleNode' }
                              & Pick<ProjectRoleNode, 'id'>
                            )>
                          }
                        )>>
                      }
                    ), clusters?: Maybe<(
                      { __typename?: 'ClusterNodeConnection' }
                      & {
                        edges: Array<Maybe<(
                          { __typename?: 'ClusterNodeEdge' }
                          & {
                            node?: Maybe<(
                              { __typename?: 'ClusterNode' }
                              & Pick<ClusterNode, 'name'>
                            )>
                          }
                        )>>
                      }
                    )>
                  }
                )
              }
            )>
          }
        )>>
      }
    )>
  }
);

export type GetOpenRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOpenRolesQuery = (
  { __typename?: 'Query' }
  & {
    allProjectRoles?: Maybe<(
      { __typename?: 'ProjectRoleNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'ProjectRoleNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'ProjectRoleNode' }
              & Pick<ProjectRoleNode, 'id' | 'startDate' | 'endDate' | 'hours'>
              & {
                tools: (
                  { __typename?: 'ToolNodeConnection' }
                  & {
                    edges: Array<Maybe<(
                      { __typename?: 'ToolNodeEdge' }
                      & {
                        node?: Maybe<(
                          { __typename?: 'ToolNode' }
                          & Pick<ToolNode, 'id' | 'name'>
                        )>
                      }
                    )>>
                  }
                ), project: (
                  { __typename?: 'ProjectNode' }
                  & Pick<ProjectNode, 'id' | 'name' | 'startDate' | 'endDate' | 'color' | 'description'>
                  & {
                    image?: Maybe<(
                      { __typename?: 'FileNode' }
                      & Pick<FileNode, 'url' | 'name'>
                    )>, clusters?: Maybe<(
                      { __typename?: 'ClusterNodeConnection' }
                      & {
                        edges: Array<Maybe<(
                          { __typename?: 'ClusterNodeEdge' }
                          & {
                            node?: Maybe<(
                              { __typename?: 'ClusterNode' }
                              & Pick<ClusterNode, 'id' | 'name'>
                            )>
                          }
                        )>>
                      }
                    )>
                  }
                )
              }
            )>
          }
        )>>
      }
    )>
  }
);

export type GetProjectDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProjectDetailsQuery = (
  { __typename?: 'Query' }
  & {
    project?: Maybe<(
      { __typename?: 'ProjectNode' }
      & Pick<ProjectNode, 'id' | 'name' | 'nameShort' | 'slug' | 'description' | 'startDate' | 'endDate' | 'costCenterInternal'>
      & {
        costCenter?: Maybe<(
          { __typename?: 'CostCenterNode' }
          & Pick<CostCenterNode, 'publicId'>
        )>, clusters?: Maybe<(
          { __typename?: 'ClusterNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'ClusterNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'ClusterNode' }
                  & Pick<ClusterNode, 'name'>
                )>
              }
            )>>
          }
        )>, image?: Maybe<(
          { __typename?: 'FileNode' }
          & Pick<FileNode, 'url' | 'name'>
        )>, roles: (
          { __typename?: 'ProjectRoleNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'ProjectRoleNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'ProjectRoleNode' }
                  & Pick<ProjectRoleNode, 'id' | 'name'>
                  & {
                    invitations: (
                      { __typename?: 'ProjectInvitationNodeConnection' }
                      & {
                        edges: Array<Maybe<(
                          { __typename?: 'ProjectInvitationNodeEdge' }
                          & {
                            node?: Maybe<(
                              { __typename?: 'ProjectInvitationNode' }
                              & {
                                worker: (
                                  { __typename?: 'MyWorkerNode' }
                                  & Pick<MyWorkerNode, 'id'>
                                  & {
                                    user?: Maybe<(
                                      { __typename?: 'UserNode' }
                                      & Pick<UserNode, 'displayName'>
                                    )>, picture?: Maybe<(
                                      { __typename?: 'FileNode' }
                                      & Pick<FileNode, 'url' | 'name'>
                                    )>
                                  }
                                )
                              }
                            )>
                          }
                        )>>
                      }
                    )
                  }
                )>
              }
            )>>
          }
        ), invitations: Maybe<(
          { __typename?: 'ProjectInvitationNodeConnection' }
          & {
            edges: Array<Maybe<(
              { __typename?: 'ProjectInvitationNodeEdge' }
              & {
                node?: Maybe<(
                  { __typename?: 'ProjectInvitationNode' }
                  & Pick<ProjectInvitationNode, 'feedbackLink' >
                  & {
                    worker: (
                      { __typename?: 'MyWorkerNode' }
                      & Pick<MyWorkerNode, 'id'>
                      & {
                        user?: Maybe<(
                          { __typename?: 'UserNode' }
                          & Pick<UserNode, 'displayName'>
                        )>, picture?: Maybe<(
                          { __typename?: 'FileNode' }
                          & Pick<FileNode, 'url' | 'name'>
                        )>
                      }
                    ),
                    
                  }
                )>
              }
            )>>
          }
        )>
      }
    )>, allProjectRoles?: Maybe<(
      { __typename?: 'ProjectRoleNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'ProjectRoleNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'ProjectRoleNode' }
              & Pick<ProjectRoleNode, 'id' | 'name'>
              & {
                workers: (
                  { __typename?: 'MyWorkerNodeConnection' }
                  & {
                    edges: Array<Maybe<(
                      { __typename?: 'MyWorkerNodeEdge' }
                      & {
                        node?: Maybe<(
                          { __typename?: 'MyWorkerNode' }
                          & Pick<MyWorkerNode, 'id'>
                        )>
                      }
                    )>>
                  }
                )
              }
            )>
          }
        )>>
      }
    )>
  }
);

export type AppendTimelogMutationVariables = Exact<{
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  durationSeconds: Scalars['Int'];
  tags?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  jiraIssues?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  project: Scalars['ID'];
  properties?: Maybe<Scalars['JSONString']>;
}>;


export type AppendTimelogMutation = (
  { __typename?: 'Mutation' }
  & {
    appendTimelog?: Maybe<(
      { __typename?: 'AppendTimelog' }
      & {
        timelog?: Maybe<(
          { __typename?: 'TimelogNode' }
          & Pick<TimelogNode, 'id'>
        )>
      }
    )>
  }
);

export type CreateOpportunityTimelogMutationVariables = Exact<{
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  durationSeconds: Scalars['Int'];
  tags?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  jiraIssues?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  crmOpportunityId?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSONString']>;
  timelogType?: Maybe<Scalars['String']>;
}>;


export type CreateOpportunityTimelogMutation = (
  { __typename?: 'Mutation' }
  & {
    createTimelog?: Maybe<(
      { __typename?: 'CreateTimelog' }
      & {
        timelog?: Maybe<(
          { __typename?: 'TimelogNode' }
          & Pick<TimelogNode, 'id'>
        )>
      }
    )>
  }
);

export type CreateTimelogMutationVariables = Exact<{
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  durationSeconds: Scalars['Int'];
  tags?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  jiraIssues?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  project?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSONString']>;
  timelogType?: Maybe<Scalars['String']>;
}>;


export type CreateTimelogMutation = (
  { __typename?: 'Mutation' }
  & {
    createTimelog?: Maybe<(
      { __typename?: 'CreateTimelog' }
      & {
        timelog?: Maybe<(
          { __typename?: 'TimelogNode' }
          & Pick<TimelogNode, 'id'>
        )>
      }
    )>
  }
);

export type DailyTimelogDataQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type DailyTimelogDataQuery = (
  { __typename?: 'Query' }
  & {
    myTimelogs?: Maybe<(
      { __typename?: 'TimelogNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'TimelogNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'TimelogNode' }
              & TimelogFragmentFragment
            )>
          }
        )>>
      }
    )>, myProjectPlan?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectPlanNode' }
      & Pick<ProjectPlanNode, 'projectId' | 'plannedHours' | 'startDate'>
    )>>>
  }
);

export type DeleteTimelogMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTimelogMutation = (
  { __typename?: 'Mutation' }
  & {
    deleteTimelog?: Maybe<(
      { __typename?: 'DeleteTimelog' }
      & Pick<DeleteTimelog, 'deleted'>
    )>
  }
);

export type ReportsPageQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  projects?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  tags?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type ReportsPageQuery = (
  { __typename?: 'Query' }
  & {
    projects?: Maybe<Array<(
      { __typename?: 'ProjectsCache' }
      & Pick<ProjectsCache, 'id' | 'name' | 'isActive' | 'color'>
    )>>, myTimelogs?: Maybe<(
      { __typename?: 'TimelogNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'TimelogNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'TimelogNode' }
              & Pick<TimelogNode, 'id' | 'date' | 'state' | 'stateLabel' | 'comment' | 'durationSeconds' | 'timelogTypeLabel'>
              & {
                project?: Maybe<(
                  { __typename?: 'ProjectNode' }
                  & Pick<ProjectNode, 'id' | 'name' | 'color'>
                )>, tags: (
                  { __typename?: 'TimelogTagNodeConnection' }
                  & {
                    edges: Array<Maybe<(
                      { __typename?: 'TimelogTagNodeEdge' }
                      & {
                        node?: Maybe<(
                          { __typename?: 'TimelogTagNode' }
                          & Pick<TimelogTagNode, 'id' | 'name'>
                        )>
                      }
                    )>>
                  }
                )
              }
            )>
          }
        )>>
      }
    )>, myTimelogStats?: Maybe<(
      { __typename?: 'TimelogStatsNode' }
      & Pick<TimelogStatsNode, 'targetUtilization' | 'loggedSeconds' | 'capaRatio' | 'averageWeeklySeconds' | 'trackedAgainstPlanned'>
      & {
        projects?: Maybe<Array<Maybe<(
          { __typename?: 'ProjectStats' }
          & Pick<ProjectStats, 'loggedSeconds'>
          & {
            project?: Maybe<(
              { __typename?: 'ProjectNode' }
              & Pick<ProjectNode, 'id' | 'name' | 'color'>
            )>
          }
        )>>>
      }
    )>, allTimelogTags?: Maybe<(
      { __typename?: 'TimelogTagNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'TimelogTagNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'TimelogTagNode' }
              & Pick<TimelogTagNode, 'id' | 'name'>
            )>
          }
        )>>
      }
    )>, myWorker?: Maybe<(
      { __typename?: 'MyWorkerNode' }
      & Pick<MyWorkerNode, 'isPermanent'>
    )>
  }
);

export type TimelogDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TimelogDetailsQuery = (
  { __typename?: 'Query' }
  & {
    timelog?: Maybe<(
      { __typename?: 'TimelogNode' }
      & TimelogFragmentFragment
    )>
  }
);

export type TimelogFragmentFragment = (
  { __typename?: 'TimelogNode' }
  & Pick<TimelogNode, 'id' | 'date' | 'state' | 'stateLabel' | 'comment' | 'durationSeconds' | 'paidDate' | 'approvedDate' | 'timelogTypeLabel' | 'properties' | 'crmOpportunityId'>
  & {
    project?: Maybe<(
      { __typename?: 'ProjectNode' }
      & Pick<ProjectNode, 'id' | 'name' | 'color'>
    )>, tags: (
      { __typename?: 'TimelogTagNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'TimelogTagNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'TimelogTagNode' }
              & Pick<TimelogTagNode, 'id' | 'name'>
            )>
          }
        )>>
      }
    ), jiraIssues: (
      { __typename?: 'JiraIssueNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'JiraIssueNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'JiraIssueNode' }
              & Pick<JiraIssueNode, 'id' | 'summary' | 'jiraKey'>
            )>
          }
        )>>
      }
    )
  }
);

export type UpdateTimelogMutationVariables = Exact<{
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  durationSeconds?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  jiraIssues?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  crmOpportunityId?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['ID']>;
  properties?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateTimelogMutation = (
  { __typename?: 'Mutation' }
  & {
    updateTimelog?: Maybe<(
      { __typename?: 'UpdateTimelog' }
      & {
        timelog?: Maybe<(
          { __typename?: 'TimelogNode' }
          & Pick<TimelogNode, 'id'>
        )>
      }
    )>
  }
);

export type WeeklyTimelogDataQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;


export type WeeklyTimelogDataQuery = (
  { __typename?: 'Query' }
  & {
    projects?: Maybe<Array<(
      { __typename?: 'ProjectsCache' }
      & Pick<ProjectsCache, 'id' | 'name' | 'isActive' | 'color' | 'costCenterInternal'>
    )>>, myTimelogs?: Maybe<(
      { __typename?: 'TimelogNodeConnection' }
      & {
        edges: Array<Maybe<(
          { __typename?: 'TimelogNodeEdge' }
          & {
            node?: Maybe<(
              { __typename?: 'TimelogNode' }
              & TimelogFragmentFragment
            )>
          }
        )>>
      }
    )>, myProjectPlan?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectPlanNode' }
      & Pick<ProjectPlanNode, 'projectId' | 'plannedHours' | 'startDate'>
    )>>>
  }
);

export const TimelogFragmentFragmentDoc = gql`
    fragment TimelogFragment on TimelogNode {
  id
  date
  state
  stateLabel
  comment
  durationSeconds
  paidDate
  approvedDate
  timelogTypeLabel
  properties
  project {
    id
    name
    color
  }
  crmOpportunityId
  tags {
    edges {
      node {
        id
        name
      }
    }
  }
  jiraIssues {
    edges {
      node {
        id
        summary
        jiraKey
      }
    }
  }
}
    `;
export const CreateToolAndWorkerToolDocument = gql`
    mutation CreateToolAndWorkerTool($name: String!, $rating: Int!) {
  createToolAndWorkerTool(name: $name, rating: $rating) {
    workerTool {
      id
      rating
      tool {
        name
      }
    }
  }
}
    `;
export type CreateToolAndWorkerToolMutationFn = Apollo.MutationFunction<CreateToolAndWorkerToolMutation, CreateToolAndWorkerToolMutationVariables>;

/**
 * __useCreateToolAndWorkerToolMutation__
 *
 * To run a mutation, you first call `useCreateToolAndWorkerToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateToolAndWorkerToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createToolAndWorkerToolMutation, { data, loading, error }] = useCreateToolAndWorkerToolMutation({
 *   variables: {
 *      name: // value for 'name'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useCreateToolAndWorkerToolMutation(baseOptions?: Apollo.MutationHookOptions<CreateToolAndWorkerToolMutation, CreateToolAndWorkerToolMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateToolAndWorkerToolMutation, CreateToolAndWorkerToolMutationVariables>(CreateToolAndWorkerToolDocument, options);
}
export type CreateToolAndWorkerToolMutationHookResult = ReturnType<typeof useCreateToolAndWorkerToolMutation>;
export type CreateToolAndWorkerToolMutationResult = Apollo.MutationResult<CreateToolAndWorkerToolMutation>;
export type CreateToolAndWorkerToolMutationOptions = Apollo.BaseMutationOptions<CreateToolAndWorkerToolMutation, CreateToolAndWorkerToolMutationVariables>;
export const CreateWorkerToolDocument = gql`
    mutation CreateWorkerTool($tool: String!, $rating: Int!) {
  createWorkerTool(tool: $tool, rating: $rating) {
    workerTool {
      id
      rating
      tool {
        id
        name
      }
    }
  }
}
    `;
export type CreateWorkerToolMutationFn = Apollo.MutationFunction<CreateWorkerToolMutation, CreateWorkerToolMutationVariables>;

/**
 * __useCreateWorkerToolMutation__
 *
 * To run a mutation, you first call `useCreateWorkerToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkerToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkerToolMutation, { data, loading, error }] = useCreateWorkerToolMutation({
 *   variables: {
 *      tool: // value for 'tool'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useCreateWorkerToolMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkerToolMutation, CreateWorkerToolMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateWorkerToolMutation, CreateWorkerToolMutationVariables>(CreateWorkerToolDocument, options);
}
export type CreateWorkerToolMutationHookResult = ReturnType<typeof useCreateWorkerToolMutation>;
export type CreateWorkerToolMutationResult = Apollo.MutationResult<CreateWorkerToolMutation>;
export type CreateWorkerToolMutationOptions = Apollo.BaseMutationOptions<CreateWorkerToolMutation, CreateWorkerToolMutationVariables>;
export const DeleteWorkerToolDocument = gql`
    mutation DeleteWorkerTool($id: String!) {
  deleteWorkerTool(id: $id) {
    deleted
  }
}
    `;
export type DeleteWorkerToolMutationFn = Apollo.MutationFunction<DeleteWorkerToolMutation, DeleteWorkerToolMutationVariables>;

/**
 * __useDeleteWorkerToolMutation__
 *
 * To run a mutation, you first call `useDeleteWorkerToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkerToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkerToolMutation, { data, loading, error }] = useDeleteWorkerToolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkerToolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkerToolMutation, DeleteWorkerToolMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteWorkerToolMutation, DeleteWorkerToolMutationVariables>(DeleteWorkerToolDocument, options);
}
export type DeleteWorkerToolMutationHookResult = ReturnType<typeof useDeleteWorkerToolMutation>;
export type DeleteWorkerToolMutationResult = Apollo.MutationResult<DeleteWorkerToolMutation>;
export type DeleteWorkerToolMutationOptions = Apollo.BaseMutationOptions<DeleteWorkerToolMutation, DeleteWorkerToolMutationVariables>;
export const GetToolsDocument = gql`
    query GetTools($name: String) {
  allTools(first: 10, name_Icontains: $name) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetToolsQuery__
 *
 * To run a query within a React component, call `useGetToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetToolsQuery(baseOptions?: Apollo.QueryHookOptions<GetToolsQuery, GetToolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetToolsQuery, GetToolsQueryVariables>(GetToolsDocument, options);
}
export function useGetToolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToolsQuery, GetToolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetToolsQuery, GetToolsQueryVariables>(GetToolsDocument, options);
}
export type GetToolsQueryHookResult = ReturnType<typeof useGetToolsQuery>;
export type GetToolsLazyQueryHookResult = ReturnType<typeof useGetToolsLazyQuery>;
export type GetToolsQueryResult = Apollo.QueryResult<GetToolsQuery, GetToolsQueryVariables>;
export const GetToolsByNameDocument = gql`
    query GetToolsByName($name: String) {
  allTools(name_Icontains: $name, first: 10) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetToolsByNameQuery__
 *
 * To run a query within a React component, call `useGetToolsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToolsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToolsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetToolsByNameQuery(baseOptions?: Apollo.QueryHookOptions<GetToolsByNameQuery, GetToolsByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetToolsByNameQuery, GetToolsByNameQueryVariables>(GetToolsByNameDocument, options);
}
export function useGetToolsByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetToolsByNameQuery, GetToolsByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetToolsByNameQuery, GetToolsByNameQueryVariables>(GetToolsByNameDocument, options);
}
export type GetToolsByNameQueryHookResult = ReturnType<typeof useGetToolsByNameQuery>;
export type GetToolsByNameLazyQueryHookResult = ReturnType<typeof useGetToolsByNameLazyQuery>;
export type GetToolsByNameQueryResult = Apollo.QueryResult<GetToolsByNameQuery, GetToolsByNameQueryVariables>;
export const MyProfileDocument = gql`
    query MyProfile {
  myWorker {
    id
    picture {
      name
      url
    }
    phone
    workLocation
    user {
      email
      displayName
      motiusEmail
      position {
        name
      }
    }
  }
}
    `;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export function useMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
}
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const MySkillsDocument = gql`
    query MySkills {
  myWorker {
    id
    clusters {
      edges {
        node {
          id
          name
        }
      }
    }
    tools {
      edges {
        node {
          id
          rating
          tool {
            name
          }
        }
      }
    }
    cv {
      name
      url
    }
  }
  allClusters {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMySkillsQuery__
 *
 * To run a query within a React component, call `useMySkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySkillsQuery(baseOptions?: Apollo.QueryHookOptions<MySkillsQuery, MySkillsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MySkillsQuery, MySkillsQueryVariables>(MySkillsDocument, options);
}
export function useMySkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MySkillsQuery, MySkillsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MySkillsQuery, MySkillsQueryVariables>(MySkillsDocument, options);
}
export type MySkillsQueryHookResult = ReturnType<typeof useMySkillsQuery>;
export type MySkillsLazyQueryHookResult = ReturnType<typeof useMySkillsLazyQuery>;
export type MySkillsQueryResult = Apollo.QueryResult<MySkillsQuery, MySkillsQueryVariables>;
export const GetPeopleDocument = gql`
    query GetPeople($after: String, $before: String, $user_Position_Id_In: [String], $department: ID, $workLocation_In: [String], $tools_Tool_Id_In: [String], $fullName_Icontains: String, $dashSearch: String) {
  allDepartments {
    edges {
      node {
        id
        name
      }
    }
  }
  allPositions {
    edges {
      node {
        id
        name
      }
    }
  }
  allWorkers(
    state: "accepted"
    activeContract: true
    first: 10
    after: $after
    before: $before
    user_Position_Id_In: $user_Position_Id_In
    department: $department
    workLocation_In: $workLocation_In
    tools_Tool_Id_In: $tools_Tool_Id_In
    fullName_Icontains: $fullName_Icontains
    userType: "regular"
    dashSearch: $dashSearch
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        picture {
          url
          name
        }
        latestRoles
        invitations(first: 4, state_In: "6") {
          edges {
            node {
              id
              project {
                name
              }
            }
          }
        }
        clusters {
          edges {
            node {
              id
              name
            }
          }
        }
        department {
          id
          name
        }
        user {
          displayName
          position {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPeopleQuery__
 *
 * To run a query within a React component, call `useGetPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      user_Position_Id_In: // value for 'user_Position_Id_In'
 *      department: // value for 'department'
 *      workLocation_In: // value for 'workLocation_In'
 *      tools_Tool_Id_In: // value for 'tools_Tool_Id_In'
 *      fullName_Icontains: // value for 'fullName_Icontains'
 *      dashSearch: // value for 'dashSearch'
 *   },
 * });
 */
export function useGetPeopleQuery(baseOptions?: Apollo.QueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options);
}
export function useGetPeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options);
}
export type GetPeopleQueryHookResult = ReturnType<typeof useGetPeopleQuery>;
export type GetPeopleLazyQueryHookResult = ReturnType<typeof useGetPeopleLazyQuery>;
export type GetPeopleQueryResult = Apollo.QueryResult<GetPeopleQuery, GetPeopleQueryVariables>;
export const PersonDetailDocument = gql`
    query PersonDetail($id: ID!) {
  worker(id: $id) {
    id
    latestRoles
    workLocation
    picture {
      url
      name
    }
    department {
      name
    }
    clusters {
      edges {
        node {
          id
          name
        }
      }
    }
    user {
      motiusEmail
      displayName
      position {
        name
      }
    }
    tools {
      edges {
        node {
          id
          tool {
            name
          }
        }
      }
    }
    invitations(project_CostCenter_Internal: false) {
      edges {
        node {
          id
          stateLabel
          project {
            id
            name
            startDate
            endDate
            isActive
            image {
              url
              name
            }
          }
          role {
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePersonDetailQuery__
 *
 * To run a query within a React component, call `usePersonDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonDetailQuery(baseOptions: Apollo.QueryHookOptions<PersonDetailQuery, PersonDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument, options);
}
export function usePersonDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonDetailQuery, PersonDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument, options);
}
export type PersonDetailQueryHookResult = ReturnType<typeof usePersonDetailQuery>;
export type PersonDetailLazyQueryHookResult = ReturnType<typeof usePersonDetailLazyQuery>;
export type PersonDetailQueryResult = Apollo.QueryResult<PersonDetailQuery, PersonDetailQueryVariables>;
export const PersonalInformationDocument = gql`
    query PersonalInformation {
  myWorker {
    id
    phone
    user {
      motiusEmail
      displayName
      email
    }
  }
}
    `;

/**
 * __usePersonalInformationQuery__
 *
 * To run a query within a React component, call `usePersonalInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonalInformationQuery(baseOptions?: Apollo.QueryHookOptions<PersonalInformationQuery, PersonalInformationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PersonalInformationQuery, PersonalInformationQueryVariables>(PersonalInformationDocument, options);
}
export function usePersonalInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonalInformationQuery, PersonalInformationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PersonalInformationQuery, PersonalInformationQueryVariables>(PersonalInformationDocument, options);
}
export type PersonalInformationQueryHookResult = ReturnType<typeof usePersonalInformationQuery>;
export type PersonalInformationLazyQueryHookResult = ReturnType<typeof usePersonalInformationLazyQuery>;
export type PersonalInformationQueryResult = Apollo.QueryResult<PersonalInformationQuery, PersonalInformationQueryVariables>;
export const SetClustersDocument = gql`
    mutation SetClusters($clusterIds: [String]) {
  setWorkerClusters(clusters: $clusterIds) {
    clusters {
      id
    }
  }
}
    `;
export type SetClustersMutationFn = Apollo.MutationFunction<SetClustersMutation, SetClustersMutationVariables>;

/**
 * __useSetClustersMutation__
 *
 * To run a mutation, you first call `useSetClustersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClustersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClustersMutation, { data, loading, error }] = useSetClustersMutation({
 *   variables: {
 *      clusterIds: // value for 'clusterIds'
 *   },
 * });
 */
export function useSetClustersMutation(baseOptions?: Apollo.MutationHookOptions<SetClustersMutation, SetClustersMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetClustersMutation, SetClustersMutationVariables>(SetClustersDocument, options);
}
export type SetClustersMutationHookResult = ReturnType<typeof useSetClustersMutation>;
export type SetClustersMutationResult = Apollo.MutationResult<SetClustersMutation>;
export type SetClustersMutationOptions = Apollo.BaseMutationOptions<SetClustersMutation, SetClustersMutationVariables>;
export const UpdatePersonalInformationDocument = gql`
    mutation UpdatePersonalInformation($phone: String!, $displayName: String!) {
  updateWorker(phone: $phone) {
    worker {
      id
    }
  }
  updateUser(displayName: $displayName) {
    user {
      id
    }
  }
}
    `;
export type UpdatePersonalInformationMutationFn = Apollo.MutationFunction<UpdatePersonalInformationMutation, UpdatePersonalInformationMutationVariables>;

/**
 * __useUpdatePersonalInformationMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalInformationMutation, { data, loading, error }] = useUpdatePersonalInformationMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useUpdatePersonalInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonalInformationMutation, UpdatePersonalInformationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePersonalInformationMutation, UpdatePersonalInformationMutationVariables>(UpdatePersonalInformationDocument, options);
}
export type UpdatePersonalInformationMutationHookResult = ReturnType<typeof useUpdatePersonalInformationMutation>;
export type UpdatePersonalInformationMutationResult = Apollo.MutationResult<UpdatePersonalInformationMutation>;
export type UpdatePersonalInformationMutationOptions = Apollo.BaseMutationOptions<UpdatePersonalInformationMutation, UpdatePersonalInformationMutationVariables>;
export const UpdateWorkerToolDocument = gql`
    mutation UpdateWorkerTool($id: String!, $rating: Int!) {
  updateWorkerTool(id: $id, rating: $rating) {
    workerTool {
      id
    }
  }
}
    `;
export type UpdateWorkerToolMutationFn = Apollo.MutationFunction<UpdateWorkerToolMutation, UpdateWorkerToolMutationVariables>;

/**
 * __useUpdateWorkerToolMutation__
 *
 * To run a mutation, you first call `useUpdateWorkerToolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkerToolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkerToolMutation, { data, loading, error }] = useUpdateWorkerToolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useUpdateWorkerToolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkerToolMutation, UpdateWorkerToolMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateWorkerToolMutation, UpdateWorkerToolMutationVariables>(UpdateWorkerToolDocument, options);
}
export type UpdateWorkerToolMutationHookResult = ReturnType<typeof useUpdateWorkerToolMutation>;
export type UpdateWorkerToolMutationResult = Apollo.MutationResult<UpdateWorkerToolMutation>;
export type UpdateWorkerToolMutationOptions = Apollo.BaseMutationOptions<UpdateWorkerToolMutation, UpdateWorkerToolMutationVariables>;
export const GetMyInvitationsDocument = gql`
    query GetMyInvitations {
  myInvitations(project_CostCenter_Internal: false) {
    edges {
      node {
        id
        stateLabel
        role {
          name
        }
        project {
          id
          name
          color
          startDate
          endDate
          isActive
          image {
            url
            name
          }
          description
          roles(isStaffed: false, isPublic: true) {
            edges {
              node {
                id
              }
            }
          }
          clusters {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMyInvitationsQuery__
 *
 * To run a query within a React component, call `useGetMyInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>(GetMyInvitationsDocument, options);
}
export function useGetMyInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>(GetMyInvitationsDocument, options);
}
export type GetMyInvitationsQueryHookResult = ReturnType<typeof useGetMyInvitationsQuery>;
export type GetMyInvitationsLazyQueryHookResult = ReturnType<typeof useGetMyInvitationsLazyQuery>;
export type GetMyInvitationsQueryResult = Apollo.QueryResult<GetMyInvitationsQuery, GetMyInvitationsQueryVariables>;
export const GetOpenRolesDocument = gql`
    query GetOpenRoles {
  allProjectRoles(isPublic: true, isStaffed: false) {
    edges {
      node {
        id
        startDate
        endDate
        hours
        tools {
          edges {
            node {
              id
              name
            }
          }
        }
        project {
          id
          name
          startDate
          endDate
          color
          description
          image {
            url
            name
          }
          clusters {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOpenRolesQuery__
 *
 * To run a query within a React component, call `useGetOpenRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOpenRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetOpenRolesQuery, GetOpenRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOpenRolesQuery, GetOpenRolesQueryVariables>(GetOpenRolesDocument, options);
}
export function useGetOpenRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOpenRolesQuery, GetOpenRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOpenRolesQuery, GetOpenRolesQueryVariables>(GetOpenRolesDocument, options);
}
export type GetOpenRolesQueryHookResult = ReturnType<typeof useGetOpenRolesQuery>;
export type GetOpenRolesLazyQueryHookResult = ReturnType<typeof useGetOpenRolesLazyQuery>;
export type GetOpenRolesQueryResult = Apollo.QueryResult<GetOpenRolesQuery, GetOpenRolesQueryVariables>;
export const GetProjectDetailsDocument = gql`
    query GetProjectDetails($id: ID!) {
  project(id: $id) {
    id
    name
    nameShort
    slug
    description
    startDate
    endDate
    costCenterInternal
    costCenter {
      publicId
    }
    clusters {
      edges {
        node {
          name
        }
      }
    }
    image {
      url
      name
    }
    roles {
      edges {
        node {
          id
          name
          invitations(state_In: "6") {
            edges {
              node {
                worker {
                  id
                  user {
                    displayName
                  }
                  picture {
                    url
                    name
                  }
                }
                feedbackLink
              }
            }
          }
        }
      }
    }
    invitations(state_In: "6") {
      edges {
        node {
          worker {
            id
            user{
              displayName
              position{
                name
              }
            }
            picture {
              url
              name
            }
          }
          feedbackLink
          role{
            name
            id
          }
        }
      }
    }
  }
  allProjectRoles(project: $id, isStaffed: false, isPublic: true) {
    edges {
      node {
        id
        name
        workers {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectDetailsQuery, GetProjectDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectDetailsQuery, GetProjectDetailsQueryVariables>(GetProjectDetailsDocument, options);
}
export function useGetProjectDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectDetailsQuery, GetProjectDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetProjectDetailsQuery, GetProjectDetailsQueryVariables>(GetProjectDetailsDocument, options);
}
export type GetProjectDetailsQueryHookResult = ReturnType<typeof useGetProjectDetailsQuery>;
export type GetProjectDetailsLazyQueryHookResult = ReturnType<typeof useGetProjectDetailsLazyQuery>;
export type GetProjectDetailsQueryResult = Apollo.QueryResult<GetProjectDetailsQuery, GetProjectDetailsQueryVariables>;
export const AppendTimelogDocument = gql`
    mutation AppendTimelog($comment: String, $date: Date!, $durationSeconds: Int!, $tags: [ID!], $jiraIssues: [ID!], $project: ID!, $properties: JSONString) {
  appendTimelog(
    comment: $comment
    date: $date
    durationSeconds: $durationSeconds
    tags: $tags
    jiraIssues: $jiraIssues
    project: $project
    properties: $properties
  ) {
    timelog {
      id
    }
  }
}
    `;
export type AppendTimelogMutationFn = Apollo.MutationFunction<AppendTimelogMutation, AppendTimelogMutationVariables>;

/**
 * __useAppendTimelogMutation__
 *
 * To run a mutation, you first call `useAppendTimelogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppendTimelogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appendTimelogMutation, { data, loading, error }] = useAppendTimelogMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      date: // value for 'date'
 *      durationSeconds: // value for 'durationSeconds'
 *      tags: // value for 'tags'
 *      jiraIssues: // value for 'jiraIssues'
 *      project: // value for 'project'
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useAppendTimelogMutation(baseOptions?: Apollo.MutationHookOptions<AppendTimelogMutation, AppendTimelogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AppendTimelogMutation, AppendTimelogMutationVariables>(AppendTimelogDocument, options);
}
export type AppendTimelogMutationHookResult = ReturnType<typeof useAppendTimelogMutation>;
export type AppendTimelogMutationResult = Apollo.MutationResult<AppendTimelogMutation>;
export type AppendTimelogMutationOptions = Apollo.BaseMutationOptions<AppendTimelogMutation, AppendTimelogMutationVariables>;
export const CreateOpportunityTimelogDocument = gql`
    mutation CreateOpportunityTimelog($comment: String, $date: Date!, $durationSeconds: Int!, $tags: [ID!], $jiraIssues: [ID!], $crmOpportunityId: String, $properties: JSONString, $timelogType: String) {
  createTimelog(
    comment: $comment
    date: $date
    durationSeconds: $durationSeconds
    tags: $tags
    jiraIssues: $jiraIssues
    crmOpportunityId: $crmOpportunityId
    properties: $properties
    timelogType: $timelogType
  ) {
    timelog {
      id
    }
  }
}
    `;
export type CreateOpportunityTimelogMutationFn = Apollo.MutationFunction<CreateOpportunityTimelogMutation, CreateOpportunityTimelogMutationVariables>;

/**
 * __useCreateOpportunityTimelogMutation__
 *
 * To run a mutation, you first call `useCreateOpportunityTimelogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpportunityTimelogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpportunityTimelogMutation, { data, loading, error }] = useCreateOpportunityTimelogMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      date: // value for 'date'
 *      durationSeconds: // value for 'durationSeconds'
 *      tags: // value for 'tags'
 *      jiraIssues: // value for 'jiraIssues'
 *      crmOpportunityId: // value for 'crmOpportunityId'
 *      properties: // value for 'properties'
 *      timelogType: // value for 'timelogType'
 *   },
 * });
 */
export function useCreateOpportunityTimelogMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpportunityTimelogMutation, CreateOpportunityTimelogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOpportunityTimelogMutation, CreateOpportunityTimelogMutationVariables>(CreateOpportunityTimelogDocument, options);
}
export type CreateOpportunityTimelogMutationHookResult = ReturnType<typeof useCreateOpportunityTimelogMutation>;
export type CreateOpportunityTimelogMutationResult = Apollo.MutationResult<CreateOpportunityTimelogMutation>;
export type CreateOpportunityTimelogMutationOptions = Apollo.BaseMutationOptions<CreateOpportunityTimelogMutation, CreateOpportunityTimelogMutationVariables>;
export const CreateTimelogDocument = gql`
    mutation CreateTimelog($comment: String, $date: Date!, $durationSeconds: Int!, $tags: [ID!], $jiraIssues: [ID!], $project: String, $properties: JSONString, $timelogType: String) {
  createTimelog(
    comment: $comment
    date: $date
    durationSeconds: $durationSeconds
    tags: $tags
    jiraIssues: $jiraIssues
    project: $project
    properties: $properties
    timelogType: $timelogType
  ) {
    timelog {
      id
    }
  }
}
    `;
export type CreateTimelogMutationFn = Apollo.MutationFunction<CreateTimelogMutation, CreateTimelogMutationVariables>;

/**
 * __useCreateTimelogMutation__
 *
 * To run a mutation, you first call `useCreateTimelogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimelogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimelogMutation, { data, loading, error }] = useCreateTimelogMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      date: // value for 'date'
 *      durationSeconds: // value for 'durationSeconds'
 *      tags: // value for 'tags'
 *      jiraIssues: // value for 'jiraIssues'
 *      project: // value for 'project'
 *      properties: // value for 'properties'
 *      timelogType: // value for 'timelogType'
 *   },
 * });
 */
export function useCreateTimelogMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimelogMutation, CreateTimelogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTimelogMutation, CreateTimelogMutationVariables>(CreateTimelogDocument, options);
}
export type CreateTimelogMutationHookResult = ReturnType<typeof useCreateTimelogMutation>;
export type CreateTimelogMutationResult = Apollo.MutationResult<CreateTimelogMutation>;
export type CreateTimelogMutationOptions = Apollo.BaseMutationOptions<CreateTimelogMutation, CreateTimelogMutationVariables>;
export const DailyTimelogDataDocument = gql`
    query DailyTimelogData($date: Date!) {
  myTimelogs(date: $date) {
    edges {
      node {
        ...TimelogFragment
      }
    }
  }
  myProjectPlan(startDate: $date) {
    projectId
    plannedHours
    startDate
  }
}
    ${TimelogFragmentFragmentDoc}`;

/**
 * __useDailyTimelogDataQuery__
 *
 * To run a query within a React component, call `useDailyTimelogDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyTimelogDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyTimelogDataQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDailyTimelogDataQuery(baseOptions: Apollo.QueryHookOptions<DailyTimelogDataQuery, DailyTimelogDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DailyTimelogDataQuery, DailyTimelogDataQueryVariables>(DailyTimelogDataDocument, options);
}
export function useDailyTimelogDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyTimelogDataQuery, DailyTimelogDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DailyTimelogDataQuery, DailyTimelogDataQueryVariables>(DailyTimelogDataDocument, options);
}
export type DailyTimelogDataQueryHookResult = ReturnType<typeof useDailyTimelogDataQuery>;
export type DailyTimelogDataLazyQueryHookResult = ReturnType<typeof useDailyTimelogDataLazyQuery>;
export type DailyTimelogDataQueryResult = Apollo.QueryResult<DailyTimelogDataQuery, DailyTimelogDataQueryVariables>;
export const DeleteTimelogDocument = gql`
    mutation DeleteTimelog($id: ID!) {
  deleteTimelog(id: $id) {
    deleted
  }
}
    `;
export type DeleteTimelogMutationFn = Apollo.MutationFunction<DeleteTimelogMutation, DeleteTimelogMutationVariables>;

/**
 * __useDeleteTimelogMutation__
 *
 * To run a mutation, you first call `useDeleteTimelogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimelogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimelogMutation, { data, loading, error }] = useDeleteTimelogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimelogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimelogMutation, DeleteTimelogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTimelogMutation, DeleteTimelogMutationVariables>(DeleteTimelogDocument, options);
}
export type DeleteTimelogMutationHookResult = ReturnType<typeof useDeleteTimelogMutation>;
export type DeleteTimelogMutationResult = Apollo.MutationResult<DeleteTimelogMutation>;
export type DeleteTimelogMutationOptions = Apollo.BaseMutationOptions<DeleteTimelogMutation, DeleteTimelogMutationVariables>;
export const ReportsPageDocument = gql`
    query ReportsPage($startDate: Date!, $endDate: Date!, $projects: [ID!], $tags: [ID!]) {
  projects @client {
    id
    name
    isActive
    color
  }
  myTimelogs(
    date_Gte: $startDate
    date_Lte: $endDate
    project_Id_In: $projects
    tags_Id_In: $tags
  ) {
    edges {
      node {
        id
        date
        state
        stateLabel
        comment
        durationSeconds
        timelogTypeLabel
        project {
          id
          name
          color
        }
        tags {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  myTimelogStats(startDate: $startDate, endDate: $endDate) {
    targetUtilization
    loggedSeconds
    capaRatio
    averageWeeklySeconds
    trackedAgainstPlanned
    projects {
      loggedSeconds
      project {
        id
        name
        color
      }
    }
  }
  allTimelogTags {
    edges {
      node {
        id
        name
      }
    }
  }
  myWorker {
    isPermanent
  }
}
    `;

/**
 * __useReportsPageQuery__
 *
 * To run a query within a React component, call `useReportsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsPageQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      projects: // value for 'projects'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useReportsPageQuery(baseOptions: Apollo.QueryHookOptions<ReportsPageQuery, ReportsPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReportsPageQuery, ReportsPageQueryVariables>(ReportsPageDocument, options);
}
export function useReportsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsPageQuery, ReportsPageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReportsPageQuery, ReportsPageQueryVariables>(ReportsPageDocument, options);
}
export type ReportsPageQueryHookResult = ReturnType<typeof useReportsPageQuery>;
export type ReportsPageLazyQueryHookResult = ReturnType<typeof useReportsPageLazyQuery>;
export type ReportsPageQueryResult = Apollo.QueryResult<ReportsPageQuery, ReportsPageQueryVariables>;
export const TimelogDetailsDocument = gql`
    query TimelogDetails($id: ID!) {
  timelog(id: $id) {
    ...TimelogFragment
  }
}
    ${TimelogFragmentFragmentDoc}`;

/**
 * __useTimelogDetailsQuery__
 *
 * To run a query within a React component, call `useTimelogDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelogDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelogDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTimelogDetailsQuery(baseOptions: Apollo.QueryHookOptions<TimelogDetailsQuery, TimelogDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TimelogDetailsQuery, TimelogDetailsQueryVariables>(TimelogDetailsDocument, options);
}
export function useTimelogDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelogDetailsQuery, TimelogDetailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TimelogDetailsQuery, TimelogDetailsQueryVariables>(TimelogDetailsDocument, options);
}
export type TimelogDetailsQueryHookResult = ReturnType<typeof useTimelogDetailsQuery>;
export type TimelogDetailsLazyQueryHookResult = ReturnType<typeof useTimelogDetailsLazyQuery>;
export type TimelogDetailsQueryResult = Apollo.QueryResult<TimelogDetailsQuery, TimelogDetailsQueryVariables>;
export const UpdateTimelogDocument = gql`
    mutation UpdateTimelog($id: ID!, $comment: String, $date: Date, $durationSeconds: Int, $tags: [ID!], $jiraIssues: [ID!], $crmOpportunityId: String, $project: ID, $properties: JSONString) {
  updateTimelog(
    id: $id
    comment: $comment
    date: $date
    durationSeconds: $durationSeconds
    tags: $tags
    jiraIssues: $jiraIssues
    crmOpportunityId: $crmOpportunityId
    project: $project
    properties: $properties
  ) {
    timelog {
      id
    }
  }
}
    `;
export type UpdateTimelogMutationFn = Apollo.MutationFunction<UpdateTimelogMutation, UpdateTimelogMutationVariables>;

/**
 * __useUpdateTimelogMutation__
 *
 * To run a mutation, you first call `useUpdateTimelogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelogMutation, { data, loading, error }] = useUpdateTimelogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *      date: // value for 'date'
 *      durationSeconds: // value for 'durationSeconds'
 *      tags: // value for 'tags'
 *      jiraIssues: // value for 'jiraIssues'
 *      crmOpportunityId: // value for 'crmOpportunityId'
 *      project: // value for 'project'
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useUpdateTimelogMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelogMutation, UpdateTimelogMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTimelogMutation, UpdateTimelogMutationVariables>(UpdateTimelogDocument, options);
}
export type UpdateTimelogMutationHookResult = ReturnType<typeof useUpdateTimelogMutation>;
export type UpdateTimelogMutationResult = Apollo.MutationResult<UpdateTimelogMutation>;
export type UpdateTimelogMutationOptions = Apollo.BaseMutationOptions<UpdateTimelogMutation, UpdateTimelogMutationVariables>;
export const WeeklyTimelogDataDocument = gql`
    query WeeklyTimelogData($startDate: Date!, $endDate: Date!) {
  projects @client {
    id
    name
    isActive
    color
    costCenterInternal
  }
  myTimelogs(date_Gte: $startDate, date_Lte: $endDate) {
    edges {
      node {
        ...TimelogFragment
      }
    }
  }
  myProjectPlan(startDate: $startDate) {
    projectId
    plannedHours
    startDate
  }
}
    ${TimelogFragmentFragmentDoc}`;

/**
 * __useWeeklyTimelogDataQuery__
 *
 * To run a query within a React component, call `useWeeklyTimelogDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyTimelogDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyTimelogDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useWeeklyTimelogDataQuery(baseOptions: Apollo.QueryHookOptions<WeeklyTimelogDataQuery, WeeklyTimelogDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WeeklyTimelogDataQuery, WeeklyTimelogDataQueryVariables>(WeeklyTimelogDataDocument, options);
}
export function useWeeklyTimelogDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeeklyTimelogDataQuery, WeeklyTimelogDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<WeeklyTimelogDataQuery, WeeklyTimelogDataQueryVariables>(WeeklyTimelogDataDocument, options);
}
export type WeeklyTimelogDataQueryHookResult = ReturnType<typeof useWeeklyTimelogDataQuery>;
export type WeeklyTimelogDataLazyQueryHookResult = ReturnType<typeof useWeeklyTimelogDataLazyQuery>;
export type WeeklyTimelogDataQueryResult = Apollo.QueryResult<WeeklyTimelogDataQuery, WeeklyTimelogDataQueryVariables>;
/**
 * @typedef {Object} AppendTimelog
 * @property {TimelogNode} [timelog]
 * @property {JSONString} [error]
 */

/**
 * @typedef {Object} ApproveEmailChange
 * @property {string} [detail]
 */

/**
 * @typedef {Object} CRMOpportunityNode
 * @property {string} id
 * @property {string} [accountName]
 * @property {string} dealName
 * @property {ProjectCRMOpportunityStageChoices} stage
 */

/**
 * @typedef {Object} CRMOpportunityNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(CRMOpportunityNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} CRMOpportunityNodeEdge
 * @property {CRMOpportunityNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {("A_1"|"A_0")} ClientClientProfileClientTypeChoices
 */

/**
 * @typedef {("A_1"|"A_0")} ClientClientProfileVisibilityChoices
 */

/**
 * @typedef {Object} ClientProfileNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {string} name
 * @property {string} [nameShort]
 * @property {string} [phone]
 * @property {ClientClientProfileClientTypeChoices} clientType
 * @property {UserNode} [user]
 * @property {string} [supplierId]
 * @property {string} [vatNumber]
 * @property {ClientClientProfileVisibilityChoices} visibility
 * @property {UserNode} [accountManager]
 * @property {number} [order]
 * @property {boolean} isInternal
 * @property {ProjectNodeConnection} projects
 */

/**
 * @typedef {Object} ClientProfileNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ClientProfileNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ClientProfileNodeEdge
 * @property {ClientProfileNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ClusterNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {ClusterNode} [parent]
 * @property {string} name
 * @property {string} roleName
 * @property {string} slug
 * @property {string} code
 * @property {ToolNodeConnection} tools
 * @property {UserNode} [owner]
 * @property {string} color
 * @property {ClusterNodeConnection} children
 * @property {MyWorkerNodeConnection} workers
 * @property {ProjectRoleNodeConnection} projectRoles
 */

/**
 * @typedef {Object} ClusterNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ClusterNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ClusterNodeEdge
 * @property {ClusterNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ConfirmEmailChange
 * @property {string} [detail]
 */

/**
 * @typedef {("DEPOSIT"|"MILESTONE"|"DEPOSIT_AND_MILESTONE")} ContractProjectClientContractBillingChoices
 */

/**
 * @typedef {("DE"|"EN")} ContractProjectClientContractLanguageChoices
 */

/**
 * @typedef {("SPRINT"|"TASK"|"HOURLY"|"DAILY"|"SP")} ContractProjectClientContractPricingTypeChoices
 */

/**
 * @typedef {("DRAFT"|"REVIEWED"|"APPROVED"|"BILLED"|"PAID"|"REJECTED")} ContractProjectClientContractStateChoices
 */

/**
 * @typedef {("CONSULTANCY"|"SERVICES"|"EXTENSION"|"AGILE"|"SP")} ContractProjectClientContractTypeChoices
 */

/**
 * @typedef {("WORKING_STUDENT"|"FREELANCER"|"MINIJOB"|"INTERNSHIP"|"TEMPORARY"|"PERMANENT"|"PARTNER_EMPLOYMENT")} ContractProjectWorkerContractTypeChoices
 */

/**
 * @typedef {("FIX_SALARY"|"FIX_SALARY_ALL_IN"|"HOURLY_WAGE"|"FREELANCER")} ContractProjectWorkerContractTypeOfSalaryChoices
 */

/**
 * @typedef {("MUNICH"|"STUTTGART"|"BERLIN"|"DUBAI"|"SERBIA"|"INDIA"|"REMOTE")} ContractProjectWorkerContractWorkLocationChoices
 */

/**
 * @typedef {Object} CostCenterNode
 * @property {string} publicId
 * @property {string} name
 * @property {boolean} internal
 * @property {string} id
 */

/**
 * @typedef {Object} CreateTimelog
 * @property {TimelogNode} [timelog]
 */

/**
 * @typedef {Object} CreateToolAndAddWorker
 * @property {WorkerToolNode} [workerTool]
 */

/**
 * @typedef {Object} CreateWorkerLanguage
 * @property {WorkerLanguageNode} [workerLanguage]
 */

/**
 * @typedef {Object} CreateWorkerTool
 * @property {WorkerToolNode} [workerTool]
 */

/**
 * @typedef {Object} CustomNode
 * @property {string} id
 */

/**
 * @typedef {*} Date
 */

/**
 * @typedef {*} DateTime
 */

/**
 * @typedef {*} Decimal
 */

/**
 * @typedef {Object} DeleteTimelog
 * @property {boolean} [deleted]
 */

/**
 * @typedef {Object} DeleteWorkerLanguage
 * @property {boolean} [deleted]
 */

/**
 * @typedef {Object} DeleteWorkerTool
 * @property {boolean} [deleted]
 */

/**
 * @typedef {Object} DepartmentNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {string} name
 * @property {MyWorkerNode} [responsible]
 * @property {MyWorkerNodeConnection} workerprofileSet
 * @property {ProjectWorkerContractNodeConnection} projectworkercontractSet
 */

/**
 * @typedef {Object} DepartmentNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(DepartmentNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} DepartmentNodeEdge
 * @property {DepartmentNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} EnumNode
 * @property {string} [id]
 * @property {string} [label]
 */

/**
 * @typedef {Object} FileNode
 * @property {string} [url]
 * @property {string} [name]
 */

/**
 * @typedef {*} GenericScalar
 */

/**
 * @typedef {Object} GroupNode
 * @property {string} id
 * @property {string} name
 * @property {PositionNodeConnection} positionSet
 */

/**
 * @typedef {Object} GroupNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(GroupNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} GroupNodeEdge
 * @property {GroupNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} InitateEmailChange
 * @property {string} [detail]
 */

/**
 * @typedef {Object} InvoiceNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {PaymentInvoiceStateChoices} state
 * @property {PaymentInvoiceTypeChoices} type
 * @property {UserNode} [author]
 * @property {PaymentInvoiceLanguageChoices} language
 * @property {boolean} pretaxDeduction
 * @property {boolean} reverseCharge
 * @property {Date} [dueDate]
 * @property {string} [legacyId]
 * @property {string} [orderId]
 * @property {string} [customPublicId]
 * @property {PaymentInvoiceRecipientSelChoices} recipientSel
 * @property {number} [recipientId]
 * @property {CostCenterNode} costCenter
 * @property {ProjectClientContractNode} [clientContract]
 * @property {ProjectInvitationNode} [invitation]
 * @property {Date} [sentDate]
 * @property {Date} [billingDate]
 * @property {string} [billingEmail]
 * @property {Date} [paidDate]
 * @property {string} [address]
 * @property {string} [additionalAddress]
 * @property {string} [city]
 * @property {string} [postalCode]
 * @property {PaymentInvoiceCountryChoices} [country]
 * @property {string} [accountHolder]
 * @property {string} [iban]
 * @property {string} [bic]
 * @property {string} [taxId]
 * @property {Date} [startDate]
 * @property {Date} [endDate]
 * @property {FileNode} [pdf]
 * @property {string} [appendix]
 * @property {FileNode} [appendixPdf]
 * @property {GenericScalar} [additionalParams]
 * @property {string} [pdfPublicId]
 * @property {string} [receiptMessageId]
 * @property {string} publicId
 * @property {Decimal} discount
 * @property {Decimal} tax
 * @property {PaymentInvoiceCategoryChoices} [category]
 * @property {LineItemNodeConnection} lineItems
 */

/**
 * @typedef {Object} InvoiceNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(InvoiceNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} InvoiceNodeEdge
 * @property {InvoiceNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {*} JSONString
 */

/**
 * @typedef {Object} JiraIssueNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {string} summary
 * @property {string} [description]
 * @property {number} jiraId
 * @property {string} jiraKey
 * @property {string} type
 * @property {string} [status]
 * @property {number} [storyPoints]
 * @property {TimelogNodeConnection} timelogs
 */

/**
 * @typedef {Object} JiraIssueNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(JiraIssueNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} JiraIssueNodeEdge
 * @property {JiraIssueNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} LineItemNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {InvoiceNode} [invoice]
 * @property {string} message
 * @property {Decimal} amountNet
 * @property {string} [publicId]
 * @property {PaymentLineItemCategoryChoices} [category]
 */

/**
 * @typedef {Object} LineItemNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(LineItemNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} LineItemNodeEdge
 * @property {LineItemNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} Mutation
 * @property {UpdateWorkerTool} [updateWorkerTool]
 * @property {DeleteWorkerTool} [deleteWorkerTool]
 * @property {CreateWorkerTool} [createWorkerTool]
 * @property {CreateToolAndAddWorker} [createToolAndWorkerTool]
 * @property {UpdateProjectColor} [updateProjectColor]
 * @property {CreateTimelog} [createTimelog]
 * @property {UpdateTimelog} [updateTimelog]
 * @property {DeleteTimelog} [deleteTimelog]
 * @property {AppendTimelog} [appendTimelog]
 * @property {UpdateUser} [updateUser]
 * @property {InitateEmailChange} [initiateEmailChange]
 * @property {ApproveEmailChange} [approveEmailChange]
 * @property {ConfirmEmailChange} [confirmEmailChange]
 * @property {UpdateWorker} [updateWorker]
 * @property {SetWorkerClusters} [setWorkerClusters]
 * @property {CreateWorkerLanguage} [createWorkerLanguage]
 * @property {UpdateWorkerLanguage} [updateWorkerLanguage]
 * @property {DeleteWorkerLanguage} [deleteWorkerLanguage]
 */

/**
 * @typedef {Object} MyWorkerNode
 * @property {string} id
 * @property {string} [phone]
 * @property {string} [address]
 * @property {string} [city]
 * @property {string} [postalCode]
 * @property {UserNode} [user]
 * @property {string} fullName
 * @property {WorkerWorkerProfileStateChoices} state
 * @property {FileNode} [picture]
 * @property {DepartmentNode} [department]
 * @property {ClusterNodeConnection} clusters
 * @property {WorkerWorkerProfileWorkLocationChoices} workLocation
 * @property {WorkerToolNodeConnection} tools
 * @property {FileNode} [cv]
 * @property {string} [cvText]
 * @property {boolean} [isPermanent]
 * @property {string} [name]
 * @property {number} [weeklyHours]
 * @property {Array<(string|null|undefined)>} [latestRoles]
 * @property {string} [referralCode]
 */

/**
 * @typedef {Object} MyWorkerNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(MyWorkerNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} MyWorkerNodeEdge
 * @property {MyWorkerNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} Node
 * @property {string} id
 */

/**
 * @typedef {Object} PageInfo
 * @property {boolean} hasNextPage
 * @property {boolean} hasPreviousPage
 * @property {string} [startCursor]
 * @property {string} [endCursor]
 */

/**
 * @typedef {("TEAMBUILDING"|"TRANSPORTATION"|"SOFTWARE"|"HARDWARE"|"EDUCATION"|"HOSPITALITY"|"EVENTS"|"ACCOMODATION")} PaymentInvoiceCategoryChoices
 */

/**
 * @typedef {("AF"|"AX"|"AL"|"DZ"|"AS"|"AD"|"AO"|"AI"|"AQ"|"AG"|"AR"|"AM"|"AW"|"AU"|"AT"|"AZ"|"BS"|"BH"|"BD"|"BB"|"BY"|"BE"|"BZ"|"BJ"|"BM"|"BT"|"BO"|"BQ"|"BA"|"BW"|"BV"|"BR"|"IO"|"BN"|"BG"|"BF"|"BI"|"CV"|"KH"|"CM"|"CA"|"KY"|"CF"|"TD"|"CL"|"CN"|"CX"|"CC"|"CO"|"KM"|"CG"|"CD"|"CK"|"CR"|"CI"|"HR"|"CU"|"CW"|"CY"|"CZ"|"DK"|"DJ"|"DM"|"DO"|"EC"|"EG"|"SV"|"GQ"|"ER"|"EE"|"SZ"|"ET"|"FK"|"FO"|"FJ"|"FI"|"FR"|"GF"|"PF"|"TF"|"GA"|"GM"|"GE"|"DE"|"GH"|"GI"|"GR"|"GL"|"GD"|"GP"|"GU"|"GT"|"GG"|"GN"|"GW"|"GY"|"HT"|"HM"|"VA"|"HN"|"HK"|"HU"|"IS"|"IN"|"ID"|"IR"|"IQ"|"IE"|"IM"|"IL"|"IT"|"JM"|"JP"|"JE"|"JO"|"KZ"|"KE"|"KI"|"KW"|"KG"|"LA"|"LV"|"LB"|"LS"|"LR"|"LY"|"LI"|"LT"|"LU"|"MO"|"MG"|"MW"|"MY"|"MV"|"ML"|"MT"|"MH"|"MQ"|"MR"|"MU"|"YT"|"MX"|"FM"|"MD"|"MC"|"MN"|"ME"|"MS"|"MA"|"MZ"|"MM"|"NA"|"NR"|"NP"|"NL"|"NC"|"NZ"|"NI"|"NE"|"NG"|"NU"|"NF"|"KP"|"MK"|"MP"|"NO"|"OM"|"PK"|"PW"|"PS"|"PA"|"PG"|"PY"|"PE"|"PH"|"PN"|"PL"|"PT"|"PR"|"QA"|"RE"|"RO"|"RU"|"RW"|"BL"|"SH"|"KN"|"LC"|"MF"|"PM"|"VC"|"WS"|"SM"|"ST"|"SA"|"SN"|"RS"|"SC"|"SL"|"SG"|"SX"|"SK"|"SI"|"SB"|"SO"|"ZA"|"GS"|"KR"|"SS"|"ES"|"LK"|"SD"|"SR"|"SJ"|"SE"|"CH"|"SY"|"TW"|"TJ"|"TZ"|"TH"|"TL"|"TG"|"TK"|"TO"|"TT"|"TN"|"TR"|"TM"|"TC"|"TV"|"UG"|"UA"|"AE"|"GB"|"UM"|"US"|"UY"|"UZ"|"VU"|"VE"|"VN"|"VG"|"VI"|"WF"|"EH"|"YE"|"ZM"|"ZW")} PaymentInvoiceCountryChoices
 */

/**
 * @typedef {("DE"|"EN")} PaymentInvoiceLanguageChoices
 */

/**
 * @typedef {("A_0"|"A_1")} PaymentInvoiceRecipientSelChoices
 */

/**
 * @typedef {("NEW"|"OPEN"|"APPROVED"|"SENT"|"PAID"|"REJECTED"|"VOID"|"CONVERTED"|"NEW_VERSION")} PaymentInvoiceStateChoices
 */

/**
 * @typedef {("R"|"P"|"G"|"S"|"L"|"E"|"C"|"D")} PaymentInvoiceTypeChoices
 */

/**
 * @typedef {("DEVELOPMENT"|"TRAVEL"|"ADDITIONAL_EXPENSES")} PaymentLineItemCategoryChoices
 */

/**
 * @typedef {Object} PositionNode
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {Object} PositionNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(PositionNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} PositionNodeEdge
 * @property {PositionNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {("MUNDLICHE_ZUSAGE"|"PROSPECTING"|"PROPOSAL_PRICE_QUOTE"|"NEEDS_ANALYSIS"|"CLOSED_WON"|"CLOSED_WON_FRAMEWORK_CONTRACT"|"CLOSED_LOST"|"NEGOTIATION_REVIEW")} ProjectCRMOpportunityStageChoices
 */

/**
 * @typedef {Object} ProjectClientContractNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {ContractProjectClientContractStateChoices} state
 * @property {UserNode} [author]
 * @property {string} [coverText]
 * @property {string} [pdfPublicId]
 * @property {boolean} legalOverwrite
 * @property {string} legalText
 * @property {ContractProjectClientContractLanguageChoices} language
 * @property {string} body
 * @property {string} pricing
 * @property {FileNode} [contract]
 * @property {FileNode} [contractSigned]
 * @property {FileNode} [calculationFile]
 * @property {GenericScalar} [calculationParams]
 * @property {FileNode} [orderConfirmation]
 * @property {ProjectNode} project
 * @property {Decimal} [safety]
 * @property {Decimal} additionalExpenses
 * @property {Decimal} travelExpenses
 * @property {Decimal} hourlyRate
 * @property {Decimal} [storyPointRate]
 * @property {Date} deadline
 * @property {Decimal} deductionValue
 * @property {Decimal} keyAccountDiscount
 * @property {ContractProjectClientContractTypeChoices} type
 * @property {number} paymentPeriod
 * @property {boolean} hidePricing
 * @property {boolean} twoClientSignatures
 * @property {boolean} hideRdTaxText
 * @property {boolean} hideProjectStagesText
 * @property {string} publicId
 * @property {Decimal} [tax]
 * @property {Decimal} [netValueOverride]
 * @property {Decimal} [initialNetValue]
 * @property {Decimal} unusedValue
 * @property {ContractProjectClientContractBillingChoices} billing
 * @property {ContractProjectClientContractPricingTypeChoices} [pricingType]
 * @property {Date} [contractSignedOn]
 * @property {MyWorkerNode} [technicalReviewer]
 * @property {boolean} shareEnabled
 * @property {string} [shareHash]
 * @property {boolean} includeRateCard
 * @property {InvoiceNodeConnection} invoices
 */

/**
 * @typedef {Object} ProjectClientContractNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ProjectClientContractNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ProjectClientContractNodeEdge
 * @property {ProjectClientContractNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ProjectInvitationNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {ProjectProjectInvitationStateChoices} state
 * @property {MyWorkerNode} worker
 * @property {ProjectNode} project
 * @property {ProjectRoleNode} [role]
 * @property {Decimal} [hourlyRate]
 * @property {Decimal} [feedbackLink]
 * @property {string} color
 * @property {Array<string>} responsibilities
 * @property {ProjectInvitationPlannedHoursNodeConnection} plannedHours
 * @property {InvoiceNodeConnection} invoices
 * @property {string} [stateLabel]
 */

/**
 * @typedef {Object} ProjectInvitationNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ProjectInvitationNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ProjectInvitationNodeEdge
 * @property {ProjectInvitationNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ProjectInvitationPlannedHoursNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {ProjectInvitationNode} invitation
 * @property {number} weeklyHours
 * @property {Date} startDate
 */

/**
 * @typedef {Object} ProjectInvitationPlannedHoursNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ProjectInvitationPlannedHoursNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ProjectInvitationPlannedHoursNodeEdge
 * @property {ProjectInvitationPlannedHoursNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ProjectNode
 * @property {string} id
 * @property {string} name
 * @property {string} nameInternal
 * @property {string} [nameShort]
 * @property {string} slug
 * @property {MyWorkerNodeConnection} workers
 * @property {string} [description]
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {boolean} timelog
 * @property {FileNode} [image]
 * @property {CostCenterNode} [costCenter]
 * @property {ProjectRoleNodeConnection} roles
 * @property {ProjectInvitationNodeConnection} invitations
 * @property {boolean} [costCenterInternal]
 * @property {string} [color]
 * @property {boolean} [isActive]
 * @property {ClusterNodeConnection} [clusters]
 */

/**
 * @typedef {Object} ProjectNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ProjectNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ProjectNodeEdge
 * @property {ProjectNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ProjectPlanNode
 * @property {string} [projectId]
 * @property {number} [plannedHours]
 * @property {Date} [startDate]
 */

/**
 * @typedef {("A_1"|"A_2"|"A_3"|"A_7"|"A_6"|"A_8")} ProjectProjectInvitationStateChoices
 */

/**
 * @typedef {Object} ProjectRoleNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {boolean} isPublic
 * @property {boolean} isStaffed
 * @property {ProjectNode} project
 * @property {ClusterNode} [cluster]
 * @property {string} name
 * @property {string} [description]
 * @property {string} [adminComment]
 * @property {Date} [startDate]
 * @property {Date} [endDate]
 * @property {number} [hours]
 * @property {Decimal} [budget]
 * @property {Decimal} [hourlyRate]
 * @property {Decimal} [totalHours]
 * @property {MyWorkerNodeConnection} workers
 * @property {ToolNodeConnection} tools
 * @property {GenericScalar} [importedRow]
 * @property {ProjectInvitationNodeConnection} invitations
 */

/**
 * @typedef {Object} ProjectRoleNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ProjectRoleNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ProjectRoleNodeEdge
 * @property {ProjectRoleNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ProjectStageNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {string} [name]
 * @property {ProjectNodeConnection} projects
 */

/**
 * @typedef {Object} ProjectStageNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ProjectStageNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ProjectStageNodeEdge
 * @property {ProjectStageNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ProjectStats
 * @property {number} [loggedSeconds]
 * @property {ProjectNode} [project]
 */

/**
 * @typedef {Object} ProjectWorkerContractNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {ContractProjectWorkerContractTypeChoices} type
 * @property {ContractProjectWorkerContractTypeOfSalaryChoices} typeOfSalary
 * @property {MyWorkerNode} worker
 * @property {ContractProjectWorkerContractWorkLocationChoices} workLocation
 * @property {PositionNode} [position]
 * @property {DepartmentNode} [department]
 * @property {number} [weeklyHours]
 * @property {number} maxTrackableWeeklyHours
 * @property {Decimal} [payment]
 * @property {Decimal} [yearlyGrossSalary]
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {boolean} isCore
 * @property {boolean} isActive
 * @property {DateTime} updated
 */

/**
 * @typedef {Object} ProjectWorkerContractNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ProjectWorkerContractNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ProjectWorkerContractNodeEdge
 * @property {ProjectWorkerContractNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} ProjectsCache
 * @property {string} id
 * @property {string} name
 * @property {string} color
 * @property {boolean} isActive
 * @property {boolean} costCenterInternal
 * @property {number} [weeklyHours]
 */

/**
 * @typedef {Object} PublicHolidayNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {string} name
 * @property {VacationPublicHolidayLocationChoices} location
 */

/**
 * @typedef {Object} PublicHolidayNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(PublicHolidayNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} PublicHolidayNodeEdge
 * @property {PublicHolidayNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} Query
 * @property {ClientProfileNodeConnection} [allClients]
 * @property {ClusterNodeConnection} [allClusters]
 * @property {DepartmentNodeConnection} [allDepartments]
 * @property {GroupNodeConnection} [allGroups]
 * @property {ProjectInvitationNodeConnection} [allInvitations]
 * @property {InvoiceNodeConnection} [allInvoices]
 * @property {JiraIssueNodeConnection} [allJiraIssues]
 * @property {ProjectInvitationPlannedHoursNodeConnection} [allPlannedHours]
 * @property {PositionNodeConnection} [allPositions]
 * @property {ProjectClientContractNodeConnection} [allProjectContract]
 * @property {ProjectRoleNodeConnection} [allProjectRoles]
 * @property {ProjectStageNodeConnection} [allProjectStages]
 * @property {ProjectNodeConnection} [allProjects]
 * @property {PublicHolidayNodeConnection} [allPublicHolidays]
 * @property {WorkerReferralNodeConnection} [allReferrals]
 * @property {TemplateNodeConnection} [allTemplates]
 * @property {TimelogTagNodeConnection} [allTimelogTags]
 * @property {TimelogNodeConnection} [allTimelogs]
 * @property {ToolNodeConnection} [allTools]
 * @property {UserNodeConnection} [allUsers]
 * @property {ProjectWorkerContractNodeConnection} [allWorkerContracts]
 * @property {WorkerLanguageNodeConnection} [allWorkerLanguages]
 * @property {WorkerNoteNodeConnection} [allWorkerNotes]
 * @property {WorkerSkillEstimationEntryNodeConnection} [allWorkerSkillEstimationEntries]
 * @property {WorkerSkillEstimationNodeConnection} [allWorkerSkillEstimations]
 * @property {WorkerToolNodeConnection} [allWorkerTools]
 * @property {WorkerProfileNodeConnection} [allWorkers]
 * @property {ClientProfileNode} [client]
 * @property {ClusterNode} [cluster]
 * @property {GroupNode} [group]
 * @property {ProjectInvitationNode} [invitation]
 * @property {InvoiceNode} [invoice]
 * @property {JiraIssueNode} [jiraIssue]
 * @property {Array<(EnumNode|null|undefined)>} [languageProficiencies]
 * @property {Array<(EnumNode|null|undefined)>} [languages]
 * @property {ProjectWorkerContractNodeConnection} [myContracts]
 * @property {ProjectInvitationNodeConnection} [myInvitations]
 * @property {ProjectInvitationPlannedHoursNodeConnection} [myPlannedHours]
 * @property {Array<(ProjectPlanNode|null|undefined)>} [myProjectPlan]
 * @property {ProjectNodeConnection} [myProjects]
 * @property {TimelogStatsNode} [myTimelogStats]
 * @property {TimelogNodeConnection} [myTimelogs]
 * @property {UserNode} [myUser]
 * @property {MyWorkerNode} [myWorker]
 * @property {WorkerLanguageNodeConnection} [myWorkerLanguages]
 * @property {CRMOpportunityNodeConnection} [opportunities]
 * @property {PositionNode} [position]
 * @property {ProjectNode} [project]
 * @property {ProjectClientContractNode} [projectContract]
 * @property {ProjectRoleNode} [projectRole]
 * @property {ProjectStageNode} [projectStage]
 * @property {Array<ProjectsCache>} [projects]
 * @property {PublicHolidayNode} [publicHoliday]
 * @property {TemplateNode} [template]
 * @property {TimelogNode} [timelog]
 * @property {ToolNode} [tool]
 * @property {UserNode} [user]
 * @property {WorkerProfileNode} [worker]
 * @property {ProjectWorkerContractNode} [workerContract]
 * @property {WorkerLanguageNode} [workerLanguage]
 * @property {WorkerNoteNode} [workerNote]
 * @property {WorkerSkillEstimationNode} [workerSkillEstimation]
 * @property {WorkerSkillEstimationEntryNode} [workerSkillEstimationEntry]
 * @property {WorkerToolNode} [workerTool]
 */

/**
 * @typedef {Object} SetWorkerClusters
 * @property {Array<(ClusterNode|null|undefined)>} [clusters]
 */

/**
 * @typedef {("TEMPLATE_BASETEMPLATE"|"TEMPLATE_INVITATIONDETAILTEMPLATE"|"TEMPLATE_WORKERDETAILTEMPLATE"|"TEMPLATE_WORKERMAILTOTEMPLATE"|"TEMPLATE_TECHNICALINTERVIEWTEMPLATE"|"TEMPLATE_EXPORTWORKERTEMPLATE"|"TEMPLATE_CONTRACTTEMPLATE"|"TEMPLATE_CONTRACTCHECKLISTTEMPLATE"|"TEMPLATE_WORKERNOTETEMPLATE"|"TEMPLATE_PROJECTDESCRIPTIONTEMPLATE"|"TEMPLATE_LEGALTEMPLATE")} TemplateBaseTemplateTypeChoices
 */

/**
 * @typedef {Object} TemplateNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {string} name
 * @property {string} slug
 * @property {string} [content]
 * @property {TemplateBaseTemplateTypeChoices} [type]
 * @property {GenericScalar} [data]
 */

/**
 * @typedef {Object} TemplateNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(TemplateNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} TemplateNodeEdge
 * @property {TemplateNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} TimelogNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {Date} date
 * @property {string} [comment]
 * @property {string} [formattedComment]
 * @property {number} durationSeconds
 * @property {TimelogTimelogStateChoices} state
 * @property {MyWorkerNode} worker
 * @property {ProjectNode} [project]
 * @property {string} [crmOpportunityId]
 * @property {DateTime} [paidDate]
 * @property {DateTime} [approvedDate]
 * @property {GenericScalar} [properties]
 * @property {TimelogTagNodeConnection} tags
 * @property {JiraIssueNodeConnection} jiraIssues
 * @property {TimelogTimelogTimelogTypeChoices} timelogType
 * @property {number} [personioId]
 * @property {boolean} isPayrollRelevant
 * @property {DateTime} [appliedOn]
 * @property {string} [stateLabel]
 * @property {string} [timelogTypeLabel]
 */

/**
 * @typedef {Object} TimelogNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(TimelogNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} TimelogNodeEdge
 * @property {TimelogNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} TimelogStatsNode
 * @property {number} [targetUtilization]
 * @property {number} [weeklyBillableHoursTarget]
 * @property {number} [currentWeeklyBillableHours]
 * @property {number} [capaRatio]
 * @property {number} [loggedSeconds]
 * @property {number} [averageWeeklySeconds]
 * @property {number} [trackedAgainstPlanned]
 * @property {Array<(ProjectStats|null|undefined)>} [projects]
 */

/**
 * @typedef {Object} TimelogTagNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {string} name
 * @property {string} [description]
 * @property {TimelogNodeConnection} timelogs
 */

/**
 * @typedef {Object} TimelogTagNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(TimelogTagNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} TimelogTagNodeEdge
 * @property {TimelogTagNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {("A_3"|"A_5"|"A_9")} TimelogTimelogStateChoices
 */

/**
 * @typedef {("TIMELOG"|"VACATION"|"HOLIDAY"|"SICKNESS"|"LEAVE")} TimelogTimelogTimelogTypeChoices
 */

/**
 * @typedef {Object} ToolNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {string} name
 * @property {string} keywordRegex
 * @property {ToolToolSourceChoices} source
 * @property {boolean} defaultEvaluated
 * @property {ClusterNodeConnection} clusters
 * @property {WorkerToolNodeConnection} workerTools
 * @property {WorkerSkillEstimationEntryNodeConnection} workerEstimation
 * @property {ProjectRoleNodeConnection} roles
 */

/**
 * @typedef {Object} ToolNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(ToolNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} ToolNodeEdge
 * @property {ToolNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {("A_1"|"A_2"|"A_3"|"A_4")} ToolToolSourceChoices
 */

/**
 * @typedef {("NEW"|"CONFIRMED_INTERVIEW"|"CONFIRMED_PROJECT")} ToolWorkerToolStatusChoices
 */

/**
 * @typedef {Object} UpdateProjectColor
 * @property {ProjectInvitationNode} [invitation]
 */

/**
 * @typedef {Object} UpdateTimelog
 * @property {TimelogNode} [timelog]
 */

/**
 * @typedef {Object} UpdateUser
 * @property {UserNode} [user]
 */

/**
 * @typedef {Object} UpdateWorker
 * @property {WorkerProfileNode} [worker]
 */

/**
 * @typedef {Object} UpdateWorkerLanguage
 * @property {WorkerLanguageNode} [workerLanguage]
 */

/**
 * @typedef {Object} UpdateWorkerTool
 * @property {WorkerToolNode} [workerTool]
 */

/**
 * @typedef {Object} UserNode
 * @property {string} id
 * @property {boolean} isSuperuser
 * @property {string} username
 * @property {string} email
 * @property {string} [motiusEmail]
 * @property {PositionNode} [position]
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} displayName
 * @property {DateTime} dateJoined
 * @property {boolean} isStaff
 * @property {MyWorkerNode} [workerProfile]
 */

/**
 * @typedef {Object} UserNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(UserNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} UserNodeEdge
 * @property {UserNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {("MUNICH"|"STUTTGART"|"BERLIN"|"DUBAI"|"SERBIA"|"INDIA"|"REMOTE")} VacationPublicHolidayLocationChoices
 */

/**
 * @typedef {Object} WorkerLanguageNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {MyWorkerNode} worker
 * @property {WorkerWorkerLanguageLanguageChoices} language
 * @property {string} [proficiency]
 */

/**
 * @typedef {Object} WorkerLanguageNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(WorkerLanguageNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} WorkerLanguageNodeEdge
 * @property {WorkerLanguageNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} WorkerNoteNode
 * @property {string} id
 * @property {MyWorkerNode} worker
 * @property {ProjectNode} [project]
 * @property {WorkerWorkerNoteTypeChoices} type
 * @property {Date} created
 * @property {UserNode} [author]
 * @property {string} [description]
 * @property {Date} [dueDate]
 */

/**
 * @typedef {Object} WorkerNoteNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(WorkerNoteNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} WorkerNoteNodeEdge
 * @property {WorkerNoteNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} WorkerProfileNode
 * @property {string} id
 * @property {string} [phone]
 * @property {string} [address]
 * @property {string} [city]
 * @property {string} [postalCode]
 * @property {UserNode} [user]
 * @property {string} fullName
 * @property {WorkerWorkerProfileStateChoices} state
 * @property {FileNode} [picture]
 * @property {DepartmentNode} [department]
 * @property {ClusterNodeConnection} clusters
 * @property {WorkerWorkerProfileWorkLocationChoices} workLocation
 * @property {WorkerToolNodeConnection} tools
 * @property {WorkerLanguageNodeConnection} languages
 * @property {ProjectInvitationNodeConnection} invitations
 * @property {boolean} [isPermanent]
 * @property {string} [name]
 * @property {number} [weeklyHours]
 * @property {Array<(string|null|undefined)>} [latestRoles]
 */

/**
 * @typedef {Object} WorkerProfileNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(WorkerProfileNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} WorkerProfileNodeEdge
 * @property {WorkerProfileNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} WorkerReferralNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {UserNode} referred
 * @property {UserNode} [referredBy]
 * @property {Decimal} reward
 * @property {WorkerWorkerReferralStateChoices} state
 */

/**
 * @typedef {Object} WorkerReferralNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(WorkerReferralNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} WorkerReferralNodeEdge
 * @property {WorkerReferralNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} WorkerSkillEstimationEntryNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {WorkerWorkerSkillEstimationEntryEstimationChoices} estimation
 * @property {WorkerSkillEstimationNode} source
 * @property {ToolNode} tool
 */

/**
 * @typedef {Object} WorkerSkillEstimationEntryNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(WorkerSkillEstimationEntryNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} WorkerSkillEstimationEntryNodeEdge
 * @property {WorkerSkillEstimationEntryNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} WorkerSkillEstimationNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {DateTime} updated
 * @property {MyWorkerNode} worker
 * @property {ProjectNode} [project]
 * @property {UserNode} reporter
 * @property {boolean} isSelf
 * @property {string} [title]
 * @property {Date} [date]
 * @property {WorkerSkillEstimationEntryNodeConnection} entries
 */

/**
 * @typedef {Object} WorkerSkillEstimationNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(WorkerSkillEstimationNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} WorkerSkillEstimationNodeEdge
 * @property {WorkerSkillEstimationNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {Object} WorkerToolNode
 * @property {string} id
 * @property {DateTime} [created]
 * @property {MyWorkerNode} worker
 * @property {ToolNode} tool
 * @property {number} [rating]
 * @property {ToolWorkerToolStatusChoices} status
 * @property {boolean} approved
 */

/**
 * @typedef {Object} WorkerToolNodeConnection
 * @property {PageInfo} pageInfo
 * @property {Array<(WorkerToolNodeEdge|null|undefined)>} edges
 */

/**
 * @typedef {Object} WorkerToolNodeEdge
 * @property {WorkerToolNode} [node]
 * @property {string} cursor
 */

/**
 * @typedef {("AF"|"AR"|"AZ"|"BG"|"BE"|"BN"|"BR"|"BS"|"CA"|"CS"|"CY"|"DA"|"DE"|"EL"|"EN"|"EN_AU"|"EN_GB"|"EO"|"ES"|"ES_AR"|"ES_MX"|"ES_NI"|"ES_VE"|"ET"|"EU"|"FA"|"FI"|"FR"|"FY"|"GA"|"GL"|"HE"|"HI"|"HR"|"HU"|"IA"|"ID"|"IS"|"IT"|"JA"|"KA"|"KK"|"KM"|"KN"|"KO"|"LB"|"LT"|"LV"|"MK"|"ML"|"MN"|"MY"|"NB"|"NE"|"NL"|"NN"|"OS"|"PA"|"PL"|"PT"|"PT_BR"|"RO"|"RU"|"SK"|"SL"|"SQ"|"SR"|"SR_LATN"|"SV"|"SW"|"TA"|"TE"|"TH"|"TR"|"TT"|"UDM"|"UK"|"UR"|"VI"|"ZH_CN"|"ZH_HANS"|"ZH_HANT"|"ZH_TW")} WorkerWorkerLanguageLanguageChoices
 */

/**
 * @typedef {("INTERVIEW"|"TECHNICAL_INTERVIEW"|"TEAM_EVALUATION"|"EVALUATION"|"QUALITY_REVIEW"|"EMAIL"|"PROJECT_NOTE"|"OTHER")} WorkerWorkerNoteTypeChoices
 */

/**
 * @typedef {("REGISTERING"|"NEW"|"ALUMNI"|"MISSING_INFORMATION"|"INVITED"|"INTERVIEW"|"REJECTED"|"ACCEPTED"|"OUTBOARDED"|"PENDING"|"DELETED")} WorkerWorkerProfileStateChoices
 */

/**
 * @typedef {("MUNICH"|"STUTTGART"|"BERLIN"|"DUBAI"|"SERBIA"|"INDIA"|"REMOTE")} WorkerWorkerProfileWorkLocationChoices
 */

/**
 * @typedef {("CREATED"|"UNPAID"|"PAID")} WorkerWorkerReferralStateChoices
 */

/**
 * @typedef {("A_0"|"A_1"|"A_2"|"A_3"|"A_4"|"A_5")} WorkerWorkerSkillEstimationEntryEstimationChoices
 */