import React from 'react'
import { ThemeProvider } from '@emotion/react'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme
} from '@mui/material/styles'

export const themeVariables = {
  primaryColor: '#23BAD9',
  primaryColorLight: '#B8E9F4',
  secondaryColor: '#383E4E',
  borderColor: '#E4EAEB',
  lightBorderColor: '#D7DFEB',
  mutedColor: '#8D97A0',
  textPrimary: '#000000',
  backgroundColor: '#F5F9FA',
  backgroundPaperColor: '#FFFFFF',
  errorColor: '#E69A9A',
  spacingNone: '0',
  spacingExtraTight: '0.25rem',
  spacingTight: '0.5rem',
  spacingBaseTight: '0.75rem',
  spacingBaseLoose: '1rem',
  spacingLoose: '1.5rem',
  spacingExtraLoose: '2rem',
  fontPrimary: 'Inter',
  fontSecondary: 'TT Firs Neue',
  mobileQuery: ({ theme }: any) => theme.breakpoints.down('sm')
}

type Props = {
  children: React.ReactNode
}

export const CombinedThemeProvider = ({ children }: Props) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: themeVariables.primaryColor,
        contrastText: '#fff'
      },
      secondary: {
        main: themeVariables.secondaryColor,
        contrastText: '#fff'
      },
      muted: {
        main: themeVariables.mutedColor
      },
      divider: themeVariables.borderColor,
      background: {
        default: themeVariables.backgroundColor,
        paper: themeVariables.backgroundPaperColor
      },
      text: {
        primary: themeVariables.textPrimary
      },
      disabled: {
        main: themeVariables.mutedColor
      }
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
      h1: {
        fontFamily: themeVariables.fontSecondary,
        fontWeight: 500,
        fontSize: '2.75rem',
        lineHeight: '3.25rem',
        letterSpacing: '0',
        fontStyle: 'normal'
      },
      h2: {
        fontFamily: themeVariables.fontSecondary,
        fontWeight: 500,
        fontSize: '1.5rem',
        lineHeight: '2rem',
        letterSpacing: '0'
      },
      h3: {
        fontFamily: themeVariables.fontSecondary,
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
        letterSpacing: '0'
      },
      body1: {
        fontFamily: themeVariables.fontPrimary,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 400
      },
      body2: {
        fontFamily: themeVariables.fontPrimary,
        fontSize: '1.125rem',
        lineHeight: '1.25',
        fontWeight: 400
      },
      caption: {
        fontFamily: themeVariables.fontPrimary,
        fontSize: '14px',
        lineHeight: '1rem',
        letterSpacing: 'normal',
        fontWeight: 500
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          text: {
            textTransform: 'capitalize'
          },
          textPrimary: {
            textTransform: 'capitalize'
          },
          textSecondary: {
            textTransform: 'capitalize'
          }
          // label: {
          //   fontSize: '1rem',
          //   lineHeight: '1.25',
          //   fontWeight: 400
          // }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 1
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          container: {
            position: 'relative',
            zIndex: 2
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: themeVariables.secondaryColor
          },
          tooltipArrow: {
            background: themeVariables.secondaryColor
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: '1rem',
            borderColor: 'var(--color-border) !important'
          }
        }
      }
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  )
}
