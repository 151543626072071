import * as Msal from 'msal'
import * as MicrosoftGraph from '@microsoft/microsoft-graph-client'
import { ImplicitMSALAuthenticationProvider } from '@microsoft/microsoft-graph-client/lib/src/ImplicitMSALAuthenticationProvider'
import { MSALAuthenticationProviderOptions } from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions'

export const MSAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const MAX_OUTLOOK_EVENTS = 100

export const msalAuthenticate = () => {
  const graphScopes = ['user.read', 'calendars.read']

  const msalApplication = new Msal.UserAgentApplication({
    auth: {
      clientId: '63193632-afec-4327-bacc-5a2780f81d1b' // TODO Move to env
    },
    cache: {
      cacheLocation: 'localStorage'
    }
  })
  const options = new MSALAuthenticationProviderOptions(graphScopes)
  const authProvider = new ImplicitMSALAuthenticationProvider(msalApplication, options)
  const AuthOptions = {
    authProvider // An instance created from previous step
  }
  const Client = MicrosoftGraph.Client
  return Client.initWithMiddleware(AuthOptions)
}
