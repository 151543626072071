import create from 'zustand'
import { Client as MsalClient } from '@microsoft/microsoft-graph-client'
import {
  checkMicrosoftIntegration,
  disableMicrosoftIntegration,
  enableMicrosoftIntegration
} from '../services/storageService'
import { IFormattedOutlookEvent } from '../types'

export type State = {
    msalClient: MsalClient | null;
    msalEnabled: boolean;
    outlookEvents: IFormattedOutlookEvent[];
    outlookSync: boolean;
    setMsalClient: (msalClient?: MsalClient) => void
    setOutlookEvents: (outlookEvents?: IFormattedOutlookEvent[]) => void
    enableMsal: () => void
    disableMsal: () => void
};

export const useMicrosoftContext = create<State>((set) => ({
  msalClient: null,
  msalEnabled: !!checkMicrosoftIntegration(),
  outlookEvents: [],
  outlookSync: false,
  setMsalClient: (msalClient) => set({ msalClient }),
  disableMsal: () => {
    disableMicrosoftIntegration()
    set({ msalEnabled: false })
  },
  enableMsal: () => {
    enableMicrosoftIntegration()
    set({ msalEnabled: true })
  },
  setOutlookEvents: (events) => set({ outlookEvents: events || [], outlookSync: true })
}))
