const MICROSOFT_INTEGRATION = 'microsoft-integration'
const LIST_TYPE = 'list-type'

export const checkMicrosoftIntegration = () =>
  localStorage.getItem(MICROSOFT_INTEGRATION)

export const enableMicrosoftIntegration = () =>
  localStorage.setItem(MICROSOFT_INTEGRATION, 'true')

export const disableMicrosoftIntegration = () =>
  localStorage.removeItem(MICROSOFT_INTEGRATION)

export const getListType = () => localStorage.getItem(LIST_TYPE)

export const setListType = (type: string) =>
  localStorage.setItem(LIST_TYPE, type)
