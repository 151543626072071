import React from 'react'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'

type Props = {
  image: string
  name: string
  url: string
}

const Container = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: var(--spacing-tight);
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;

  &::before {
    content: '';
    transition: 400ms;
  }

  img {
    width: 80%;
  }
`

const AppName = styled(Typography)`
  && {
    margin-top: var(--spacing-extra-tight);
  }
`

const NavbarApp = ({ image, name, url }: Props) => {
  return (
    <Container href={url} target='_blank' rel='noopener noreferrer'>
      <img src={image} alt={name} />
      <AppName>{name}</AppName>
    </Container>
  )
}

export default NavbarApp
