import { Snackbar } from '@mui/material'
import { Alert } from '@mui/lab'
import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Footer from './components/navigation/Footer'
import Navbar from './components/navigation/Navbar'
import { loadCacheData } from './store/apolloCache'
import { themeVariables } from './theme'
import { useAuth, useMobileQuery } from './utils/customHooks'
import { useMicrosoftContext } from './store/microsoftContext'
import { msalAuthenticate } from './services/msalService'
import Loading from './components/loading/Loading'
import BottomBar from './components/navigation/BottomBar'
import styled from '@emotion/styled'
import GuestIndex from './containers/guest'
import { css, Global } from '@emotion/react'

const ProfileRoutes = React.lazy(() => import('./containers/profile/routes'))
const TimelogRoutes = React.lazy(() => import('./containers/timelogs/routes'))
const SettingRoutes = React.lazy(() => import('./containers/settings/routes'))
const ProjectRoutes = React.lazy(() => import('./containers/projects/routes'))

const AppContainer = styled.div`
  min-height: calc(100vh - 4rem);
  padding-bottom: 64px;

  ${themeVariables.mobileQuery} {
    overflow: auto;
  }
`

const App = () => {
  const { setMsalClient } = useMicrosoftContext()
  const isMobile = useMobileQuery()
  const [isOffline, setIsOffline] = useState(false)
  const { isLoggedIn, isGuest } = useAuth()

  useEffect(() => {
    const handleOffline = () => {
      setIsOffline(!navigator.onLine)
    }

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOffline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOffline)
    }
  }, [])

  useEffect(() => {
    setMsalClient(msalAuthenticate())
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      loadCacheData()
    }
  }, [isLoggedIn])

  return isLoggedIn || process.env.REACT_APP_TEST_TOKEN ? (
    <div style={{ minHeight: '100vh' }}>
      <Global
        styles={css`
          :root {
            --color-primary: ${themeVariables.primaryColor};
            --color-primary-light: ${themeVariables.primaryColorLight};
            --color-secondary: ${themeVariables.secondaryColor};
            --color-border: ${themeVariables.borderColor};
            --color-border-light: ${themeVariables.lightBorderColor};
            --color-muted: ${themeVariables.mutedColor};
            --color-disabled: ${themeVariables.mutedColor};
            --color-text-hint: ${themeVariables.mutedColor};
            --color-text-primary: ${themeVariables.textPrimary};
            --color-error: ${themeVariables.errorColor};
            --color-background: ${themeVariables.backgroundColor};
            --color-background-paper: ${themeVariables.backgroundPaperColor};
            --spacing-none: ${themeVariables.spacingNone};
            --spacing-extra-tight: ${themeVariables.spacingExtraTight};
            --spacing-tight: ${themeVariables.spacingTight};
            --spacing-base-tight: ${themeVariables.spacingBaseTight};
            --spacing-base-loose: ${themeVariables.spacingBaseLoose};
            --spacing-loose: ${themeVariables.spacingLoose};
            --spacing-extra-loose: ${themeVariables.spacingExtraLoose};
            --font-primary: ${themeVariables.fontPrimary};
            --font-secondary: ${themeVariables.fontSecondary};

            * {
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }

            body {
              background: var(--color-background);
              color: var(--color-text-primary);

              .MuiAutocomplete-listbox {
                max-height: calc(1rem + 5 * 2rem);
              }

              .MuiAutocomplete-option {
                padding-top: 0;
                padding-bottom: 0;
                min-height: 2rem;
              }

              .hidden {
                display: none;
              }

              /* spacing utilities */

              .mt-2 {
                margin-top: 0.5rem;
              }

              .ml-2 {
                margin-left: 0.5rem;
              }

              .mr-2 {
                margin-right: 0.5rem;
              }

              .mb-4 {
                margin-bottom: 0.5rem;
              }

              .mt-4 {
                margin-top: 1rem;
              }

              .ml-4 {
                margin-left: 1rem;
              }

              .mr-4 {
                margin-right: 1rem;
              }

              .mb-4 {
                margin-bottom: 1rem;
              }

              .pt-2 {
                padding-top: 0.5rem;
              }

              .pl-2 {
                padding-left: 0.5rem;
              }

              .pr-2 {
                padding-right: 0.5rem;
              }

              .pb-2 {
                padding-bottom: 0.5rem;
              }

              .pb-4 {
                padding-bottom: 0.5rem;
              }

              .pt-4 {
                padding-top: 1rem;
              }

              .pl-4 {
                padding-left: 1rem;
              }

              .pr-4 {
                padding-right: 1rem;
              }
            }
          }
        `}
      />
      <BrowserRouter>
        <AppContainer>
          <Navbar />
          {!isGuest ? (
            <Switch>
              <Route path='/timelogs'>
                <Suspense fallback={<Loading />}>
                  <TimelogRoutes />
                </Suspense>
              </Route>
              <Route path='/settings'>
                <Suspense fallback={<Loading />}>
                  <SettingRoutes />
                </Suspense>
              </Route>
              <Route path='/projects'>
                <Suspense fallback={<div>Loading...</div>}>
                  <ProjectRoutes />
                </Suspense>
              </Route>
              <Route path='/profile'>
                <Suspense fallback={<div>Loading...</div>}>
                  <ProfileRoutes />
                </Suspense>
              </Route>
              <Route path='*'>
                <Redirect to='/timelogs' />
              </Route>
            </Switch>
          ) : (
            <GuestIndex />
          )}
        </AppContainer>
        {isMobile ? <BottomBar /> : <Footer />}
      </BrowserRouter>
      <Snackbar
        open={isOffline}
        color=''
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          style={{ backgroundColor: 'var(--color-secondary)' }}
          color='info'
          severity='info'
          variant='filled'
        >
          You are offline, but you can still view your timelogs!
        </Alert>
      </Snackbar>
    </div>
  ) : null
}

export default App
