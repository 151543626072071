import { Popover } from '@mui/material'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import breezy from '../../assets/images/apps/breezy.png'
import confluence from '../../assets/images/apps/confluence.png'
import gitlab from '../../assets/images/apps/gitlab.png'
import jira from '../../assets/images/apps/jira.svg'
import metabase from '../../assets/images/apps/metabase.png'
import miro from '../../assets/images/apps/miro.svg'
import personio from '../../assets/images/apps/personio.png'
import rancher from '../../assets/images/apps/rancher.png'
import sentry from '../../assets/images/apps/sentry.png'
import sharepoint from '../../assets/images/apps/sharepoint.png'
import spendesk from '../../assets/images/apps/spendesk.jpg'
import zoho from '../../assets/images/apps/zoho.png'
import { useGetCurrentWorker } from '../../queries/currentWorker'
import Icon from '../common/Icon'
import IconButton from '../common/IconButton'
import NavbarApp from './NavbarApp'

const TECH = 'tech'
const PT = 'pt'
const PI = 'impact'
const PRO = 'project'
const FINANCE = 'fcl'
const STRATEGY = 'strategy'

export const APPS = [
  {
    id: 1,
    image: gitlab,
    name: 'GitLab',
    url: 'https://gitlab.motius.de',
    only: [TECH, PRO],
    guest: true
  },
  {
    id: 2,
    image: jira,
    name: 'Jira',
    url: 'https://motius.atlassian.net/jira/projects',
    guest: true
  },
  {
    id: 3,
    image: confluence,
    name: 'Confluence',
    url: 'https://motius.atlassian.net/wiki/home',
    guest: true
  },
  {
    id: 4,
    image: personio,
    name: 'Personio',
    url: 'https://motius.personio.de/'
  },
  {
    id: 5,
    image: breezy,
    name: 'Breezy',
    url: 'https://app.breezy.hr/app/c/motius/home',
    only: [PT]
  },
  {
    id: 6,
    image: sentry,
    name: 'Sentry',
    url: 'https://sentry.motius.de/',
    only: [TECH, PRO]
  },
  {
    id: 7,
    image: rancher,
    name: 'Rancher',
    url: 'https://rancher.motius.ci/',
    only: [TECH, PRO]
  },
  {
    id: 8,
    image: metabase,
    name: 'Metabase',
    url: 'https://metabase.motius.de/',
    only: [PT, PRO, FINANCE, STRATEGY]
  },
  {
    id: 9,
    image: zoho,
    name: 'Zoho',
    url: 'https://crm.zoho.eu/crm/org20060819597/tab/Home',
    only: [PI, PRO, STRATEGY]
  },
  {
    id: 10,
    image: miro,
    name: 'Miro',
    url: 'https://miro.com/app/dashboard/'
  },
  {
    id: 11,
    image: sharepoint,
    name: 'Sharepoint',
    url: 'https://motius.sharepoint.com/_layouts/15/sharepoint.aspx',
    guest: true
  },
  {
    id: 12,
    image: spendesk,
    name: 'Spendesk',
    url: 'https://www.spendesk.com/app/JogHauFlop'
  }
]

const AppContainer = styled.div`
  display: grid;
  width: 300px;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
`

const NavbarApplications = () => {
  const { data } = useGetCurrentWorker()

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  )
  const openPopover = Boolean(popoverAnchor)

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(e.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchor(null)
  }

  return (
    <>
      <IconButton
        style={{ marginRight: 'var(--spacing-base-loose)' }}
        onClick={handleButtonClick}
      >
        <Icon icon='fa-grip-horizontal' color='var(--color-secondary)' />
      </IconButton>
      <Popover
        open={openPopover}
        onClose={handleClose}
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <AppContainer>
          {APPS.filter(
            app =>
              !app.only ||
              app.only.some(dep =>
                data?.currentWorker?.department?.name
                  .toLowerCase()
                  .includes(dep)
              )
          ).map(app => (
            <NavbarApp
              key={app.id}
              name={app.name}
              image={app.image}
              url={app.url}
            />
          ))}
        </AppContainer>
      </Popover>
    </>
  )
}

export default NavbarApplications
