import styled from '@emotion/styled'
import { Container } from '@mui/material'
import { themeVariables } from '../../theme'

const PageContainer = styled(Container)`
  margin-top: var(--spacing-extra-loose);

  ${themeVariables.mobileQuery} {
    & > h2:first-of-type {
      display: none;
    }
  }
`

export default PageContainer
