import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Container, Typography } from '@mui/material';
import LogoImage from '../../assets/images/logo-nav.png';
import { ReactComponent as FacebookLogo } from '../../assets/images/footer/facebook.svg';
import { ReactComponent as GithubLogo } from '../../assets/images/footer/github.svg';
import { ReactComponent as LinkedInLogo } from '../../assets/images/footer/linkedin.svg';
import { ReactComponent as InstagramLogo } from '../../assets/images/footer/instagram.svg';
import apiService from '../../services/apiService';
import { toast } from 'react-toastify';

export const dashboardVersion = process.env.REACT_APP_DASHBOARD_VERSION;

const FooterContainer = styled.footer`
  background-color: var(--color-background-paper);
  height: 4rem;

  & > .MuiContainer-root {
    display: flex;
    align-items: center;
    color: var(--color-text-primary);
    padding-top: var(--spacing-base-loose);
    padding-bottom: var(--spacing-base-loose);
  }
`;

const Logo = styled.a`
  height: 1.5rem;
  margin-right: var(--spacing-loose);

  img {
    height: 100%;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;

  a {
    color: inherit;
    text-decoration: none;

    &:first-child {
      margin-left: 2rem;
    }
  }

  & > div:first-child a {
    margin: 0 1rem;
  }

  & > div a {
    margin-right: 1rem;
  }
`;

const FooterVersions = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;

  span {
    color: inherit;
    font-weight: 100;
    font-size: 12px;

    &:first-child {
      margin-left: 0.5rem;
    }
  }

  & > div:first-child span {
    margin: 0 1rem;
  }

  & > div span {
    margin-right: 1rem;
  }
`;
const Footer = () => {
  const [serviceVersion, setServiceVersion] = useState('');

  useEffect(() => {
    apiService.serviceVersion
      .version()
      .then((res) => res.json())
      .then((res) => {
        setServiceVersion(res['platform-backend']);
      })
      .catch((e) => {
        toast.error('Failed to get service version.');
      });
  }, []);

  return (
    <FooterContainer>
      <Container maxWidth='lg'>
        <Logo href='https://motius.de' target='_blank'>
          <img src={LogoImage} alt='Motius GmbH' />
        </Logo>
        <FooterLinks>
          <div>
            <div>
              <a
                href='https://www.motius.de/en/privacy-policy/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Typography variant='caption'>Privacy Policy</Typography>
              </a>
              <a
                href='https://motius.de/agb/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Typography variant='caption'>Terms of Service</Typography>
              </a>
              <a
                href='https://motius.breezy.hr/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Typography variant='caption'>Jobs</Typography>
              </a>
              <a
                href='https://motius.atlassian.net/wiki/spaces/TEAM/pages/7887650817/Motius+Platform'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Typography variant='caption'>Guide</Typography>
              </a>
            </div>
            <FooterVersions>
              <span>
                <Typography variant='caption'>
                  Dashboard: {dashboardVersion}
                </Typography>
              </span>
              <span>
                <Typography variant='caption'>
                  Service: {serviceVersion}
                </Typography>
              </span>
            </FooterVersions>
          </div>
          <div>
            <a
              href='https://github.com/motius'
              target='_blank'
              rel='noopener noreferrer'
            >
              <GithubLogo />
            </a>
            <a
              href='https://facebook.com/motius.de/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FacebookLogo />
            </a>
            <a
              href='https://instagram.com/team_motius'
              target='_blank'
              rel='noopener noreferrer'
            >
              <InstagramLogo />
            </a>
            <a
              href='https://linkedin.com/company/motius-gmbh'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedInLogo />
            </a>
          </div>
        </FooterLinks>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
