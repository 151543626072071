import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { identifySentryUser } from '../sentry'
import { useAppContext } from '../store/appContext'
import {
  useCreateTimelogMutation,
  useDeleteTimelogMutation,
  useUpdateTimelogMutation,
  useCreateOpportunityTimelogMutation
} from '../types/graphql'
import userManager from '../userManager'

// const GUEST_GROUP = 'c45f097d-7c47-440f-b8c5-fcaa504b8356'

export const useMobileQuery = (): boolean => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('sm'))
}

type NavigationProps = {
  title: string
  hasParent?: boolean
}

type useTimelogCrudProps = {
  refetchQuery: string
}

export const useNavigation = ({
  title,
  hasParent = false
}: NavigationProps) => {
  const { setNavigation } = useAppContext()

  useEffect(() => {
    setNavigation({ title, hasParent })
  }, [title])
}

export const useTimelogCrud = ({ refetchQuery }: useTimelogCrudProps) => {
  const [createTimelog, { loading: createLoading }] = useCreateTimelogMutation({
    refetchQueries: [refetchQuery]
  })
  const [updateTimelog, { loading: updateLoading }] = useUpdateTimelogMutation({
    refetchQueries: [refetchQuery]
  })
  const [deleteTimelog, { loading: deleteLoading }] = useDeleteTimelogMutation({
    refetchQueries: [refetchQuery]
  })
  const [createOpportunityTimelog, { loading: createOpportunityLoading }] = useCreateOpportunityTimelogMutation({
    refetchQueries: [refetchQuery]
  })

  const loading = createLoading || updateLoading || deleteLoading || createOpportunityLoading

  return { createTimelog, updateTimelog, deleteTimelog, loading, createOpportunityTimelog }
}

export const useAuth = () => {
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [isGuest] = useState(false)

  useEffect(() => {
    const isCallback = window.location.pathname === '/login'
    if (isCallback) {
      userManager.signinRedirectCallback().then(e => {
        window.history.replaceState({}, '', (e.state as any).path)
        identifySentryUser(e.profile.email, (e.profile as any).preferred_username)
        setLoggedIn(true)
      })
    } else {
      userManager.getUser().then((user) => {
        if (user) {
          if (user.expired) {
            localStorage.clear()
            userManager
            .signinRedirect({ state: { path: window.location.pathname } })
            .then(a => {
              setLoggedIn(false)
            })
          } else {
            identifySentryUser(user.profile.email, (user.profile as any).preferred_username)
            setLoggedIn(true)
          }
        } else {
          userManager
            .signinRedirect({ state: { path: window.location.pathname } })
            .then(a => {
              setLoggedIn(false)
            })
        }
      })
    }
  }, [])

  return { isLoggedIn, isGuest }
}
