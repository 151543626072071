import React from 'react'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import Icon from '../common/Icon'
import styled from '@emotion/styled'
import { useHistory, useLocation } from 'react-router-dom'

const NavigationIcon = styled(Icon)`
  font-size: 1.25rem !important;
`

const NavActionWithoutLabel = styled(BottomNavigationAction)`
  && {
    padding: 6px 12px 8px !important;
    color: var(--color-secondary);
  }
`

const StyledNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

const BottomBar = () => {
  const location = useLocation()
  const history = useHistory()

  const getNavValue = () => {
    // if (location.pathname === '/') {
    //   return 'home'
    // } else if (location.pathname.includes('/projects')) {
    //   return 'projects'
    // } else if (location.pathname.includes('/timelogs')) {
    //   return 'timelogs'
    // } else if (location.pathname.includes('/profile')) {
    //   return 'profile'
    // } else if (location.pathname.includes('/billing')) {
    //   return 'billing'
    // }

    if (location.pathname.includes('/timelogs')) {
      return 'timelogs'
    } else if (location.pathname.includes('/profile')) {
      return 'profile'
    }
  }

  const onChange = (value: string) => {
    if (value.includes('profile')) {
      history.push('/profile/referral')
    } else if (value !== getNavValue()) {
      history.push(`/${value}`)
    }
  }

  return (
    <StyledNavigation
      value={getNavValue()}
      onChange={(event, newValue) => onChange(newValue)}
    >
      {/* <NavActionWithoutLabel */}
      {/*  value='home' */}
      {/*  icon={<NavigationIcon regular icon='fa-home' />} */}
      {/* /> */}
      {/* <NavActionWithoutLabel */}
      {/*  value='projects' */}
      {/*  icon={<NavigationIcon regular icon='fa-briefcase' />} */}
      {/* /> */}
      <NavActionWithoutLabel
        value='timelogs'
        icon={<NavigationIcon regular icon='fa-clock' />}
      />
      <NavActionWithoutLabel
        value='profile'
        icon={<NavigationIcon regular icon='fa-user-friends' />}
      />
      {/* <NavActionWithoutLabel */}
      {/*  value='billing' */}
      {/*  icon={<NavigationIcon regular icon='fa-coins' />} */}
      {/* /> */}
    </StyledNavigation>
  )
}

export default BottomBar
