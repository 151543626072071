import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import userManager from './userManager'
import { projectsVar, currentWorkerVar } from './store/apolloCache'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPH_API_URL
})

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const user = await userManager.getUser()
  const token = user?.id_token
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Platform-Origin': 'Worker'
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          projects: {
            read () {
              return projectsVar()
            }
          },
          currentWorker: {
            read () {
              return currentWorkerVar()
            }
          }
        }
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

export const errorParser = (error: Error): string => {
  try {
    const errors = JSON.parse(error.message.replace(/'/g, '"'))
    if (errors.__all__) {
      return errors.__all__.join(',')
    } else if (Object.values(errors)) {
      return Object.values(errors).join(',')
    }
    return errors
  } catch (e) {
    return error.message
  }
}

export default client
